import { render, staticRenderFns } from "./ScormView.vue?vue&type=template&id=93640a48&scoped=true&"
import script from "./ScormView.vue?vue&type=script&lang=js&"
export * from "./ScormView.vue?vue&type=script&lang=js&"
import style0 from "./ScormView.vue?vue&type=style&index=0&id=93640a48&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "93640a48",
  null
  
)

export default component.exports