<template>
	<div >
		<section v-if="LocalKonum == 0 && !Hazir" class="home-two-slide d-flex align-items-center" style="justify-content:center">
			<div class="row ">
			<span  class="loader"></span>
			</div>
		</section>
		<section :class="{'topcategory-sec':LocalKonum == 0,'topcategory-sec sec2':LocalKonum==1}" v-if="IAR.length > 0">
			<div class="container">
				<div class="header-two-title text-center" data-aos="fade-up">
					<p class="tagline" style="margin-bottom: -1rem;" :style="Text1StyleObject">Interactive Readers</p>
					<!-- <h2>Top Category</h2> -->
					<div class="header-two-text">
						<!-- <p class="mb-0">Lorem ipsum dolor sit amet</p> -->
					</div>

				</div>
				<div class="top-category-group">
					
					<div class="row" style="justify-content: center;">
						<span v-if="!Hazir" class="loader"></span>
						<template v-if="Hazir && IAR.length > 0">
							<div v-for="(item, index) in IAR" :key="index" class="col-xl-3 col-lg-4 col-md-4 col-sm-6 d-flex" data-aos="fade-down"
							v-on:click="IARGit(item.ScormId)" >
								<div class="categories-item flex-fill" :style="CardStyleObject">
									<div style="height: 100px;">

										<div class="categories-icon" style="text-align: center;">
											<img :src="`/assets/img/iar/${item.GorselAdi}`" class="img-fluid" style="height: 100px;">
										</div>
									</div>
									<div class="categories-content" :style="CardStyleObject">
										<h3 style="text-align: center; font-size: 16px!important;" :style="CardStyleObject">{{item.IARAdi}}</h3>
										<!-- <p>40 Instructors</p> -->
									</div>
								</div>
							</div>							
						</template>
					</div>
				</div>
			</div>
		</section>
	</div>
	
</template>

<script>
import {mapGetters} from "vuex";
import LmsService from '@/services/lms.service';

export default {
	// eslint-disable-next-line vue/multi-word-component-names
	name: "iar",
	data() {
		return {
			Hazir : false,
			IAR: [],
			LocalKonum: this.Konum??0,		
		};
	},
	beforeMount() {
		const Izin = this.getRol =='teacher' ? 1 : 0
		if(Izin == 0){
			this.$router.push( { path : "/home" } )
			return
		}
		this.IlkYukleme();
	},

	computed:{
		...mapGetters(['getKullaniciDetaylari','getSinifSeviye','getOkulId','getRol','getOgretmenSinifSeviyeleri',
		'getSinifId','Button1StyleObject','CardStyleObject','Text1StyleObject']),

	},
	methods: {
		
        async IlkYukleme(){

            if (this.getKullaniciDetaylari) {
				await this.IARkListesi()
        	}
		},

		async IARkListesi(){
			try {
				let SinifSeviyeler 
				if (this.getRol == 'teacher'){
					if(this.getOgretmenSinifSeviyeleri.length > 0){
						SinifSeviyeler = this.getOgretmenSinifSeviyeleri.map(value => ({ SinifSeviye: value })); // anahtar adı olmayan listeye anahtar atama
					}
				} else{
					SinifSeviyeler = [{SinifSeviye: this.getSinifSeviye}]
				}

				const data = {
					OkulId: this.getOkulId,
					SinifSeviye: SinifSeviyeler,
				}	

				const resData = await LmsService.IARGetir(data)
				this.IAR = resData
				this.Hazir = true
			} catch (error) {
				this.Hazir = true
                this.$store.commit('setLoading', false)
				this.$toast.open({
					message: "Inter Active Readers listesi alınamadı!",
					type: "error",
					duration: 3000,
					dismissible: true,
					position : 'top'
				})
				return
			}
		},

		IARGit(ScormId){
        	// this.$router.push({ name: 'interActiveReaders', params: { ScormId } });
        	this.$router.push({ path: 'interActiveReaders/'+ScormId});
    	},

		activated() {
			

		},
		created () {
		}
	}
}
</script>

<style scoped>

</style>
