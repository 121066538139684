import CryptoJS from 'crypto-js';

const state = {
    KullaniciDetaylari :null
}

const getters = {
    getKullaniciDetaylari(){
        return state.KullaniciDetaylari
    },

    getSinifSube(){
        if(state.KullaniciDetaylari.role_type=='student'){
            return state.KullaniciDetaylari.organization.find(x => x.type === 'grade') ? state.KullaniciDetaylari.organization.find(x => x.type === 'grade').name : 0;
        } else{
            const Filter = state.KullaniciDetaylari.organization.filter((x) => x.type === "grade")
            let Siniflar =[]
            for (let key in Filter){
                Siniflar.push(Filter[key].name)
            }
            let SiniflarText = Siniflar.toString().replaceAll(","," / ")
            return SiniflarText//.replaceAll((',','='))
        }
    },

    getSinifId(){        
        return state.KullaniciDetaylari.organization.find(x => x.type === 'grade') ? state.KullaniciDetaylari.organization.find(x => x.type === 'grade').id : 0;
    },

    getSinifSeviye(){
        return (state.KullaniciDetaylari.organization.find(x => x.type === 'grade') ? state.KullaniciDetaylari.organization.find(x => x.type === 'grade').name : 0).split('-')[0]
    },
    getKampusTipi(){
        return state.KullaniciDetaylari.organization.find(x => x.type === 'campus').school_type;
    },
    getKampusAdi(){
        return state.KullaniciDetaylari.organization.find(x => x.type === 'campus').name;
    },
    getKampusId(){
        return state.KullaniciDetaylari.organization.find(x => x.type === 'campus').id;
    },
    getOkulId(){
        return state.KullaniciDetaylari.organization.find(x => x.type === 'main').id;
    },
    getOkulAdi(){
        return state.KullaniciDetaylari.organization.find(x => x.type === 'main').name;
    },
    getRol(){
        return state.KullaniciDetaylari?.role_type;
    },
    getOgretmenSinifSeviyeleri(){
        let OgretmenSinifSeviyeleri = [] 
        const Siniflar = state.KullaniciDetaylari.organization.filter((x) => x.type === "grade")
        for (let key in Siniflar){
            OgretmenSinifSeviyeleri.push(Siniflar[key].name.split("-")[0])
        }
        return OgretmenSinifSeviyeleri = [...new Set(OgretmenSinifSeviyeleri)] 
    },

    getOgretmenSinifSubeIdleri(){
        let OgretmenSinifSubeIdleri = []
        const Siniflar = state.KullaniciDetaylari.organization.filter((x) => x.type === "grade")
        for (let key in Siniflar){
            OgretmenSinifSubeIdleri.push(Siniflar[key].id)
        }
        return OgretmenSinifSubeIdleri = [...new Set(OgretmenSinifSubeIdleri)] 
    },

}

const mutations = {
    setKullaniciDetaylari(state, payload){
        state.KullaniciDetaylari = payload
    },

    setStorageKullaniciDetaylari(payload){
        location.host == 'localhost:8080' ? localStorage.setItem("LMSKullaniciDetaylari", JSON.stringify(payload).toString())
        : localStorage.setItem("LMSData1", CryptoJS.AES.encrypt(JSON.stringify(payload).toString(), this.state.Passphrase).toString())
    },

    clearKullaniciDetaylari(state){
        state.KullaniciDetaylari = null
    }
}

const actions = {

}

export default {
    state, getters, mutations, actions
}

