<template>
	<div >

		<div class="main-wrapper">
			<AssignmentList :Gosterim="`Gosterim`" v-if="getRol =='student'"></AssignmentList>
			<IAR v-if="getRol =='teacher'"></IAR>
			<LearningTools></LearningTools>
			<Links :Konum=1></Links>
			<!-- <Footer></Footer> -->
		</div>
		<!-- <div class="patter-one-main">
			<img class="patter-one" src="assets/img/bg/patter-one.png" alt="Img">
			<img class="patter-two" src="assets/img/bg/home-middle.png" alt="Img" >
		</div> -->
		<!-- <div class="shapes"> -->
			<!-- <img class="shapes-one" src="assets/img/bg/home-right.png" alt="Img" style="right: 1rem;"> -->
			<!-- <img class="shapes-two" src="assets/img/bg/home-right-bottom.png" alt="Img">
			<img class="shapes-middle" src="assets/img/bg/home-middle.png" alt="Img"> -->
		<!-- </div> -->
	</div>

</template>

<script>
import LearningTools from "@/template/LearningTools.vue";
import AssignmentList from "@/template/AssignmentList.vue";
import Links from "@/template/Links.vue";
import IAR from "@/template/IAR.vue";
import {mapGetters} from "vuex";
import Content from '@/services/content';


// import Footer from "@/components/static/Footer";
export default {
	// eslint-disable-next-line vue/multi-word-component-names
	name: "mainPage",
	components: { LearningTools, AssignmentList, Links, IAR },
	data() {
		return {
			file: null,
			content:null,
			
		};
	},

	mounted() {
      this.getCollection(); 
    },
	computed:{
		...mapGetters(['getRol']),
	},
	methods: {
		async getCollection(){
			try {
				await Content.getCollections()
			} catch (error) {
				console.log('Colletion yüklenemedi')
			}
		}


	},
	activated() {
		

	},
	created () {
	}
}
</script>

<style scoped>




</style>
