import axios from 'axios';
// import tools from '@/tools';
// import store from '@/store/index';
// import router from '@/router.js';
import { router } from '../router'
import {store} from "../components/store/store";
// import {store} from "@/components/store/store";
const instance = axios.create();
instance.defaults.timeout = 300000;

instance.interceptors.request.use((config) => {
//   const token = tools.cookie.get('token');
const token = store.state.token;
  // eslint-disable-next-line no-param-reassign
  config.baseURL = 'https://uescustomlms-api.ey.r.appspot.com';  
  // config.baseURL = 'http://localhost:3000/api';
  config.headers.Authorization = `Bearer ${token}`;
  return config;
});

instance.interceptors.response.use(res => res.data, (err) => {
  const sc = err.response.status;
  // const userRole = store.state.user.role;
  // let routeName = userRole ? (userRole === 'admin' ? 'admin-login' : 'student-login') : 'student-login';
  // if (sc === 401) {
  if (sc !== 200) {
    // localStorage.clear();
    if (err.response.data.message === 'JsonWebTokenError') {
      store.commit('setLoading', false)
      store.dispatch("logout")
      router.push({ name: "login" }).catch(()=>{});
    }
  }
  throw err;
});

export default () => instance;
