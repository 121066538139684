
// colorUtils.js
export function hexToRgb(hex) {
    // HEX kodunu RGB'ye çevirme fonksiyonu
    const bigint = parseInt(hex.slice(1), 16);
    const r = (bigint >> 16) & 255;
    const g = (bigint >> 8) & 255;
    const b = bigint & 255;
    return [r, g, b];
  }
  
  export function rgbToHex(r, g, b) {
    return (
      "#" +
      ((1 << 24) + (r << 16) + (g << 8) + b)
        .toString(16)
        .slice(1)
        .toUpperCase()
    );
  }

  export function getTextColorBasedOnBackground(hex) {
    const [r, g, b] = hexToRgb(hex);
    // Parlaklık (luminance) hesaplama
    const brightness = (r * 0.299 + g * 0.587 + b * 0.114) / 255;
    // Eşik değeri 0.5; daha parlaksa siyah, koyuysa beyaz öneririz
    return brightness > 0.5 ? '#000000' : '#FFFFFF';
  }
  
  export function colorShades(hex, steps) {
    const [r, g, b] = hexToRgb(hex);
    const shades = [];
    for (let i = 0; i <= steps; i++) {
      const factor = i / steps;
      const newR = Math.round(r + factor * (255 - r));
      const newG = Math.round(g + factor * (255 - g));
      const newB = Math.round(b + factor * (255 - b));
      shades.push(rgbToHex(newR, newG, newB));
    }
    return shades;
  }
  