<template >
	<div>
		<!-- EDIT MODAL  -->
		<div  class="modal fade show" id="withdraw-request" style="display: block;" aria-modal="true"  role="dialog">
			<div class="modal-dialog modal-dialog-centered withdraw-modal">
				<div class="modal-content">
					<div class="page-wrapper-new p-0">
						<div class="content" style="min-height: 184.062px;">
							<div class="modal-header border-0 custom-modal-header">
								<div class="page-title">
									<h5>Edit Assignments</h5>
								</div>
								<button type="button" class="close" data-bs-dismiss="modal" aria-label="Close" @click.prevent="$parent.EditModalGosterKapat()">
									<i class="feather-x"></i>
								</button>
							</div>
							<!-- <div class="modal-body custom-modal-body"> -->
								<hr style="margin: 18px 0px 0px 0px;">

								<div class="checkout-form" style="padding: 25px">
									<div class="row">
									<div v-if="Mod=='single'" class="col-md-8">
										<div class="input-block">
											<label class="form-control-label">Title</label>
											<input v-model="Title" type="text" class="form-control THMinput" placeholder="Assignment Title" :style="InputStyleObject">
										</div>
									</div>
									<div :class="Mod=='single' ? 'col-md-4' : 'col-md-12'" >
										<div class="input-block">
											<label class="form-control-label">Due Date</label>
											<date-picker  v-model="DueDate" valueType="YYYY-MM-DD" :format="format" 
												placeholder="Due Date" style="padding-top: 4px; display: inline;"
												:style="InputStyleObject" :disabled-date="StartDateTarihleriEngelle">
												<template v-slot:header="{ emit }">
												<button class="mx-btn mx-btn-text" @click="emit(new Date())">Bugün</button>
												</template>
											</date-picker>
										</div>
									</div>
									</div>
									<!-- <div class="row">
										<div class="col-md-6 ">
											<div class="input-block">
												<label class="form-control-label">Due Date</label>
												<date-picker  v-model="DueDate" valueType="YYYY-MM-DD" :format="format" 
													placeholder="Due Date" style="padding-top: 4px; display: inline;"
													:style="InputStyleObject" :disabled-date="StartDateTarihleriEngelle">
													<template v-slot:header="{ emit }">
													<button class="mx-btn mx-btn-text" @click="emit(new Date())">Bugün</button>
													</template>
												</date-picker>
											</div>
										</div>
										<div class="col-md-6">
											<div class="input-block">
												<label class="form-control-label">Due Time  </label>
												<date-picker v-model="DueTime" :minute-step="1"  :style="InputStyleObject" style="padding-top: 4px; display: inline;"
													format="HH:mm" value-type="format" type="time"  placeholder="HH:mm (Default 23:59)">
												</date-picker>												
												
											</div>	
										</div>	
									</div> -->
									<hr style="margin: 18px 0px 0px 0px;">
									<div class="col-md-4 offset-md-8" style="text-align: end; margin-top:10px">
										<button class="btn btn-primary btn-start THMbutton THMbuttonShadow" @click="Update()"
										:class="(KayitAktif() == true) ? '':'li-disabled'" 
										:style="SaveButtonStyleObject" type="submit">Update																									
										</button>
									</div>
								<!-- </div> -->

							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<!-- /EDIT MODAL  -->

		<!-- MODAL GÖLGE -->
		<div  class="modal-backdrop fade show"></div>
		<!-- /MODAL GÖLGE -->

	</div>
</template>

<script>
import {mapGetters} from "vuex";
import LmsService from '@/services/lms.service';
import moment from 'moment';
import DatePicker from 'vue2-datepicker';
// import ContentService from '@/services/content';


import 'vue2-datepicker/index.css';


// import DatePicker from 'vuejs-datepicker';


export default {
	// eslint-disable-next-line vue/multi-word-component-names
	name: "editAssignments",
	props:['Mod','SingleEditIndex'],

	components: { DatePicker},

	data() {
		return {
			SistemTarihi: null,
			format: 'DD.MM.YYYY',
			Title: null,
			DueDate : null,
			DueTime: null,

		};
	},
	
	async mounted() {
		this.SistemTarihiGetir()
	},

	computed:{
		...mapGetters(['getKullaniciDetaylari','InputStyleObject',
			'BackButtonStyleObject','SaveButtonStyleObject',
		]),

		ToplamKayitSayisi() {
			return this.KayitSayisi
		},

	},
	methods: {
		DateFormat(value){
        	return moment(value).format("DD.MM.YYYY"); 
    	},

		StartDateTarihleriEngelle(date) {
			const today = new Date();
			today.setHours(0, 0, 0, 0);
			// return date < today || date > new Date(today.getTime() + 7 * 24 * 3600 * 1000);
			return  date < today 
		},

		DueDateTarihleriEngelle(date) {
			let today= null
			if(this.StartDate){
				today = new Date(this.StartDate);
			} else{
				today = new Date();
			}
			today.setHours(0, 0, 0, 0);
			// return date < today || date > new Date(today.getTime() + 7 * 24 * 3600 * 1000);
			return  date < today 
		},
		

		async SistemTarihiGetir(){
			try {
				const resData = await LmsService.SistemTarihiGetir()
				if(resData){
					this.SistemTarihi = resData
				}

			} catch (error) {
				return
			}
		},

		KayitAktif(){
			if((this.Title && (this.Title.trim()!='')) || this.DueDate) {
				return true
			} else return false
		},

		Update(){			
			// const DueDate = this.DueDate.concat(this.DueTime == null ? ' 23:59:00' : ' '.concat(this.DueTime.concat(':00')))
			const DueDate = this.DueDate
			this.$parent.AssignmentUpdate(this.SingleEditIndex, this.Title, DueDate)
		}

	}
}
</script>

<style scoped>

.text-center{
	text-align:center
}

.select-noimg{
	--bs-form-select-bg-img: none !important;
}



</style>
