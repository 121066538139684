<template>

	<!-- Header -->
	<header class="header header-two">
		<div class="header-fixed">
			<nav class="navbar navbar-expand-lg header-nav scroll-sticky add-header-bg">
				<div class="container">
					<div class="navbar-header">
						<a id="mobile_btn" v-on:click="MobilMenuClicked">
							<span class="bar-icon">
								<span></span>
								<span></span>
								<span></span>
							</span>
						</a>
						<a  class="navbar-brand logo">
							<img :src="`/assets/img/schoollogo/${this.$store.state.DomainAdi}.png`" class="img-fluid" style="max-width:80%" alt="Logo">							
						</a>
					</div>
					<div class="main-menu-wrapper">
						<div class="menu-header">
							<a  class="menu-logo">
								<!-- <img :src="`/assets/img/schoollogo/${$store.state.DomainAdi}.png`" class="img-fluid" alt="Logo"> -->								
							</a>
							<a id="menu_close" class="menu-close" v-on:click="MobilMenuClicked">
								<i class="fas fa-times"></i>
							</a>
						</div>
						<ul class="main-nav">
							<li id="Menu1" class="has-submenu menu-effect" :class="this.$route.path =='/home'?'active':''">
								<a href="javascript:void(0);" v-on:click="AnaMenuClicked(1)" >Home</a>
							</li>
							<li id="Menu2" v-if="getRol =='teacher'?getOkulAyarlar.OgretmenMenu1:getOkulAyarlar.OgrenciMenu1" class="has-submenu menu-effect" :class="this.$route.path =='/library'?'active':''">
								<a href="javascript:void(0);" v-on:click="AnaMenuClicked(2)">Library</a>
							</li>
							<li id="Menu3" v-if="getRol =='teacher'?getOkulAyarlar.OgretmenMenu2:getOkulAyarlar.OgrenciMenu2" class="has-submenu menu-effect" :class="this.$route.path =='/links'?'active':''">
								<a href="javascript:void(0);" v-on:click="AnaMenuClicked(3)">Links</a>
							</li>
							<li id="Menu4" v-if="getRol =='teacher'?getOkulAyarlar.OgretmenMenu3:getOkulAyarlar.OgrenciMenu3" class="has-submenu menu-effect" :class="this.$route.path =='/resources'?'active':''">
								<a href="javascript:void(0);" v-on:click="AnaMenuClicked(4)">Resources</a>
							</li>
							<li id="Menu5" v-if="getRol =='teacher'?getOkulAyarlar.OgretmenMenu4:getOkulAyarlar.OgrenciMenu4" class="has-submenu menu-effect" 
							:class="(this.$route.path =='/assignments' ||this.$route.path =='/assignment/create')?'active':''">
								<a href="javascript:void(0);" v-on:click="AnaMenuClicked(5)">Assignments</a>
							</li>
                            <li id="Menu6" v-if="getRol =='teacher' && getOkulAyarlar.OgretmenMenu5 == 1" class="has-submenu menu-effect" :class="this.$route.path =='/assignment/report'?'active':''">
								<a href="javascript:void(0);" v-on:click="AnaMenuClicked(6)">Report</a>
							</li>
							<li class="login-link">
								<a href="javascript:void(0);" @click.prevent="Logout()">Log Out</a>
							</li>
						</ul>
					</div>
					<ul class="main-nav">
						<li class="nav-item ">
								<a href="javascript:void(0);" @click.prevent="ModalGosterKapat()">
									{{this.$store.state.users.KullaniciDetaylari?.first_name??'' }} {{this.$store.state.users.KullaniciDetaylari?.last_name??''}}
                                    <i class="fas fa-chevron-down"></i>
                                </a>
							<!-- <a  href="javascript:void(0);">
								<div class="user-text">
									<h6>Kullanıcı Adı</h6>
								</div>

							</a> -->
						</li>
					</ul>
				</div>
			</nav>


			<div v-if="Modal" class="modal fade show" id="withdraw-request" style="display: block;" aria-modal="true"  role="dialog">
				<div class="modal-dialog modal-dialog-centered withdraw-modal">
					<div class="modal-content">
						<div class="page-wrapper-new p-0">
							<div class="content" style="min-height: 184.062px;">
								<div class="modal-header border-0 custom-modal-header">
									<div class="page-title">
										<h4>My Profile</h4>
									</div>
									<button type="button" class="close" data-bs-dismiss="modal" aria-label="Close" @click.prevent="ModalGosterKapat()">
										<i class="feather-x"></i>
									</button>
								</div>
								<div class="modal-body custom-modal-body">
									<div class="settings-widget dash-profile" :style="ProfileStyleObject">
										<div class="settings-menu">
											<div class="profile-bg">
												<div class="profile-img">
													<img src="assets/img/profile-picture.png" alt="Img">
												</div>
											</div>
											<div class="profile-group">
												<div class="profile-name text-center">
													<h4>{{getKullaniciDetaylari?.first_name??'' }} {{getKullaniciDetaylari?.last_name??''}}</h4>
													<p>{{getRol}}</p>
													<hr>
													<h6>{{getOkulAdi??''}}</h6>
													<h6>{{getKampusAdi??''}}</h6>
													<h6>{{getSinifSube??''}}</h6>

													<a href="javascript:void(0);" @click.prevent="Logout()" class="add-course btn-primary THMbutton THMbuttonShadow" :style="Button2StyleObject">
														<i class="feather-log-out me-1"></i>
														Log Out
													</a>
												</div>
											</div>
										</div>
									</div>

									
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div v-if="Modal" class="modal-backdrop fade show"></div>
		</div>
	</header>
	<!-- /Header -->

</template>

<script>
// import {eventBus} from "../../main.js"
import {mapGetters} from "vuex";


export default {
	data() {
		return {
			Modal: false
			
		};
	},

	computed: {
		...mapGetters(['getOkulAyarlar','getRol','getKullaniciDetaylari','getOkulAdi','getKampusAdi','getSinifSube','ProfileStyleObject','Button2StyleObject']),
	},

	methods: {
		logout() {
			this.$store.dispatch("logout")
		},

		ModalGosterKapat(){
			this.Modal = !this.Modal
		},

		Logout(){
			this.Modal = !this.Modal
			this.$store.dispatch("logout")
        	// window.location = '/';
			// this.$store.commit("clearToken")
			// this.$store.commit("clearUserDetails")

			// if (location.host !=='localhost:8080') {
            //     localStorage.removeItem("data0")
            //     localStorage.removeItem("data1")

            // }else{
            //     localStorage.removeItem("tokenData")
            //     localStorage.removeItem("userDetails")
            // }
        	// window.location = '/';

		},

		MobilMenuClicked(){
            const element = document.querySelector("#mainTag");
            // Tüm class değerini değiştir
            if(element.className =='ligth'){
                element.className = "ligth menu-opened";
            } else{
                element.className = "ligth";

            }
		},

        AnaMenuClicked(id){
            const elements = document.querySelectorAll(".has-submenu"); // Birden fazla elementi seç

            elements.forEach((element) => {
                element.classList.remove("active"); // Her birine yeni sınıf ekle
            });

            const element = document.querySelector('#Menu'+id);
            element.classList.add("active");
			
			if (id == 2 && this.$route.fullPath != "/library"){
                this.$router.push( { path : "/library"} )
            }
            if (id == 3 && this.$route.fullPath != "/links"){
                this.$router.push( { path : "/links"} )
            }
			if (id == 4 && this.$route.fullPath != "/resources"){
                this.$router.push( { path : "/resources"} )
            }
			if (id == 5 && this.$route.fullPath != "/assignments"){
                this.$router.push( { path : "/assignments"} )

                // if (this.getRol =='student'){
				// 	this.$router.push( { name : "assignments"} )
				// } else {
				// 	this.$router.push( { name : "assignmentsTeacher"} )
				// }
            }
			if (id == 6 && this.$route.fullPath != "/assignment/report"){
                this.$router.push( { path : "/assignment/report"} )
            }
			
			if (id == 1 && (this.$route.fullPath != "/home" || this.$route.fullPath != "/")){
                this.$router.push( { path : "/home"} )
            }

			// console.log(window.location.href); // Tam URL
			// console.log(window.location.pathname); // Sayfanın path kısmı
			// console.log(window.location.hostname); // Alan adı (domain)
			// console.log(window.location.protocol); // Protokol (http veya https)

			// console.log(this.$route.fullPath); // Router'daki tam URL path
			// console.log(this.$route.path); // Yalnızca path kısmı
		    // console.log(this.$route.query); // URL'deki query parametreleri

	
		},
	},
}


</script>


<style scoped>





</style>
