import api from './index';
import uesLmsApi from './ues.lms.service';
import {store} from "../components/store/store";
import CryptoJS from "crypto-js";
// import axios from 'axios';
import moment from 'moment';




export default {


    async OkulAyarlar(obj) {
        const res0 = await api().post('lms/SchoolConfig',obj);    
        const data = CryptoJS.AES.decrypt(res0.dataObj, store.state.Passphrase).toString(CryptoJS.enc.Utf8);
        const res  = JSON.parse(data);
        return res;
    },

    // async UesLmsTokenAl(obj) {
    //     const res = await uesLmsApi().post('/authenticate',obj);   
    //     return res;

    // },

    async UesLmsTokenAl(obj) {
        try{
            const res = await uesLmsApi().post('/authenticate',obj);   
            if(res.message === 'Request failed with status code 401'){
                return {
                    success: false,
                    data: res.message
                };
            }else{

                store.commit("setToken", res.data)
                store.commit("setStorageToken", res.data)
                await store.dispatch('actionToken', {
                token     : res.data.token,
                expiresIn : 60*60*60
                });

                return {
                    success: true,
                    data: res.data,
                };
                
            } 
        } catch (error) {
            return {
                success: false,
                data: error.message,
            };
        }      

    },

    async UesLmsTokenYenile() {
        try{
            const obj = {
                email: store.state.users.KullaniciDetaylari.KullaniciAdi,
                password: store.state.users.KullaniciDetaylari.Sifre,
                api_key: store.state.OkulAyarlar.ApiKey,
            }

            const res = await uesLmsApi().post('/authenticate',obj);   
            if(res.message === 'Request failed with status code 401'){
                return {
                    success: false,
                    data: res.message
                };
            }else{

                store.commit("setToken", res.data)
                store.commit("setStorageToken", res.data)
                await store.dispatch('actionToken', {
                token     : res.data.token,
                expiresIn : 60*60*60
                });

                return {
                    success: true,
                    data: res.data,
                };
                
            } 
        } catch (error) {
            return {
                success: false,
                data: error.message,
            };
        }      

    },

    // async UesLmsTokenAl(obj) {
    //     console.log('sssssssssssss',obj)
    //     const token = store.state.token
    //     axios.post('https://www.ueslms.com/api/v2/authenticate', obj, {
    //         headers: {
    //           Authorization: `Bearer ${token}`
    //         }
    //       })
    //       .then(response => {
    //         console.log('Response:', response.data); // Başarılı yanıtı logla
    //         return {
    //             success: true,
    //             data: response.data,
    //           };
    //       })
    //       .catch(error => {
    //         console.error('Error:', error.response?.data || error.message); // Hata durumunda logla
    //         return {
    //             success: false,
    //             data: error.message,
    //           };
    //       });

    // },

    // async KullaniciBilgiAl() {
    //     const res = await uesLmsApi().post('/user');   
    //     return res;
    // },
    
    async KullaniciBilgiAl(KullaniciAdi, Sifre) {
        
        try {
            const res = await uesLmsApi().post('/user');   

            const KullaniciData = res.data.data
            KullaniciData.KullaniciAdi = KullaniciAdi
            KullaniciData.Sifre = Sifre
            store.commit('setKullaniciDetaylari', KullaniciData)
            store.commit('setStorageKullaniciDetaylari', KullaniciData) 

            return {
                success: true,
            };               
        } catch (error) {
            return {
                success: false,
                data: error.message,
            };
        }
    },

    // async AssignmentsGetir(AssignmenTip) {
    //     try {
    //         await this.UesLmsTokenYenile();
    //         let res = null;
    //         if (AssignmenTip) {
    //         // res = await uesLmsApi().post(`/assignments?page=${Sayfa}`, {
    //         res = await uesLmsApi().post(`/assignments`, {
    //             platform_id: store.state.OkulAyarlar.PlatformId,
    //             class_id: null,
    //             assignment_ids: null,
    //             paginate: 999999,
    //             type: 'individual',
    //         });
    //         } else {
    //         res = await uesLmsApi().post(`/assignments`, {
    //             platform_id: store.state.OkulAyarlar.PlatformId,
    //             class_id: null,
    //             assignment_ids: null,
    //             paginate: 999999,
    //         });
    //         } 
    //         return {
    //             success: true,
    //             data: res.data,
    //         };
    //     } catch (error) {
    //       return {
    //         success: false,
    //         data: error.message,
    //       };
    //     }
    // },

    // async AssignmentSkorGetir(id) {
    //     try {   
    //         await this.UesLmsTokenYenile();
    //         const res = await uesLmsApi().post(`/assignment/${id}`);
    //         console.log(res)
    //         return {
    //         success: true,
    //         data: res.data.data,
    //         };
    //     } catch (error) {
    //         return {
    //         success: false,
    //         data: error.message,
    //         };
    //     }
    // },

    async OgrenimAraclariGetir(obj) {
        const cryptoText = JSON.stringify(obj);
        let cryptoObj = CryptoJS.AES.encrypt(cryptoText, store.state.Passphrase).toString();
        const data = {
            dataObj : cryptoObj
        }

        const res0 = await api().post('lms/LearningTools', data); 
        const data0 = CryptoJS.AES.decrypt(res0.dataObj, store.state.Passphrase).toString(CryptoJS.enc.Utf8);  
        const res  = JSON.parse(data0);
        return res;
    },

    async DemoHesaplariGetir(obj) {
        const cryptoText = JSON.stringify(obj);
        let cryptoObj = CryptoJS.AES.encrypt(cryptoText, store.state.Passphrase).toString();
        const data = {
            dataObj : cryptoObj
        }

        const res0 = await api().post('lms/InClassTools', data); 
        const data0 = CryptoJS.AES.decrypt(res0.dataObj, store.state.Passphrase).toString(CryptoJS.enc.Utf8);  
        const res  = JSON.parse(data0);
        return res;
    },

    async OkulLinkleriGetir(obj) {
        const cryptoText = JSON.stringify(obj);
        let cryptoObj = CryptoJS.AES.encrypt(cryptoText, store.state.Passphrase).toString();
        const data = {
            dataObj : cryptoObj
        }

        const res0 = await api().post('lms/Links', data); 
        const data0 = CryptoJS.AES.decrypt(res0.dataObj, store.state.Passphrase).toString(CryptoJS.enc.Utf8);  
        const res  = JSON.parse(data0);
        return res;
    },

    async IARGetir(obj) {
        const cryptoText = JSON.stringify(obj);
        let cryptoObj = CryptoJS.AES.encrypt(cryptoText, store.state.Passphrase).toString();
        const data = {
            dataObj : cryptoObj
        }

        const res0 = await api().post('lms/InterActiveReaders', data); 
        const data0 = CryptoJS.AES.decrypt(res0.dataObj, store.state.Passphrase).toString(CryptoJS.enc.Utf8);  
        const res  = JSON.parse(data0);
        return res;
    },

    async SistemTarihiGetir() {
        const res = await api().post('lms/SystemDate'); 

        return moment(res).format("YYYY-MM-DD HH:mm:ss"); 
    },

    

};

