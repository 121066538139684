import CryptoJS from "crypto-js";
import {store} from "../components/store/store";
export default {
    
    upperTR (value) {            
        let harfler = { "i": "İ", "ş": "Ş", "ğ": "Ğ", "ü": "Ü", "ö": "Ö", "ç": "Ç", "ı": "I" }
        value = value.replace(/(([iışğüçö]))/g, function(harf){ return harfler[harf]; })
        return value.toUpperCase()
    },
    cookie: {

        async getKullaniciDetaylari(){

            let token, tokenData, KullaniciDetaylari

            let pass = store.state.Passphrase

            if(localStorage.getItem("LMSData0") !== null || localStorage.getItem("LMSTokenData") !== null){
                let data0 = location.host !== 'localhost:8080' ? CryptoJS.AES.decrypt(localStorage.getItem("LMSData0"), pass).toString(CryptoJS.enc.Utf8)
                : localStorage.getItem("LMSTokenData")
                
                tokenData = JSON.parse(data0)
                token = tokenData.token
            }else {
                token = ""
            }

            if(localStorage.getItem("LMSData1") !== null || localStorage.getItem("LMSKullaniciDetaylari") !== null){
                let data = location.host !== 'localhost:8080' ? CryptoJS.AES.decrypt(localStorage.getItem("LMSData1"), pass).toString(CryptoJS.enc.Utf8)
                : localStorage.getItem("LMSKullaniciDetaylari")
                KullaniciDetaylari = JSON.parse(data)
            }

            if(token !== ""){
                store.commit('setToken',tokenData)
                store.commit('setKullaniciDetaylari',KullaniciDetaylari.KullaniciDetaylari)

                tokenData.expiresIn
                store.state.expiresIn = tokenData.expiresIn
                let time = new Date().getTime()
                if(time >= tokenData.expiresIn){
                    store.state.token = ""
                    return false
                } else {
                    store.state.token = token
                    return true
                }
            } else {
                return false
            }
        },

        async getOkulAyarlar(){            

            let pass = store.state.Passphrase
            let OkulAyarlar

            if(localStorage.getItem("LMSData2") !== null || localStorage.getItem("LMSOkulAyarlar") !== null){
                let data = location.host !== 'localhost:8080' ? CryptoJS.AES.decrypt(localStorage.getItem("LMSData2"), pass).toString(CryptoJS.enc.Utf8)
                : localStorage.getItem("LMSOkulAyarlar")
                OkulAyarlar = JSON.parse(data)
                store.commit('setOkulAyarlar',OkulAyarlar)
            }

        },

        async getAssignmentDetay(){            

            let pass = store.state.Passphrase
            let AssignmentDetay

            if(localStorage.getItem("LMSData3") !== null || localStorage.getItem("LMSAssignmentDetay") !== null){
                let data = location.host !== 'localhost:8080' ? CryptoJS.AES.decrypt(localStorage.getItem("LMSData3"), pass).toString(CryptoJS.enc.Utf8)
                : localStorage.getItem("LMSAssignmentDetay")
                AssignmentDetay = JSON.parse(data)
                store.commit('setAssignmentDetay',AssignmentDetay)
            }

        },

        async getScormView(){            

            let pass = store.state.Passphrase
            let ScormView

            if(localStorage.getItem("LMSData4") !== null || localStorage.getItem("LMSScormView") !== null){
                let data = location.host !== 'localhost:8080' ? CryptoJS.AES.decrypt(localStorage.getItem("LMSData4"), pass).toString(CryptoJS.enc.Utf8)
                : localStorage.getItem("LMSScormView")
                ScormView = JSON.parse(data)
                store.commit('setScormView',ScormView)
            }

        },

        // async getResources(){   
        //     let pass = store.state.Passphrase
        //     let Resources

        //     if(localStorage.getItem("LMSData5") !== null || localStorage.getItem("LMSResources") !== null){
        //         let data = location.host !== 'localhost:8080' ? CryptoJS.AES.decrypt(localStorage.getItem("LMSData5"), pass).toString(CryptoJS.enc.Utf8)
        //         : localStorage.getItem("LMSResources")
        //         Resources = JSON.parse(data)
        //         store.commit('setResources',Resources)
        //     }

        // },

    }
};
