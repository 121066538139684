<template >
	<div>
		<!-- <section  class="home-two-slide d-flex align-items-center" style="margin-top: 131px; justify-content:center"> -->
		<section v-if="!Hazir" class="home-two-slide d-flex align-items-center" style="margin-top: 131px; justify-content:center">
			<div class="row ">
			<span  class="loader"></span>
			</div>
		</section>

		<section class="home-two-slide d-flex align-items-center" v-if="Hazir">

			<div class="container">
				<div class="row ">					
					<div class="col-lg-12 col-12" data-aos="fade-up">
						<div class="home-slide-face">						
							
							<div class="container">
								<div class="header-two-title text-center" data-aos="fade-up">
									<p class="tagline" style="margin-bottom: -1rem;" :style="Text1StyleObject" >
										Resources 
									</p>
								</div>

								<div class="featured-courses-two" style="margin-bottom: 25px!important;">
									<div class="row" style="justify-content: center;">
										<div class="dash-pagination">
											<div class="row align-items-center">
												<div class="col-4" style="margin-top: -25px;">
													<a v-if="Gosterim !=='parent'" href="#" class="btn btn-primary btn-start THMbutton THMbuttonShadow" :style="Button1StyleObject"
													v-on:click="GeriGit()">
														<i class="fas fa-arrow-left ms-2"></i> Back 
													</a>													
												</div>
												<div class="col-4">
													<div class="fade-up" style="justify-items:center">
														<div class="banner-content">
															<form class="form">
																<div class="input-group">																	
																	<input v-model="Aranan" @keyup="AramaYap"
																	type="email" class="form-control" placeholder="Search">
																</div>
															</form>
														</div>
													</div>
												</div>
												<div class="col-4"> 	
													<div class="header-two-title text-center" data-aos="fade-up">
														<p class="tagline" style="margin-bottom: -1.5rem; text-align: end;" :style="Text1StyleObject" >
															{{BaslikGetir()}} 
														</p>
													</div>
													
												</div>
											</div>
										</div>

										<!-- <template v-if="Gosterim"> -->
										<template v-if="Gosterim == 'parent'">
											<h5 v-if="ParentData.length == 0" style="text-align: center;" :style="Text1StyleObject">There is no resources.</h5>
											<div v-for="(item) in ParentData" :key="item.id" class="col-xl-2 col-lg-4 col-md-4 col-sm-6 d-flex" 
												v-on:click="childResources(item.id)" style="cursor: pointer;">
												<div class="featured-details-two" :style="CardStyleObject" >
													<div class="product-item-two">
														<div class="product-img-two" >
															<a href="javascript:void(0);" style="font-size: 18px !important;">
																<img class="img-fluid" alt="Img" :src="item.image">
															</a>									
														</div>
														<div class="course-details-content">
															<div><p class="me-3" >{{ item.name }}</p></div>	
														</div>
													</div>
												</div>
											</div>
										</template>
										<template v-if="Gosterim == 'child'">
											<h5 v-if="ChildData.length == 0" style="text-align: center;" :style="Text1StyleObject">There is no details.</h5>
											<div v-for="(item) in ChildData" :key="item.id" class="col-xl-2 col-lg-2 col-md-3 col-sm-4 d-flex" 
											    v-on:click="getScorms(item.id)" style="cursor: pointer;">
												<div class="featured-details-two" :style="CardStyleObject" >
													<div class="product-item-two">
														<div class="product-img-two" >
															<a href="javascript:void(0);" style="font-size: 18px !important;">
																<img class="img-fluid" alt="Img" :src="item.image">
															</a>									
														</div>
														<div class="course-details-content">
															<div><p class="me-3" >{{ item.name }}</p></div>	
														</div>
													</div>
												</div>
											</div>
										</template>
										<!-- <template v-if="Gosterim == 'scorm'">
											<h5 v-if="ScormData.length == 0" style="text-align: center;" :style="Text1StyleObject">There is no contents.</h5>
											<div v-for="(item) in ScormData" :key="item.id" class="col-xl-2 col-lg-3 col-md-3 col-sm-4 d-flex" 
											 	v-on:click="DerseGit(item.id)" style="cursor: pointer;">
												<div class="featured-details-two" :style="CardStyleObject" >
													<div class="product-item-two">
														<div class="product-img-two" >
															<a href="javascript:void(0);" style="font-size: 18px !important;">
																<img class="img-fluid" alt="Img" :src="item.image">
															</a>									
														</div>
														<div class="course-details-content">
															<div><p class="me-3" >{{ item.name }}</p></div>	
														</div>
													</div>
												</div>
											</div>
										</template> -->

										<Courses v-if="Gosterim == 'scorm'" :Gosterim="`resource`"></Courses>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>

			</div>

		</section>
		
	</div>
</template>

<script>
import {mapGetters} from "vuex";
import LmsService from '@/services/lms.service';
import moment from 'moment';
import Content from '@/services/content';
import Courses from "./Component.Course.vue";



export default {
	// eslint-disable-next-line vue/multi-word-component-names
	name: "resources",
	// props:{
	// 	Gosterim:null
	// },
	components: { Courses},

	data() {
		return {
			Aranan: "",
			AramaSonuclari: [],
			Hazir : false,
			Resources:[],
			ParentData: [],
			ParentId: null,
			ChildData: [],
			ChildId: null,
			Scorms: [],
			ScormData: [],
			Gosterim: 'parent',
			SistemTarihi: null,
			GecerliSayfa: 1,
			KayitSayisi: 0,
			InnerLoading: true,
		};
	},
	
	async beforeMount() {
		const Izin = this.getRol =='teacher' ? this.getOkulAyarlar.OgretmenMenu3 : this.getOkulAyarlar.OgrenciMenu3
		if(Izin == 0){
			this.$router.push( { path : "/home" } )
			return
		}
		await this.SistemTarihiGetir()
		await this.getResources()
	},

	computed:{
		...mapGetters(['getKullaniciDetaylari','getOkulAyarlar','getRol','getScormView','Button1StyleObject','CardStyleObject','Text1StyleObject']),

		ToplamKayitSayisi() {
			return this.KayitSayisi
		},

	},
	methods: {
		DateFormat(value){
        	return moment(value).format("DD.MM.YYYY"); 
    	},

		async SistemTarihiGetir(){
			try {
				const resData = await LmsService.SistemTarihiGetir()
				if(resData){
					this.SistemTarihi = resData
				}

			} catch (error) {
				return
			}
		},

		BaslikGetir(){
			if(this.ParentId > 0 && this.Gosterim == 'child'){
				return this.Resources.find((x)=> x.id == this.ParentId).name
			} else 
			if(this.ChildId > 0 && this.Gosterim == 'scorm'){
				return this.Resources.find((x)=> x.id == this.ChildId).name
			}
			// return this.Gosterim == 'child' ? this.Resources.find((x)=> x.parent == this.ParentId).name : this.Resources.find((x)=> x.parent == this.ChildId).name
		},
		async getResources(){
			try {
				this.$store.commit('setLoading', true)
				const res = await Content.getCollections()
				this.Resources = res.data.data
				this.parentResources();
				this.$store.commit('setLoading', false)
				this.Hazir = true
				 
			} catch (error) {
				this.Hazir = true
				this.$store.commit('setLoading', false)
				console.log('Colletion yüklenemedi')
			}
		},

		async parentResources(){
			this.KayitSayisi = 0
			if(this.$store.state.previousRoute == '/scormView') {
				this.ParentId = this.Resources.find(x=> x.id == this.ChildId)
				this.getScorms(this.getScormView.assignment_id)
				this.childResources(this.Resources.find((x)=> x.id == this.ChildId).parent)
				this.Gosterim ='scorm'
			} else{
				this.Gosterim = 'parent'
			}

			try {
				const ParentResources = this.Resources
				this.ParentData = ParentResources.filter((x)=> x.parent == 0 && x.id !== 213 && x.id !== 531 && x.id !== 530)
				this.KayitSayisi = ParentResources.length

			} catch (error) {
				console.log('Resouces hata:',error)
				return
			}
		},

		childResources(id){
			this.KayitSayisi = 0
			this.Gosterim = 'child'
			this.ParentId = id
			try {
				const ParentResources = this.Resources
				this.ChildData = ParentResources.filter((x)=> x.parent == id )
				this.KayitSayisi = this.ChildData.length
			} catch (error) {
				console.log('Resouces hata:',error)
				return
			}
    	},

		async getScorms(id){
			this.Gosterim = 'scorm'
			this.ChildId = id
			this.ScormData = []
			try {
				this.$store.commit('setLoading', true)
				const res = await Content.getScorms(id)
				this.ScormData = res.data
				this.Scorms = res.data
				this.$store.commit('setLoading', false)
			} catch (error) {
				this.$store.commit('setLoading', false)
				console.log('Scorm Data yüklenemedi')
			}
		},

		GeriGit(){
			if (this.Gosterim =='child'){
				this.Gosterim = 'parent'
				this.ParentId = null
				this.Aranan = ''
			} else
			if(this.Gosterim =='scorm'){
				this.Gosterim = 'child'
				this.Aranan = ''
			}
		},

		DerseGit(id){
			const SData = this.ScormData.find(x=> x.id == id)
			const ScormView = {
				scorm_id: SData.id,
				assignment_id: SData.collection,
				scormTitle: SData.name,
				scormDesc: SData.desc,
				scormCode: SData.scorm_code,
				packageType: 'scorm',
				timestamp: null,
			};

        	this.$router.push({ name: 'scormView', params: { ScormView } });
		},

		// AramaYap() {
		// 	if(this.ParentData.length > 0 && this.Aranan !=''){
		// 		const Filtre = this.Resources.filter((x)=> x.parent == 0 && x.id !== 213 && x.id !== 531 && x.id !== 530)
		// 		// this.Assignments = Filtre;
		// 		const query = this.Aranan.toLowerCase();
		// 		this.ParentData =Filtre.filter((item) =>
		// 			// this.deepSearch(item, query)
		// 			this.searchInSpecificKeys(item, query)					
		// 		);
		// 	} else {
		// 		this.parentResources()
		// 	}			
		// 	this.KayitSayisi = this.ParentData.length
		// },

		AramaYap() {
			if(this.Aranan !=''){
				if(this.Gosterim =='parent' && this.ParentData.length == 0) return
				if(this.Gosterim =='child' && this.ChildData.length == 0) return
				if(this.Gosterim =='scorm' && this.ScormData.length == 0) return

				const Filtre = (
						this.Gosterim =='parent' 
						? this.Resources.filter((x)=> x.parent == 0 && x.id !== 213 && x.id !== 531 && x.id !== 530)
						: (this.Gosterim =='child' ? this.Resources.filter((x)=> x.parent == this.ParentId) : this.ScormData)
						
						// : this.Resources.filter((x)=> x.parent == this.ParentId)						
				)
				// this.Assignments = Filtre;
				const query = this.Aranan.toLowerCase();
				const AramaSonuc = Filtre.filter((item) =>
					// this.deepSearch(item, query)
					this.searchInSpecificKeys(item, query)					
				);
				// this.Gosterim =='parent' ? this.ParentData = AramaSonuc : this.ChildData = AramaSonuc
				this.Gosterim =='parent' ? this.ParentData = AramaSonuc : (this.Gosterim =='child' ? this.ChildData = AramaSonuc : this.ScormData = AramaSonuc)

			} else {
				this.Gosterim =='parent' ? this.parentResources() : (this.Gosterim =='child' ? this.childResources(this.ParentId) : this.ScormData = this.Scorms)
			}			
			this.KayitSayisi = this.ParentData.length
		},
		
		deepSearch(obj, query) {
			// Tüm anahtarları ve değerleri kontrol etmek için recursive arama
			for (const key in obj) {
				if (Object.prototype.hasOwnProperty.call(obj, key)) {
					const value = obj[key];

					if (
						key.toLowerCase().includes(query) || // Anahtar sorguyu içeriyor mu?
						(typeof value === "string" &&
						value.toLowerCase().includes(query)) || // Değer string olarak sorguyu içeriyor mu?
						(typeof value === "number" && value.toString().includes(query)) // Değer sayı ise sorguyu içeriyor mu?
					) {
						return true;
					}

					// Alt nesneler veya diziler varsa onları da kontrol et
					if (typeof value === "object" && value !== null) {
					if (this.deepSearch(value, query)) {
						return true;
						}
					}
				}
			}
			return false;
		},

		searchInSpecificKeys(obj, query) {
			// Sadece belirli anahtarlar üzerinde arama yapılacak
			const keysToSearch = ["name"];
			for (const key of keysToSearch) {
				if (Object.prototype.hasOwnProperty.call(obj, key)) {
				const value = obj[key];
				if (
					typeof value === "string" &&
					value.toLowerCase().includes(query)
				) {
					return true;
				}
				}
			}

			// Alt nesne kontrolü (örneğin scorm)
			if (obj.scorm) {
				for (const key of keysToSearch) {
				if (Object.prototype.hasOwnProperty.call(obj.scorm, key)) {
					const value = obj.scorm[key];
					if (
					typeof value === "string" &&
					value.toLowerCase().includes(query)
					) {
					return true;
					}
				}
				}
			}

			return false;
		},
		

		activated() {
			

		},
		created () {
		}
	}
}
</script>

<style scoped>



</style>
