<template>
    <div>
        
        <div id="content" ref="content">
            <nav v-if="isReady" role="navigation">
                <div class="menu">
                    <div class="sizing-btns THMbutton THMbuttonShadow" :style="Button1StyleObject" @click="fit()">{{isFit ? 'Original Size' : 'Fit to Window'}}</div>
                    <div v-if="this.getRol !== 'teacher' && this.getKullaniciDetaylari.title =='demo'" 
                        class="save-btn THMbutton THMbuttonShadow" :style="Button2StyleObject" @click="saveAndExit()" style="cursor: pointer;">
                        Exit
                    </div>
                    <div v-else class="save-btn THMbutton THMbuttonShadow" :style="Button2StyleObject" @click="saveAndExit()" style="cursor: pointer;">Save and Exit</div>
                </div>
            </nav>

            <section v-if="!isReady" class="home-two-slide d-flex align-items-center" style="margin-top: 131px; justify-content:center">
                <div class="row ">
                <span  class="loader"></span>
                </div>
		    </section>

            <iframe 
            v-if="isLoaded"
            id="iframe"
            ref="iframe"
            :style="[styleFrame, {opacity: isReady ? 1 : 0}]"
            allowfullscreen="allowfullscreen"
            :src="`https://content.ueslms.com/scorms/${ScormViewData.scormCode}/index_new.html`">
            </iframe>
        </div>
        <div>
            
        </div>
            
    </div>
</template>
  
<script>
  /* eslint-disable */
import { mapState } from 'vuex';
import {mapGetters} from "vuex";
import contentService from '@/services/content';
// import { colorShades, getTextColorBasedOnBackground } from "@/global/color";

  
export default {
name: 'Scorm',
// props: ['scormData', 'scormType'],
// props: ['packageId', 'scormTitle', 'scormDesc', 'scormCode', 'timestamp', 'packageType'],
data() {
    return {
    isLoaded: false,
    isReady: false,
    state: null,
    isFit: true,
    styleFrame: {
        left: 0,
        transform: 0,
    },
    scormWidth: 0,
    scormHeight: 0,
    ScormViewData: {}
    };
},
// computed: {
//   ...mapGetters([
//     't',
//     'details',
//   ]),
// },
computed: {
    ...mapState(['getScormView','getRol']),
	...mapGetters(['getKullaniciDetaylari','Button1StyleObject','Button2StyleObject','ScrollBarColor']),

},
methods: {
    // ...mapMutations([
    //   'setScore',
    // ]),
    // ...mapActions([
    //   'setAssignments',
    //   'setReaders',
    //   'refreshToken',
    // ]),

    sentState() {
        if (!this.state) return;
        const iframeWindow = this.$refs.iframe.contentWindow || this.$refs.iframe.contentDocument;
        const message = `SET_STATE;|${this.state}`;
        iframeWindow.postMessage(message, '*');
        console.log('SENT STATE (in app)');
    },
    getState() {
        const data = {
            user_id: Number(this.$store.state.KullaniciDetaylari.user_id),
            scorm_id: Number(this.ScormViewData.scorm_id),
            stateable_type: this.ScormViewData.packageType ? this.ScormViewData.packageType : 'package',
            stateable_id: Number(this.ScormViewData.assignment_id),
        };
        contentService.loadState(data).then((res) => {
            // console.log('res', res);
            if (res[0]) this.state = res[0].state;
            this.isLoaded = true;
        }).catch((err) => {
            console.log('ERR', err);
        });
    },
    saveStateScore(state, score) {
        const req = {
            user_id: Number(this.$store.state.KullaniciDetaylari.user_id),
            scorm_id: Number(this.ScormViewData.scorm_id),
            stateable_type: this.ScormViewData.packageType ? this.ScormViewData.packageType : 'package',
            stateable_id: Number(this.ScormViewData.assignment_id),
            state,
            score: `{"score": ${JSON.stringify(score)}}`,
        };
        if (this.getRol !== 'teacher' && this.getKullaniciDetaylari.title !== 'demo') {
            contentService.saveStateScore(req).then((res) => {
            this.$router.go(-1);
            }).catch((err) => {
            console.log('ERR', err);
            });
        } else {
        this.$router.go(-1);
        }
    },
    fit() {
        this.isFit = !this.isFit;
        this.onResize();

    },
    // onResize() {
    //   const contentWidth = this.$refs.content.offsetWidth;
    //   const contentHeight = this.$refs.content.offsetHeight;
    //   let sizeContent = contentWidth;
    //   let sizeScorm = this.scormWidth;
    //   let scormWidth = this.scormWidth;
    //   let isWide = contentWidth / this.scormWidth > contentHeight / this.scormHeight;
    //   if (isWide) {
    //     if (this.isFit) {
    //       sizeContent = contentHeight;
    //       sizeScorm = this.scormHeight;
    //     } else {
    //       sizeScorm = this.scormWidth + 18;
    //       scormWidth += 18; 
    //     }
    //   }
    //   const rate = sizeContent / sizeScorm;
    //   this.styleFrame.left = `${(contentWidth - (scormWidth * rate)) / 2}px`;
    //   this.styleFrame.transform = `scale(${rate})`;
    // },
    // fillResize(width, height) {
    //   if (!this.$refs.iframe) return;
    //   this.scormWidth = width + 20;
    //   this.scormHeight = height + 20;
    //   this.$refs.iframe.style.width = `${this.scormWidth}px`;
    //   this.$refs.iframe.style.height = `${this.scormHeight}px`;
    //   this.onResize();
    //   this.isReady = true;
    // },
    onResize() {
        // console.log('--', this.$refs.content.clientWidth);
        // console.log(' --', this.$refs.content.offsetWidth);
        const contentWidth = this.$refs.content.offsetWidth;
        const contentHeight = this.$refs.content.offsetHeight - 50;
        let sizeContent = contentWidth;
        let sizeScorm = this.scormWidth;
        let scormWidth = this.scormWidth;
        let isWide = contentWidth / this.scormWidth > contentHeight / this.scormHeight;
        if (this.isFit) {
            if (isWide) {
            sizeContent = contentHeight;
            sizeScorm = this.scormHeight;
            } else {
            sizeContent = contentWidth;
            sizeScorm = this.scormWidth;
            }
            this.styleFrame.margin = '0';
            const rate = sizeContent / sizeScorm;
            this.styleFrame.left = `${(contentWidth - (scormWidth * rate)) / 2}px`;
            this.styleFrame.transform = `scale(${rate})`;
        } else {
            // sizeScorm = this.scormWidth + 18;
            // scormWidth += 18;
            this.styleFrame.left = '0';
            this.styleFrame.right = '0';
            this.styleFrame.margin = 'auto';
            this.styleFrame.transform = `scale(1)`;
            this.$refs.content.style.overflowX = 'auto';
        }
    },
    fillResize(width, height) {
        if (!this.$refs.iframe) return;
        this.scormWidth = width + 40;
        this.scormHeight = height + 40;
        this.$refs.iframe.style.width = `${this.scormWidth - 20}px`;
        this.$refs.iframe.style.height = `${this.scormHeight - 20}px`;
        this.onResize();
        this.isReady = true;
    },

    saveAndExit() {
        this.isReady = false;
        const iframeWindow = this.$refs.iframe.contentWindow || this.$refs.iframe.contentDocument;
        iframeWindow.postMessage('SAVEANDCLOSE', '*');
    },

    onMessageReceived(event) {
    if (event.data.indexOf('LOADED') === 0) {
        this.isSuccess = true;
        console.log('RECEIVED LOADED (in app)');
    }else if (event.data.indexOf('PLAYER_LOADED') === 0) {
        this.sentState();
        console.log('RECEIVED PLAYER LOADED (in app)');
    } else if (event.data.indexOf('GET_STATE_SCORE;|') === 0) {
        const splitData = event.data.split(';|');
        this.saveStateScore(splitData[1], JSON.parse(splitData[2]));
        console.log('RECEIVED STATE AND SCORE (in app)');
    } else if (event.data.indexOf('RESIZE:') === 0) {
        const sizes = event.data.split('RESIZE:');
        const width = sizes[1].split(';')[0];
        const height = sizes[1].split(';')[1];
        this.fillResize(parseFloat(width), parseFloat(height));
    }
    },
},
async beforeMount() {
    if(this.$route.params.ScormView){
        this.ScormViewData = this.$route.params.ScormView; 
        this.$store.commit('setScormView',this.$route.params.ScormView)
        this.InnerLoading=false

        if (!this.ScormViewData.scormCode) {
            this.$router.push('/');
        } else {
            this.getState();
        }
    } else {
        this.ScormViewData = await this.getScormView
        this.InnerLoading=false

        if (!this.ScormViewData.scormCode) {
            this.$router.push('/');
        } else {
            this.getState();
        }
    }

    // if (!this.ScormViewData.scormCode) {
    //     this.$router.push('/');
    // } else {
    //     this.getState();
    // }
},

mounted() {  
    document.body.style.overflowY = 'auto';
    // document.body.style.overflowX = 'visible';
    window.addEventListener('resize', this.onResize);
    window.addEventListener('message', this.onMessageReceived, false);
},

beforeDestroy() {
    // document.body.style.overflowY = 'hidden';
    document.body.style.overflowY = 'hidden';

    window.removeEventListener('resize', this.onResize);
    window.removeEventListener('message', this.onMessageReceived);
},
};
</script>
  
  <!-- <style scoped></style> -->
<style scoped>

#content{
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  overflow-x: hidden;
}
#content > nav {
  /* background: #222222; */
  background: var(--background) !important;
  overflow: auto;
}
#content > nav > .header {
  display: inline-block;
}
#content > nav > .menu > .sizing-btns,
#content > nav > .menu .save-btn {
  text-align: center;
  min-width: 168px;
  margin: 5px;
  padding: 8px;
  color: #fff;
  background-color: var(--color) !important;
  border-color: var(--color) !important;
  border-radius: 3px;
  font-size: 20px;
  line-height: 20px;
  float: left;
  cursor: pointer;
}
#content > nav > .menu > .sizing-btns:hover,
#content > nav > .menu .save-btn:hover {
  background-color:  var(--color-hover) !important;;
}
#content > nav > .menu > .sizing-btns:active,
#content > nav > .menu .save-btn:active {
  box-shadow: 0 0 4px white;
  background-color: #68b1ff;
}
#content > nav > .menu > .save-btn {
  float: right;
}
#content > #iframe {
  position: absolute;
  left: 0;
  border: 10px hidden #00dfff;
  transform-origin: 0% 0%;
}
#content > .loading {
  position: fixed;
  width: 100vw;
  height: 100vh;
  left: 0;
  top: 0;
  background-color: white;
}
#content > .loading > .icon{
  position: absolute;
  margin: auto;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  width: 30vh;
  height: 30vh;
  /* background-image: url(/static/img/lmsLoding.gif); */
  background-size: 100%;
}
@keyframes rot {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}



</style>

  