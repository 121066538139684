<template >
	<div>
		<!-- <section  class="home-two-slide d-flex align-items-center" style="margin-top: 131px; justify-content:center"> -->
		<!-- <section v-if="InnerLoading" class="home-two-slide d-flex align-items-center" style="margin-top: 131px; justify-content:center">
			<div class="row ">
			<span  class="loader"></span>
			</div>
		</section> -->

		<section class="home-two-slide d-flex align-items-center" >

			<div class="container">
				<div class="row " >					
					<div class="col-lg-12 col-12" data-aos="fade-up">
						<div class="home-slide-face">						
							<div class="container">
								<div class="header-two-title text-center" data-aos="fade-up">									
									<p class="tagline" style="margin-bottom: -1rem;" :style="Text1StyleObject" >
										Assignments Report
									</p>
								</div>

								<div class="featured-courses-two" style="margin-bottom: 25px!important;">
									<div class="row" style="justify-content: center;">

										<!-- <template v-if="Gosterim"> -->
										<template >
											<div class="settings-menu p-0">												
												<div class="checkout-form">
																
													<div class="tab-pane active show">
														<div class="row">
															<div class="col-md-6 ">
																<div class="input-block">
																	<label class="form-control-label">Assignmnet Type</label>
																	<v-select 
																	@input="AssigneeListele()"
																	v-model="SeciliAssignmentTip"
																	:options="AssignmentTip"
																	:reduce="(option) => option.name"
																	label="title">
																	</v-select>
																</div>
															</div>
															<div class="col-md-6 ">
																<div class="input-block">
																	<label class="form-control-label">Assignee</label>
																	<v-select 
																	@input="Filtre()"																	
																	v-model="SeciliAssignee"
																	:options="AssigneeListe"
																	:reduce="(option) => option.name"
																	label="name">
																	</v-select>
																</div>
															</div>
														</div>

														<div class="row ">															
															<div class="col-md-3 ">
																<div class="input-block">
																	<label class="form-control-label">Start Date</label>
																	<date-picker  v-model="StartDate" valueType="YYYY-MM-DD" :format="format" placeholder="Start Date (Required)" style="padding-top: 4px; display: inline;"
																		:style="InputStyleObject" @change="Filtre()" >
																		<template v-slot:header="{ emit }">
																		<button class="mx-btn mx-btn-text" @click="emit(new Date())">Bugün</button>
																		</template>
																	</date-picker>
																</div>
															</div>
															<div class="col-md-3">
																<div class="input-block">
																	<label class="form-control-label">Due Date  </label>
																	<date-picker  v-model="DueDate" valueType="YYYY-MM-DD" :format="format" placeholder="Due Date (Required)" style="padding-top: 4px; display: inline;"
																		:style="InputStyleObject" @change="Filtre()" >
																		<template v-slot:header="{ emit }">
																		<button class="mx-btn mx-btn-text" @click="emit(new Date())">Bugün</button>
																		</template>
																	</date-picker>
																</div>																
															</div>
															<div class="col-md-6 ">
																<div class="input-block">
																	<label class="form-control-label">Assignments</label>
																	<v-select placeholder="Select a Course"	
																	@input="TabloTemizle()"																																	
																	v-model="SeciliAssignment"
																	:options="AssignmentListe"
																	:reduce="(option) => option.title"
																	label="title">
																	</v-select>
																</div>
															</div>
														</div>

														<div class="about-button  more-details text-center">
															<button class="btn btn-primary btn-start THMbutton THMbuttonShadow" @click="AssignmentRapor()"
															:class="(RaporButonAktif() == true) ? '':'li-disabled'"
															:style="Button1StyleObject">Get Report 														
															</button>															
														</div>


														<section v-if="InnerLoading" class="home-two-slide d-flex align-items-center" style="margin-top: 131px; justify-content:center">
															<div class="row ">
															<span  class="loader"></span>
															</div>
														</section>
														<div v-else class="table-responsive custom-table" style="margin-top: 15px;">
															<h5 v-if="RaporAnaTablo.length == 0 && Hazir==true" style="text-align: center;" :style="Text1StyleObject">There is no data.</h5>

															<table v-if="RaporAnaTablo.length > 0" class="table table-hover table-nowrap mb-0" :style="TableStyleObject">
																<thead>
																<tr>
																	<th class="text-center" >#</th>																	
																	<th >Student Name</th>
																	<th v-if="SeciliAssignmentTip == 'individual'">Lesson Name</th>
																	<th class="text-center">T.Checks</th>
																	<th class="text-center">T.Errors</th>
																	<th class="text-center">T.Mistakes</th>
																	<th class="text-center">Average Score</th>
																	<th class="text-center">T.Time</th>
																	<th class="text-center" style>Details</th>
																</tr>
																</thead>
																<tbody v-for="(item,i) in RaporAnaTablo" :key="i">
																	<tr style="height:20px !important" >
																		<td class="text-center">{{i+1}}</td>																																				
																		<td><span class="title-course">{{item.name}}</span></td>
																		<td v-if="SeciliAssignmentTip == 'individual'">
																			<span class="title-course">{{item.scorm_name}}</span>
																		</td>
																		<td class="text-center">{{item.total_checks_count}}</td>
																		<td class="text-center">{{item.total_errors_count}}</td>
																		<td class="text-center">{{item.total_mistake_count}}</td>
																		<td class="text-center">{{item.avg_score | sayi_format(2,true)}}%</td>
																		<td class="text-center">{{millisToMinutesAndSeconds(item.total_time)}}</td>
																																				
																		<td class="text-center">
																			<!-- https://feathericons.dev/?search=chevrons-down&iconset=feather -->
																		<svg v-if="DetayGoster.includes(i)" @click="DetayGosterGizle(i)" title="Hide Details" v-tippy style="cursor: pointer;"
																		viewBox="0 0 24 24" width="24" height="24" class="main-grid-item-icon" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2">
																			<polyline points="17 11 12 6 7 11" />
																			<polyline points="17 18 12 13 7 18" />
																		</svg>
																		<svg v-else @click="DetayGosterGizle(i)" title="Show Details" v-tippy style="cursor: pointer;"
																		viewBox="0 0 24 24" width="24" height="24" class="main-grid-item-icon" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2">
																			<polyline points="7 13 12 18 17 13" />
																			<polyline points="7 6 12 11 17 6" />
																		</svg>

																		</td>																															

																	</tr>
																	<tr>
																		<td colspan="9" :id="i" v-if="DetayGoster.includes(i)">
																			<div :id="i" v-if="DetayGoster.includes(i)"> 
																				<ReportUserDetails 
																					:DetailData= "AssignmentDetayData" 
																					:UserId="item.user_id"
																					:AssignmentId="item.assignment_id??0">
																				</ReportUserDetails>
																			</div>
																		</td> 
																	</tr>																	
																</tbody>
															</table>
														</div>
													</div>

													
												</div>
											</div>
										</template>
									</div>
								</div>
								
							</div>


						</div>
					</div>
				</div>
			</div>
		</section>
		
		<EditAssignment v-if="Modal" :Mod="Secilenler.length ==0 || Secilenler.length ==1 ? 'single':'multi'" :SingleEditIndex="SingleEditIndex">	
		</EditAssignment>	

	</div>
</template>

<script>
import {mapGetters} from "vuex";
import LmsService from '@/services/lms.service';
import ContentService from '@/services/content';
import moment from 'moment';
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import ReportUserDetails from "./Component.ReportUserDetails.vue";
// import EditAssignment from "./Component.EditAssignment.vue";


// import DatePicker from 'vuejs-datepicker';


export default {
	// eslint-disable-next-line vue/multi-word-component-names
	name: "AssignmentReport",
	// props:{
	// 	Gosterim:null
	// },
	components: { DatePicker, ReportUserDetails},

	data() {
		return {
			Hazir : false,
			InnerLoading: false,
			AssignmentTip: [{name:"group",title:"Group"},{name:"individual",title:"Individual"}],
			SeciliAssignmentTip:null,
			GroupAssignments: [],
			IndividualAssignments: [],
			AssigneeListe: [],
			SeciliAssignee: null,
			AssignmentListe:[],
			SeciliAssignment:null,

			AssignmentsGosterim: [],
			SistemTarihi: null,
			StartDate : null,
			DueDate: null,
			format: 'DD.MM.YYYY',
			DetayGoster:[],
			Modal: false,
			AssignmentDetayData:[],
			RaporAnaTablo: [],
		};
	},
	
	async beforeMount() {
		await this.SistemTarihiGetir()
		// ContentService.OgretmenOgrencileriGetir(this.$store.state.KullaniciDetaylari.organization)
		this.IlkYukleme();
	},

	async mounted() {
		const Izin = this.getRol =='teacher' ? this.getOkulAyarlar.OgretmenMenu5 : 0
		if(Izin == 0){
			this.$router.push( { path : "/home" } )
			return
		}
		this.SistemTarihiGetir()
	},

	computed:{
		...mapGetters(['getKullaniciDetaylari','getRol','getOkulAyarlar','Button1StyleObject','Button2StyleObject','Text1StyleObject','InputStyleObject','TableStyleObject',
		'InputStyleObject','SaveButtonStyleObject']),
		ToplamKayitSayisi() {
			return this.KayitSayisi
		},

		

	},
	methods: {
		DateFormat(value){
        	return moment(value).format("DD.MM.YYYY"); 
    	},

		TabloTemizle(){
			this.Hazir = false
			this.RaporAnaTablo = []
		},
		IsExpired(value){
        	var a = new Date(this.SistemTarihi); 
        	var b =new Date(value) 
			// a.getTime() - b.getTime()
			return (a.getTime() > b.getTime()) ? false : true
    	},

		millisToMinutesAndSeconds(millis) {
			let minutes = Math.floor(millis / 60000);
			let seconds = ((millis % 60000) / 1000).toFixed(0);
			if (minutes < 10) {
				minutes = `0${minutes}`;
			}
			if (seconds < 10) {
				seconds = `${minutes}`;
			}
			return `${minutes}:${seconds}`;
		},

		DetayGosterGizle(id) {
			const index = this.DetayGoster.indexOf(id);
			if (index > -1) {
				this.DetayGoster.splice(index, 1); // Diziden id'yi çıkar
			} else {
				this.DetayGoster.push(id); // Diziye id'yi ekle
			}
		},

		Durum(start_date, end_date){
			// console.log(start_date, end_date, this.SistemTarihi)
        	var st = new Date(this.SistemTarihi); 
        	var sd =new Date(start_date) 
        	var ed =new Date(end_date) 
			// console.log(sd.getTime(), ed.getTime(), st.getTime())

			if (sd.getTime() > st.getTime()){
				return 'Not Started'
			} else if (ed.getTime() < st.getTime()){
				return 'Expired'
			} else { return 'Continues'}
    	},


		async SistemTarihiGetir(){
			try {
				const resData = await LmsService.SistemTarihiGetir()
				if(resData){
					this.SistemTarihi = resData
				}

			} catch (error) {
				return
			}
		},

        async IlkYukleme(){
            if (this.getKullaniciDetaylari) {
				await this.AssignmentsGetir()
        	}
		},	

		async AssignmentsGetir(){
				try {
					// this.$store.commit('setLoading', true)
					const resGroup = await ContentService.AssignmentsGetir()
					// this.$store.commit('setLoading', false)
					if(resGroup.success == true){
						// title anahtar ekliyorum, assignment liste de farklı gösterim için lazım, obje anahtarları eşit olsun yüklemede ayarlandı
						this.GroupAssignments = resGroup.data.data
						this.GroupAssignments = this.GroupAssignments.map(item => {
							return { ...item, title: item.assignment_name + ' ( SD: '+ item.start_date.split(' ')[0]+' )'};
						});
					}

					const resIndividual = await ContentService.AssignmentsGetir('individual')
					// this.$store.commit('setLoading', false)
					if(resIndividual.success == true){
						this.IndividualAssignments = resIndividual.data.data
						this.IndividualAssignments = this.IndividualAssignments.map(item => {
							return { ...item, title: item.assignment_name + ' ( SD: '+ item.start_date.split(' ')[0]+' )'};
						});
					}
				} catch (error) {
					this.$toast.open({
						message: "Assignment listesi alınamadı!",
						type: "error",
						duration: 3000,
						dismissible: true,
						position : 'top'
					})
					return
				}
		},

		async AssigneeListele(){
			try {
				this.AssignmentListe = []
				this.AssigneeListe = []
				this.SeciliAssignee = null
				this.SeciliAssignment = null
				this.TabloTemizle()
				if(this.SeciliAssignmentTip != null){
					
					const AssigneeListe = this.SeciliAssignmentTip == 'group' ? this.GroupAssignments.map(({ name }) => ({ name })) : this.IndividualAssignments.map(({ name }) => ({ name })); 
					const UniqAssigneeListe = [
						...new Map(AssigneeListe.map(item => [JSON.stringify(item), item])).values()
					];
					this.AssigneeListe = UniqAssigneeListe
				}
			} catch (error) {
				console.log('Assignee Liste Hatası',error)
			}
		},

		Filtre(){
			this.AssignmentListe = []
			this.SeciliAssignment = null
			this.TabloTemizle()

			if (this.SeciliAssignmentTip == null || this.SeciliAssignee == null){
				return
			} else{
			
				const Asgmnt = this.SeciliAssignmentTip == 'group' ? this.GroupAssignments : this.IndividualAssignments
				// const Assignment = Asgmnt.map(({ assignment_name, name, create_date, start_date, end_date }) => ({ assignment_name, name, create_date, start_date, end_date  })); 
				const Assignment = Asgmnt.map(item => {
				return { ...item, due_date: moment(item.end_date).format('YYYY-MM-DD'), title: item.assignment_name + ' ( SD: '+ item.start_date.split(' ')[0]+' )'};
				}).map(({ assignment_name, name, start_date, due_date, title}) => ({ assignment_name, name, start_date, due_date, title}));	
				
				// tekil hale getiriyoruz
				const UniqAssignment = [
				...new Map(Assignment.map(item => [JSON.stringify(item), item])).values()
				];

				let KosulDizi = []
				let KosulItem = {}
				
				if(this.StartDate){
					KosulItem.key = 'start_date'
					// KosulItem.operator = this.Op1 == '='?'==':this.Op1
					KosulItem.operator = '>='
					KosulItem.value = this.StartDate.split(' ')[0]
					KosulDizi.push(KosulItem)
				}
				KosulItem = {}
				if(this.DueDate){
					KosulItem.key = 'end_date'
					KosulItem.operator = '<='
					KosulItem.value = this.DueDate.split(' ')[0]
					KosulDizi.push(KosulItem)

				}
				KosulItem = {}
				if(this.SeciliAssignee != null){
					KosulItem.key = 'name'
					KosulItem.operator = '=='
					KosulItem.value = this.SeciliAssignee
					KosulDizi.push(KosulItem)

				}
				const Filtre = this.Filtre_DinamikParametre(UniqAssignment, KosulDizi);
				this.AssignmentListe = Filtre
			}
		},

		Filtre_DinamikParametre(array, conditions) {
			return array.filter(item => {
				return conditions.every(condition => {
					const { key, operator, value } = condition;
					const itemValue = key == 'date' ? item[key]?.split(' ')[0] : item[key];
					switch (operator) {
					case '==': return itemValue == value;
					case '>=': return itemValue >= value;
					case '<=': return itemValue <= value;
					case '>': return itemValue > value;
					case '<': return itemValue < value;
					case '!=': return itemValue != value;
					default:
						this.$toast.open({
							message: "Unsupported operator!",
							type: "error",
							duration: 3000,
							dismissible: true,
							position : 'top'
						})
						return false;
					}
				});
			});
		},

		EditModalGosterKapat(index){
			this.SingleEditIndex = index
				
			if(this.Modal == true){
				this.SingleEditIndex = null
			}

			this.Modal = !this.Modal
		},

		RaporButonAktif(){
			if(this.SeciliAssignmentTip == null || this.SeciliAssignee == null || this.SeciliAssignment == null) {
				return false
			} else return true
		},

		async AssignmentRapor(){
			this.Hazir = false
			this.InnerLoading = true
			let AssignmentScorms = []
			this.TabloTemizle()
			this.AssignmentDetayData = []
			const Assignments = this.SeciliAssignmentTip == 'group' ? this.GroupAssignments : this.IndividualAssignments

			const Assignment = Assignments.filter(x=> x.title == this.SeciliAssignment && x.name == this.SeciliAssignee ); 
			AssignmentScorms =Assignment.map(item => item.assignment_id);

			const AssignmentDetayData = [];
			for (const item of AssignmentScorms) {  
				const ResDetayData = await ContentService.AssignmentSkorGetir(item); 
				AssignmentDetayData.push(ResDetayData.data);
			}

            this.AssignmentDetayData = AssignmentDetayData
			let YeniAssignmentData = []
			if(this.SeciliAssignmentTip == 'group'){
				for (const item of AssignmentDetayData) { 
					YeniAssignmentData = [...YeniAssignmentData, ...item.users];
				}

				for (const item of YeniAssignmentData) { 
					const userScores = YeniAssignmentData.filter(x => x.user_id === item.user_id);
					let ToplamCheck = 0;
					let ToplamErrors = 0;
					let ToplamMistake = 0;
					let ToplamScore = 0;
					let ToplamTime = 0;
					for (let i = 0; i < userScores.length; i += 1) {
						const element = userScores[i];
						ToplamCheck += element.scorm_scores.total_checks_count;
						ToplamErrors += element.scorm_scores.total_errors_count;
						ToplamMistake += element.scorm_scores.total_mistake_count;
						ToplamScore += element.scorm_scores.total_score;
						ToplamTime += element.scorm_scores.total_time;
					}
					const obj = {};
					obj.user_id = userScores[0].user_id;
					obj.name = userScores[0].name;
					obj.total_checks_count = ToplamCheck;
					obj.total_errors_count = ToplamErrors;
					obj.total_mistake_count = ToplamMistake;
					obj.avg_score = ToplamScore / userScores.length;
					obj.total_time = ToplamTime;
					this.RaporAnaTablo.push(obj);
				}

				// tekil hale getiriyoruz
				this.RaporAnaTablo = [
				...new Map(this.RaporAnaTablo.map(item => [JSON.stringify(item), item])).values()
				];
				this.Hazir = true
				this.InnerLoading = false

			} else{

				for (const item of AssignmentDetayData) { 
					const userScores = AssignmentDetayData.filter(x => x.users.user_id === item.users.user_id);
					// console.log(1,userScores)
					for (let i = 0; i < userScores.length; i += 1) {
						const element = userScores[i];
						// console.log(2,element)
						const obj = {};
						obj.user_id = userScores[0].users.user_id;
						obj.name = userScores[0].users.name;
						obj.total_checks_count = element.users.scorm_scores.total_checks_count;
						obj.total_errors_count = element.users.scorm_scores.total_errors_count;
						obj.total_mistake_count = element.users.scorm_scores.total_mistake_count;
						obj.avg_score = element.users.scorm_scores.total_score ;
						obj.total_time = element.users.scorm_scores.total_time;
						obj.scorm_name = element.scorm_name??null;
						obj.assignment_id = element.assignment_id??0;
						this.RaporAnaTablo.push(obj);
					}
				}

				// console.log(this.RaporAnaTablo)
				// tekil hale getiriyoruz
				this.RaporAnaTablo = [
				...new Map(this.RaporAnaTablo.map(item => [JSON.stringify(item), item])).values()
				];
				this.Hazir = true
				this.InnerLoading = false
			}
		},

		activated() {
			

		},
		created () {
		}
	}
}
</script>

<style scoped>

.text-center{
	text-align:center
}

.custom-table table th {
  background: var(--color) !important;
  font-weight: 500;
  color: #1b1b1b;
  padding: 15px 10px;
  /* border-color: #f4f6f9; */
}
/* .custom-table table td:hover {
  background-color: var(--color) !important;
} */
.table-hover>tbody>tr:hover>* {
    --bs-table-color-state: var(--color) !important;
    --bs-table-bg-state: var(--color-hover) !important;
}
label.form-label {
    font-size: 15px;
}





</style>
