import { render, staticRenderFns } from "./Resources.vue?vue&type=template&id=361ee496&scoped=true&"
import script from "./Resources.vue?vue&type=script&lang=js&"
export * from "./Resources.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "361ee496",
  null
  
)

export default component.exports