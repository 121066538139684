import axios from 'axios';
import {store} from "../components/store/store";
// import {store} from "@/components/store/store";
const instance = axios.create();
instance.defaults.timeout = 300000;

instance.interceptors.request.use((config) => {
//   const token = tools.cookie.get('token');
const token = store.state.token;
  config.baseURL = 'https://www.ueslms.com/api/v2';
  config.headers.Authorization = `Bearer ${token}`;
  return config;
});

instance.interceptors.response.use(res => res, err => err);
export default () => instance;
