<template>
    <div>
        <div class="announcement-head">
            <div class="announcement-titlte">
                <h6>Please select students or groups to assign. 
                    If you click checkbox near of the group name, that's meaning is you will assign as a group assignment. 
                    Otherwise, even if you click all checkbox one by one under the certain group name, that will assign as a individual assignments type.</h6>
            </div>
        </div>

        <div class="row">
            <div class="col-md-8 offset-md-1">
            <v-jstree :data="treeArr" show-checkbox multiple allow-batch whole-row @item-click="itemClick"></v-jstree>
            </div>
        </div>
    </div>
</template>

<script>
import { mapState } from 'vuex';

/*eslint-disable*/
export default {
    name: 'assignees',
    data() {
        return {
            treeData: [],
        };
    },

    computed: {
        ...mapState(['OgretmenOgrencileri']),
        treeArr() {
            return this.treeData;
        },
    },

    methods: {
        itemClick(node) {
            if (node.model.type === 'student' && !node.model.selected) {

                const gradeData = this.treeData.find(
                    x => x.value === node.model.parentValue
                );
                
                this.treeData.find(
                    x => x.value === node.model.parentValue
                ).selected = false;

            } else if (node.model.type === 'student' && node.model.selected) {
                const gradeData = this.treeData.find(
                    x => x.value === node.model.parentValue
                );

                let isAllSelected = true;
                for (const item of gradeData.children) {
                    if (!item.selected) {
                    isAllSelected = false;
                    break;
                    }
                }
                if (isAllSelected) {
                    this.treeData.find(
                    x => x.value === node.model.parentValue
                    ).selected = true;
                }
            }
        },

        ResetSelected() {
            const UpdateRecursive = (items) => {
                items.forEach((item) => {
                item.selected = false;
                if (item.children && item.children.length > 0) {
                    UpdateRecursive(item.children);
                }
                });
            };
            UpdateRecursive(this.$store.state.OgretmenOgrencileri);
        },
    },


    beforeMount() {
        this.ResetSelected()
        this.treeData = this.OgretmenOgrencileri;
        // this.treeData.forEach((x) => {
        //   x.children.forEach((y) => {
        //     y.disabled = true;
        //   });
        // });
    },

    beforeDestroy() {
        const obj = {};
        let classArr = [];
        let stuArr = [];
        for (const item of this.treeData) {

            if (item.selected) {
                classArr = [...classArr, item.value];
            } else {
                stuArr = [
                    ...stuArr,
                    ...item.children.filter(x => x.selected).map(x => x.value),
                ];
            }
        }

        if (classArr.length > 0 && stuArr.length > 0) {
            obj.assignment_type = 'mixed';
            obj.studentIds = stuArr;
            obj.groupIds = classArr;
        } else if(classArr.length > 0) {
            obj.assignment_type = 'group';
            obj.ids = classArr;
        } else if(stuArr.length > 0) {
            obj.assignment_type = 'individual';
            obj.ids = stuArr;
        }
        this.$parent.AssigneeData = obj;
    },
};
</script>

