<template>
	<div>
		<section v-if="!Hazir" class="home-two-slide d-flex align-items-center" style="margin-top: 131px; justify-content:center">
			<div class="row ">
			<span  class="loader"></span>
			</div>
		</section>
		<section class="topcategory-sec">
			<div class="container">
				<div v-if="getRol =='student'" class="header-two-title text-center" data-aos="fade-up">
					<p class="tagline" style="margin-bottom: -1rem;" :style="Text1StyleObject">Online Learning Tools</p>
					<!-- <h2>Top Category</h2> -->
					<div class="header-two-text">
						<!-- <p class="mb-0">Lorem ipsum dolor sit amet</p> -->
					</div>
				</div>
				<div class="top-category-group">
					
					<div class="row" style="justify-content: center;">
						<span v-if="!Hazir" class="loader"></span>
						<!-- <template v-if="Hazir && OgrenimAraclari.length > 0"> -->
						<template v-if="getRol =='student' && (Hazir && OgrenimAraclari.length > 0)">
							<div v-for="(item) in OgrenimAraclari" :key="`l-`+item.UrunId" class="col-xl-3 col-lg-4 col-md-4 col-sm-6 d-flex" data-aos="fade-down">
								<div class="categories-item flex-fill" :style="CardStyleObject">
									<div style="height: 100px;">
	
										<div class="categories-icon" style="text-align: center;">
											<img :src="`/assets/img/learningtools/${item.UrunGorselAdi}`" class="img-fluid">
										</div>
									</div>
									<div class="categories-content" :style="CardStyleObject">
										<h3 style="text-align: center; font-size: 16px!important;" :style="CardStyleObject">{{item.UrunAdi}}</h3>
									</div>
								</div>
							</div>							
						</template>
						<template v-if="getRol =='teacher' && (Hazir && DemoHesaplar.length > 0)">
							<div class="header-two-title text-center" data-aos="fade-up">
								<p class="tagline" style="margin-bottom: -1rem;" :style="Text1StyleObject">In-Class Tools</p>
								<!-- <h2>Top Category</h2> -->
								<div class="header-two-text">
									<!-- <p class="mb-0">Lorem ipsum dolor sit amet</p> -->
								</div>
							</div>
							<div v-for="(item) in DemoHesaplar" :key="`d-`+item.UrunId" class="col-xl-3 col-lg-4 col-md-4 col-sm-6 d-flex" data-aos="fade-down">
								<div class="categories-item in-class flex-fill" :style="CardStyleObject">
									<div style="height: 100px;">
	
										<div class="categories-icon" style="text-align: center;">
											<img :src="`/assets/img/learningtools/${item.UrunGorselAdi}`" class="img-fluid">
										</div>
									</div>
									<div class="categories-content" :style="CardStyleObject">
										<h3 style="text-align: center; font-size: 16px!important;" :style="CardStyleObject">In-Class</h3>
										<h3 style="text-align: center; font-size: 16px!important;" :style="CardStyleObject">{{item.UrunAdi}}</h3>
									</div>
								</div>
							</div>							
						</template>
					</div>
				</div>
			</div>
		</section>
	</div>
</template>

<script>
import {mapGetters} from "vuex";
import LmsService from '@/services/lms.service';

export default {
	// eslint-disable-next-line vue/multi-word-component-names
	name: "learningTools",
	data() {
		return {
			Hazir : false,
			// SinifSeviye: null,
            // SinifSube: null,
			// OgretmenSinifSeviyeleri: [],
			// OgretmenSinifSubeIdleri: [],
			// Rol: null,
			OgrenimAraclari: [],
			DemoHesaplar: []
			
		};
	},
	beforeMount() {
		this.IlkYukleme();
	},

	computed:{
		...mapGetters(['getKullaniciDetaylari','getSinifSeviye','getSinifSeviye','getKampusTipi','getKampusAdi','getKampusId','getOkulId',
		'getOkulAdi','getRol','getOgretmenSinifSeviyeleri','getOgretmenSinifSubeIdleri','CardStyleObject','Text1StyleObject']),

	},
	methods: {
        async IlkYukleme(){

            if (this.getKullaniciDetaylari) {
				// this.SinifSube = 
				// 	this.getKullaniciDetaylari.organization.find(x => x.type === 'grade') ? 
				// 	this.getKullaniciDetaylari.organization.find(x => x.type === 'grade').name : 0;

				// this.SinifSeviye = this.getSinifSeviye //this.SinifSube.split('-')[0] 

				// this.KampusTipi = this.getKullaniciDetaylari.organization.find(x => x.type === 'campus').school_type;
				// this.KampusAdi = this.getKullaniciDetaylari.organization.find(x => x.type === 'campus').name;
				// this.KampusId = this.getKullaniciDetaylari.organization.find(x => x.type === 'campus').id;
				// this.OkulId = this.getKullaniciDetaylari.organization.find(x => x.type === 'main').id;
				// this.OkulAdi = this.getKullaniciDetaylari.organization.find(x => x.type === 'main').name;
				// this.Rol = this.getKullaniciDetaylari.role_type;


				// const OgretmenSinifSeviyeleri = this.getKullaniciDetaylari.organization.filter((x) => x.type === "grade")
				// for (let key in OgretmenSinifSeviyeleri){
				// 	this.OgretmenSinifSeviyeleri.push(OgretmenSinifSeviyeleri[key].name.split("-")[0])
				// 	this.OgretmenSinifSubeIdleri.push(OgretmenSinifSeviyeleri[key].id)
				// }
				// this.OgretmenSinifSeviyeleri = [...new Set(this.OgretmenSinifSeviyeleri)] 
				// this.OgretmenSinifSubeIdleri = [...new Set(this.OgretmenSinifSubeIdleri)] 
				await this.OgrenimAraclariGetir()
        	}


		},

		async OgrenimAraclariGetir(){
			let Hata = null
			try {
				let SinifSubeIdler, SinifSeviyeler 
				if (this.Rol == 'teacher'){
					if(this.getOgretmenSinifSubeIdleri.length > 0){
						SinifSubeIdler = this.getOgretmenSinifSubeIdleri.map(value => ({ SinifId: value })); // anahtar adı olmayan listeye anahtar atama
					}

					if(this.getOgretmenSinifSeviyeleri.length > 0){
						SinifSeviyeler = this.getOgretmenSinifSeviyeleri.map(value => ({ SinifSeviye: value })); // anahtar adı olmayan listeye anahtar atama
					}

				} else{
					SinifSubeIdler = [{SinifId: this.getSinifId}]
					SinifSeviyeler = [{SinifSeviye: this.getSinifSeviye}]
				}

				const data = {
					OkulId: this.getOkulId,
					KampusId: this.getKampusId,
					SinifSeviye: SinifSeviyeler,
					SinifId: SinifSubeIdler,
				}	

				const dataInClass = {
					OkulId: this.getOkulId,
					SinifSeviye: SinifSeviyeler,
				}

				Hata = 1
				this.$store.commit('setLoading', true)
				const resData = await LmsService.OgrenimAraclariGetir(data)
				this.OgrenimAraclari = resData
				Hata = 2
				const resData1 = await LmsService.DemoHesaplariGetir(dataInClass)
				this.DemoHesaplar = resData1
                this.$store.commit('setLoading', false)
				this.Hazir = true
			} catch (error) {
				this.Hazir = true

                this.$store.commit('setLoading', false)
				this.$toast.open({
					message: (Hata == 1 )?"Lerarning Tools Araçları hatası!" : "InClass Araçları hatası!",
					type: "error",
					duration: 3000,
					dismissible: true,
					position : 'top'
				})
				return
			}
		},

		activated() {
			

		},
		created () {
		}
	}
}
</script>

<style scoped>


</style>
