

import Vue from 'vue'
import VueRouter from 'vue-router'
import LoginPage from "./components/auth/LoginPage"
import MainPage from "./components/mainpage/MainPage"
import Assignments from "./components/assignments/Assignments"
import CreateAssignment from "./components/assignments/CreateAssignment"
import AssignmentDetails from "./components/assignments/AssignmentDetails"
import AssignmentReport from "./components/assignments/AssignmentReport"
// import AssignmentsTeacher from "./components/assignments/AssignmentsTeacher"
import ScormView from "./components/scorms/ScormView"
import Links from "@/template/Links.vue"
import Resources from "@/components/resources/Resources.vue"
import Library from "@/components/library/Library.vue"
import InterActiveReader from "@/components/interActiveReaders/InterActiveReaders.vue"


import Global from "@/global";
import {store} from "@/components/store/store";

Vue.use(VueRouter) //VueRouter kullanmak için Vue.use() ile kullcd..anacağımızı belirtiyoruz, Vue kullanmak içinde import Vue from 'vue' ile çağırıyoruz.
const routes =[
// USER ROUT LAR    
    { name : "login", path : "/login", component : LoginPage, meta : { auth: false } },
    { name : "mainPage", path : "/", component : MainPage,  meta : { auth: true } },
    { name : "home", path : "/home", component : MainPage,  meta : { auth: true } },
    { name : "assignments", path : "/assignments", component : Assignments,  meta : { auth: true } },
    { name : "createAssignment", path : "/assignment/create", component : CreateAssignment,  meta : { auth: true, roles: ['teacher'] } },
    { name : "assignmentDetails", path : "/assignmentDetails", component : AssignmentDetails,  meta : { auth: true, roles: ['student'] } },
    { name : "assignmentReport", path : "/assignment/report", component : AssignmentReport,  meta : { auth: true, roles: ['teacher'] } },
    // { name : "assignmentsTeacher", path : "/assignments", component : AssignmentsTeacher,  meta : { auth: true } },
    { name : "scormView", path : "/scormView", component : ScormView,  meta : { auth: true } },
    { name : "interActiveReaders", path : "/interActiveReaders/:id", component : InterActiveReader,  meta : { auth: true, roles: ['teacher'] } },
    { name : "links", path : "/links", component : Links,  meta : { auth: true } },
    { name : "resources", path : "/resources", component : Resources,  meta : { auth: true } },
    { name : "library", path : "/library", component : Library,  meta : { auth: true } },


// ADMIN ROUTE LAR
    // { name : "mainPageAdmin", path : "/admin/home", component : MainPageAdmin, meta : { auth: true }, roles: ['admin'] },
    // { name : "productAssigns", path : "/admin/productAssigns", component : ProductAssigns, meta : { auth: true }},
    // { name : "assignProduct", path : "/admin/assignProduct", component : AssignProduct, meta : { auth: true }},
    // { name : "assignSchool", path : "/admin/assignSchool", component : AssignSchool, meta : { auth: true }},
    // { name : "editProductAssigns", path : "/admin/editProductAssigns/:id", component : CreateProductAssigns, meta : { auth: true }},
    { 
        // name : "PageNotFound",
        path : "*", redirect : "/home",
        // component : PageNotFound
    },


]

//Burada ise tanımları dosya içinde yapıyoruz ve dışarıdan kullanmak için export ediyoruz.
export const router = new VueRouter(
  {
     //mode : "history", // history yazınca hash kalkıyor ama gcloud da refreslerde hata veriyor
     routes: routes,
  }
)

// function getDeepRouteByNames(routeNames, routes) {
//     const routeName = routeNames.shift();
//     if (routeName) {
//       const searchRoutes = routes.find(x => x.name === routeName);
//       if (routeNames.length > 0) {
//         return getDeepRouteByNames(routeNames, searchRoutes);
//         // eslint-disable-next-line
//       } else {
//         return searchRoutes.roles ? searchRoutes.roles : [];
//       }
//     }
//   }

// async function isAuthenticated (){
//     // Burada token kontrolü yapılabilir
//     return await Global.cookie.getKullaniciDetaylari()
// }

// router.beforeEach((to, from, next) => {
//     if (to.meta.auth && !isAuthenticated()) {
//       // Eğer giriş yapılmamışsa, login sayfasına yönlendir
//       next({ name: 'Login' });
//     } else {
//       next(); // Yönlendirmeye izin ver
//     }
//   })

  router.beforeEach(async (to, from, next ) => {
    // console.log(from.name, from.meta, to.meta, to.name, to.path)
    let enter = null
    try {
        enter = await Global.cookie.getKullaniciDetaylari()
        // console.log(enter)                
    } catch (error) {
        // console.log('0',from.path, to.path, to.meta, enter)

        next('/login')
        return
    }

    store.commit('SET_PREVIOUS_ROUTE', from.fullPath);
    // const enter = await Global.cookie.getUserDetails()
    // console.log(enter)
    // const routeNames = to.matched.map(x => x.name);
    // const routeRoles = getDeepRouteByNames(routeNames, routes);
    // const Rol = store.state.users.KullaniciDetaylari.role_type

    //  console.log(routeNames,routeRoles,userRole)
    if ('auth' in to.meta && to.meta.auth && !enter) {
        //auth true olan herhangi bir sayfaya gitmeye çalışırsa ve giriş yoksa logine gönder
        // console.log('1',from.path, to.path, to.meta, enter)
        return next('/login')

    } else if('auth' in to.meta && !to.meta.auth){
        //login sayfasına geldik
        //Eğer giriş yapılmışsa logineden yönlendir
        // console.log('2',from.path, to.path, to.meta, enter)

        if(enter){   
        // console.log('2.1',from.path, to.path, to.meta, enter)

            return next({ name: 'home' });//await router.replace('/')            
            // return next({ name: 'mainPage' });//await router.replace('/')            
        }else{
        // console.log('2.2',from.path, to.path, to.meta, enter)

            next()
        }
        
    } else if ('auth' in to.meta && to.meta.auth && enter){
        // console.log('3',from.path, to.path, to.meta, enter)

        //giriş yapılmışsa sayfada gezinme
        if (to.path === '/login'){
            // console.log(2,store.state.users.userDetails.role)
            await router.replace('/home')
            return
        }  

        // if (to.path === '/assignmentsDetails' && to.meta.roles.includes(Rol)){
        //     // console.log(2,store.state.users.userDetails.role)
        //     next({ name: 'home' });
        //     return
        // }
        // if (to.meta.roles && !to.meta.roles.includes(Rol)){
        //     next({ name: 'Home' })
        // } else
          next();

    } else{
        next()         
    }

});





