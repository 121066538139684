<template >
	<div v-if="(this.getRol =='teacher' ? this.getOkulAyarlar.OgretmenMenu4 : this.getOkulAyarlar.OgrenciMenu4) ==1">
		<!-- <section  class="home-two-slide d-flex align-items-center" style="margin-top: 131px; justify-content:center"> -->
		<section v-if="Gosterim && InnerLoading" class="home-two-slide d-flex align-items-center" style="margin-top: 131px; justify-content:center">
			<div class="row ">
			<span  class="loader"></span>
			</div>
		</section>

		<section class="home-two-slide d-flex align-items-center" v-if=" Hazir && Assignments.length > 0">

			<div class="container">
				<div class="row " v-if="(Gosterim  && KayitSayisi > 0) || (!Gosterim && Hazir && Assignments.length > 0)">					
					<div class="col-lg-12 col-12" data-aos="fade-up">
						<div class="home-slide-face">						
							<div class="container">
								<div class="header-two-title text-center" data-aos="fade-up">
									<p v-if="Gosterim && KayitSayisi >0" class="tagline" style="margin-bottom: -1rem;" :style="Text1StyleObject" >
										Assignments ({{AssignmentsGosterim.length}}/{{Assignments.length}})
									</p>
									<p v-else-if="!Gosterim" class="tagline" style="margin-bottom: -1rem;" :style="Text1StyleObject" >
										Assignments 
									</p>
								</div>

								<div class="featured-courses-two" style="margin-bottom: 25px!important;">
									<div class="row" style="justify-content: center;">

										<!-- <template v-if="Gosterim"> -->
										<template >
											<!-- <span  v-if="Gosterim && InnerLoading" class="loader"></span> -->
											<div v-if="!Gosterim" class="wishlist-tab">
												<ul class="nav" role="tablist" style="justify-content: center">
													<li class="nav-item" role="presentation">
														<a class="THMbutton THMbuttonShadow" :class="this.AktifTab == 0 ? 'active': ''" 
														href="javascript:void(0);" :style="AktifTab==1?Button2StyleObject:Button1StyleObject" style="width: 195px; text-align: center;" role="tab" 
														v-on:click="AktifTabDegis(0)">													
															Current Assignments
														</a>
													</li>
													<li class="nav-item" role="presentation">
														<a class="THMbutton THMbuttonShadow" :class="this.AktifTab == 1 ? 'active': ''" 
														href="javascript:void(0);" :style="AktifTab==0?Button2StyleObject:Button1StyleObject" style="width: 195px; text-align: center;" role="tab" 
														v-on:click="AktifTabDegis(1)">
															Past Assignments
														</a>
													</li>
												</ul>												

												<div v-if="AssignmentsGosterim.length > 0 || Aranan !=''" class="fade-up" style="justify-items:center">
													<div class="banner-content">
														<form class="form">
															<!-- <div class="form-inner"> -->
																<div class="input-group">																	
																	<input v-model="Aranan" @keyup="AramaYap"
																	type="email" class="form-control" placeholder="Search">
																</div>
															<!-- </div> -->
														</form>
													</div>
												</div>
											</div>										


											<h5 v-if="!Gosterim && KayitSayisi == 0" style="text-align: center;" :style="Text1StyleObject">There is no assignment.</h5>
											<div v-for="(item) in AssignmentsGosterim" :key="item.assignment_id" class="col-xl-3 col-lg-4 col-md-4 col-sm-6 d-flex" 
											v-on:click="AssignmentDetayGit(item.assignment_id)"  style="cursor: pointer;">
												<div class="featured-details-two" :style="CardStyleObject" >
													<div class="product-item-two">
														<div class="product-img-two" >
															<a href="javascript:void(0);" style="font-size: 18px !important;">
																<img class="img-fluid" alt="Img" :src="item.scorm.scorm_image">
															</a>									
														</div>
														<div class="course-details-content">
															<div class="name-text featured-info-two">
																<p class="me-3" >{{ item.assignment_name }}</p>
																<h3 style="margin-top:-10px" ><span>{{ item.scorm.collection_path }}</span></h3>
																<h3 class="title instructor-text" ><a >{{ item.scorm.scorm_name }}</a></h3>
															</div>
															<div class="range" :style="{ '--p': parseInt(item.scorm.scorm_scores.total_score) }">
															<!-- <div class="range__label">Progress</div> -->
															</div>
															<div class="featured-info-time d-flex align-items-center">
																
																<div class="hours-time-two d-flex align-items-center">
																	<span><i class="fa-regular fa-clock me-2"></i></span>
																	<h3><span>Due Date: {{ DateFormat(item.end_date) }}</span></h3>
																	<!-- <p> 12-05-2025</p> -->
																</div>
																<div class="course-view d-inline-flex align-items-center">
																	<!-- <div class="course-price"> -->
																		<!-- <h3><span>12-10-2025</span></h3> -->
																	<!-- </div> -->
																	<div class="price combo" >
																		<h3 v-if="IsExpired(item.end_date)">continues</h3>
																		<h3 v-if="!IsExpired(item.end_date)" style="color: #ff0100 !important;">expired</h3>
																	</div>
																</div>
															</div>
														</div>
													</div>
												</div>
											</div>
										</template>
									</div>
								</div>
								
								<!-- View all  Assignments -->
								<div v-if="Gosterim && KayitSayisi > 0" class="col-lg-12" data-aos="fade-up" style="margin-bottom: -20px;">
									<div class="about-button  more-details text-center">
										<button class="btn btn-primary btn-start THMbutton THMbuttonShadow" @click="AssignmentsGit()"
										:style="Button1StyleObject">View all Assignments <i
										class="fas fa-arrow-right ms-2"></i>
										
										</button>
										
									</div>
								</div>
								<!-- /View all  Assignments -->
							</div>

							<!-- <b-pagination v-if="!Gosterim && AssignmentsGosterim.length > 4" style="justify-content:center"
										v-model="GecerliSayfa"
										:total-rows="ToplamKayitSayisi"
										:per-page="4"
										aria-controls="my-table"
										first-text="First"
										prev-text="Previous"
										next-text="Next"
										last-text="Last"
										limit="10"
										@input ="sayfaDegistir(GecerliSayfa)">
							</b-pagination> -->


						</div>
					</div>
				</div>
			</div>
		</section>

	</div>
</template>

<script>
import {mapGetters} from "vuex";
import LmsService from '@/services/lms.service';
import ContentService from '@/services/content';
import moment from 'moment';

export default {
	// eslint-disable-next-line vue/multi-word-component-names
	name: "AssignmentList",
	props:{
		Gosterim:null
	},
	data() {
		return {
			Aranan: "",
			AramaSonuclari: [],
			Hazir : false,
			Assignments: [],
			AssignmentsGosterim: [],
			SistemTarihi: null,
			AktifTab: 0,
			GecerliSayfa: 1,
			KayitSayisi: 0,
			InnerLoading: true,
		};
	},
	
	async beforeMount()  {
		
		await this.SistemTarihiGetir()
		this.IlkYukleme();
	},


	activated() {			

	},

	computed:{
		...mapGetters(['getKullaniciDetaylari','getOkulAyarlar','getRol','Button1StyleObject','Button2StyleObject','CardStyleObject','Text1StyleObject']),

		ToplamKayitSayisi() {
			return this.KayitSayisi
		},

	},
	methods: {
		DateFormat(value){
        	return moment(value).format("DD.MM.YYYY"); 
    	},

		AssignmentDetayGit(value){
			const Assignment = this.AssignmentsGosterim.find(x => x.assignment_id == value);
        	// this.$router.push({ name: 'assignmentDetails', params: { id: 123 }, state: { extraData:value } });
        	this.$router.push({ name: 'assignmentDetails', params: { Assignment } });
    	},

		IsExpired(value){
        	var a = new Date(this.SistemTarihi); 
        	var b =new Date(value) 
			// a.getTime() - b.getTime()
			return (a.getTime() > b.getTime()) ? false : true
    	},

		AktifTabDegis(value){
			this.AktifTab = value
			this.Aranan =''
			if(this.Assignments.length > 0){
				const Filtre = value == 1 ? this.Assignments.filter((x)=> x.end_date <= this.SistemTarihi) : this.Assignments.filter((x)=> x.end_date >= this.SistemTarihi)
					Filtre.sort((a, b) => a.create_date - b.create_date);
					// this.Assignments = Filtre;
					this.AssignmentsGosterim = Filtre//.slice(0, 4);
				}
				
			this.KayitSayisi = this.AssignmentsGosterim.length
			// if(this.Aranan !='') this.AramaYap()
		},

		AssignmentsGit(){
			this.$router.push( { path : "/assignments"} )
		},

		async SistemTarihiGetir(){
			try {
				const resData = await LmsService.SistemTarihiGetir()
				if(resData){
					this.SistemTarihi = resData
				}

			} catch (error) {
				return
			}
		},

        async IlkYukleme(){

            if (this.getKullaniciDetaylari) {
				await this.AssignmentsGetir()
        	}
		},	

		async AssignmentsGetir(){
			this.KayitSayisi = 0
			try {
				this.$store.commit('setLoading', true)
				const resData = await ContentService.AssignmentsGetir()

				if(resData.success == true){
					this.Assignments = resData.data.data
					if(this.Assignments.length > 0){
						// const Filtre = this.Assignments.filter((x)=> x.start_date <= this.SistemTarihi)
						// Filtre.sort((a, b) => a.create_date - b.create_date);
						// this.Assignments = Filtre;
						// this.AssignmentsGosterim = this.Gosterim ? Filtre.slice(0, 4) : Filtre;

						if(this.Gosterim){
							this.AktifTabDegis(0)
							this.AssignmentsGosterim = this.AssignmentsGosterim.slice(0, 4)
						}else{
							this.AktifTabDegis(0)
						}
					} 
				}
				this.KayitSayisi = this.AssignmentsGosterim.length

                this.$store.commit('setLoading', false)
				this.Hazir = true
				this.InnerLoading = false
			} catch (error) {
				this.InnerLoading = false

                this.$store.commit('setLoading', false)
				this.$toast.open({
					message: "Assignment listesi alınamadı!",
					type: "error",
					duration: 3000,
					dismissible: true,
					position : 'top'
				})
				return
			}
		},

		async sayfaDegistir(value){
			const Filtre = this.Assignments.filter((x)=> x.start_date <= this.SistemTarihi)
			Filtre.sort((a, b) => a.create_date - b.create_date);
			this.AssignmentsGosterim = Filtre.slice((value-1)*4, value*4);
		},

		AramaYap() {
			if(this.Assignments.length > 0){
				const Filtre = this.AktifTab == 1 ? this.Assignments.filter((x)=> x.end_date <= this.SistemTarihi) : this.Assignments.filter((x)=> x.end_date >= this.SistemTarihi)
					Filtre.sort((a, b) => a.create_date - b.create_date);
					// this.Assignments = Filtre;
					const query = this.Aranan.toLowerCase();
					this.AssignmentsGosterim =Filtre.filter((item) =>
						// this.deepSearch(item, query)
						this.searchInSpecificKeys(item, query)						
					);
			}
				
			this.KayitSayisi = this.AssignmentsGosterim.length

		},
		
		deepSearch(obj, query) {
			// Tüm anahtarları ve değerleri kontrol etmek için recursive arama
			for (const key in obj) {
				if (Object.prototype.hasOwnProperty.call(obj, key)) {
					const value = obj[key];

					if (
						key.toLowerCase().includes(query) || // Anahtar sorguyu içeriyor mu?
						(typeof value === "string" &&
						value.toLowerCase().includes(query)) || // Değer string olarak sorguyu içeriyor mu?
						(typeof value === "number" && value.toString().includes(query)) // Değer sayı ise sorguyu içeriyor mu?
					) {
						return true;
					}

					// Alt nesneler veya diziler varsa onları da kontrol et
					if (typeof value === "object" && value !== null) {
					if (this.deepSearch(value, query)) {
						return true;
						}
					}
				}
			}
			return false;
		},

		searchInSpecificKeys(obj, query) {
			// Sadece belirli anahtarlar üzerinde arama yapılacak
			const keysToSearch = ["assignment_name", "assignment_desc", "scorm_name"];
			for (const key of keysToSearch) {
				if (Object.prototype.hasOwnProperty.call(obj, key)) {
				const value = obj[key];
				if (
					typeof value === "string" &&
					value.toLowerCase().includes(query)
				) {
					return true;
				}
				}
			}

			// Alt nesne kontrolü (örneğin scorm)
			if (obj.scorm) {
				for (const key of keysToSearch) {
				if (Object.prototype.hasOwnProperty.call(obj.scorm, key)) {
					const value = obj.scorm[key];
					if (
					typeof value === "string" &&
					value.toLowerCase().includes(query)
					) {
					return true;
					}
				}
				}
			}

			return false;
		},
		

		
	}
}
</script>

<style scoped>



</style>
