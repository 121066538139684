// import uesLms from '../services';
import {store} from "../components/store/store";
import LmsService from '@/services/lms.service';
import uesLmsApi from './ues.lms.service';
import lmsService from '@/services/lms.service';


export default {
    async getPackages() {
        try {
            await LmsService.UesLmsTokenYenile();
            return {
                success: true,
                data: [],
            };
        } catch (error) {
            return {
                success: false,
                data: error.message,
            };
        }
    },


   


    // async getHistoryScorms() {
    //     try {
    //         await uesLmsApi.refreshToken();
    //         const res = await uesLms(true).post('/assignment/assignmentScormList', {
    //             platform_id: 7,
    //         });
    //         return res.data;
    //     } catch (error) {
    //         return {
    //             success: false,
    //             data: error.message,
    //         };
    //     }
    // },

    // async setAssignment(data) {
    //     try {
    //         await uesLmsApi.refreshToken();
    //         await LmsService.UesLmsTokenAl(obj)
    //         const res = await uesLms().post('/assignment/create', data);
    //         if (res.data.success === false) {
    //         return {
    //             success: false,
    //             data: res.data.message,
    //         };
    //         }
    //         return {
    //             success: true,
    //         };
    //     } catch (error) {
    //         return {
    //             success: false,
    //             data: error.message,
    //         };
    //     }
    // },

    // async deleteAssignments(ids) {
    // try {
    //     await uesLmsApi.refreshToken();
    //     /*eslint-disable*/
    //     let removeSuccess = false;
    //     let globalRes = '';
    //     for (const id of ids) {
    //     const res = await uesLms().post(`/assignment/${id}/deleteassignment`);
    //     if(res.data.data.success === true) {
    //         removeSuccess = true;
    //     } else {
    //         removeSuccess = false;
    //         globalRes = res.data.message;
    //         break;
    //     }
    //     }
    //     if (removeSuccess === false) {
    //     return {
    //         success: false,
    //         data: globalRes,
    //     };
    //     }
    //     return {
    //     success: true,
    //     };
    // } catch (error) {
    //     return {
    //     success: false,
    //     data: error.message,
    //     };
    // }
    // },


    async AssignmentsGetir(AssignmenTip) {
        try {
            await lmsService.UesLmsTokenYenile();
            let res = null;
            if (AssignmenTip) {
            // res = await uesLmsApi().post(`/assignments?page=${Sayfa}`, {
            res = await uesLmsApi().post(`/assignments`, {
                platform_id: store.state.OkulAyarlar.PlatformId,
                // class_id: null,
                assignment_ids: null,
                paginate: 999999,
                type: 'individual',
            });
            } else {
            res = await uesLmsApi().post(`/assignments`, {
                platform_id: store.state.OkulAyarlar.PlatformId,
                // class_id: null,
                assignment_ids: null,
                paginate: 999999,
            });
            } 
            return {
                success: true,
                data: res.data,
            };
        } catch (error) {
          return {
            success: false,
            data: error.message,
          };
        }
    },
    
    // async AssignmentSkorGetir(id) {
    // try {
    //     const obj = {
    //         email: store.state.users.KullaniciDetaylari.KullaniciAdi,
    //         password: store.state.users.KullaniciDetaylari.Sifre,
    //         api_key: store.state.OkulAyarlar.ApiKey,
    //     }

    //     await this.UesLmsTokenYenile(obj);
    //     const res = await uesLmsApi().post(`/assignment/${id}`);
    //     return {
    //     success: true,
    //     data: res.data.data,
    //     };
    // } catch (error) {
    //     return {
    //     success: false,
    //     data: error.message,
    //     };
    // }
    // },

    async AssignmentSkorGetir(id) {
        try {   
            await lmsService.UesLmsTokenYenile();
            const res = await uesLmsApi().post(`/assignment/${id}`);
            return {
            success: true,
            data: res.data.data,
            };
        } catch (error) {
            return {
            success: false,
            data: error.message,
            };
        }
    },
    async loadState(data) {
        try {
            const res = await uesLmsApi().post('/state/load', data);
            return res.data;
        } catch (error) {
            return {
                success: false,
                data: error.message,
            };
        }
    },

    async saveStateScore(data) {
        try {
            const res = await uesLmsApi().post('/state/save', data);
            return res.data;
        } catch (error) {
            return {
                success: false,
                data: error.message,
            };
        }
    },

    async getCollections() {
        try {
            await lmsService.UesLmsTokenYenile();
            const res = await uesLmsApi().post('/collections');
            if (res.response && res.response.status !== 200) {
                console.log('Collection Error', res.response.data.error);
                res.data = [];
            } else{
              store.commit('setResources', res.data.data)
            }

            return {
                success: true,
                data: res.data,
            };
        } catch (error) {
            return {
                success: false,
                data: error.message,
            };
        }
    },

    async getScorms(id, paginateOrType) {
    try {
        await lmsService.UesLmsTokenYenile();

        let res;
        if (paginateOrType === 'reader') {
            res = await uesLmsApi(true).post(`/scorms`, {
                type: 'reader',
                paginate: 9999,
            });
        } else if (!paginateOrType) {
            res = await uesLmsApi(true).post(`/scorms`, {
                collection_id: id,
            });
        } else if (paginateOrType == 'IAR'){
            console.log({                
                collection_id: id,
                paginate: 9999,
            })
            res = await uesLmsApi(true).post(`/scorms`, {                
                collection_id: id,
                paginate: 9999,
            });
        }
        return res.data;
    } catch (error) {
        return {
            success: false,
            data: error.message,
        };
    }
    },

    async AssignmentOlustur(data) {
        try {
            await lmsService.UesLmsTokenYenile();

            const res = await uesLmsApi().post('/assignment/create', data);
            if (res.data.success === false) {
            return {
                success: false,
                data: res.data.message,
            };
            }
            return {
                success: true,
            };
        } catch (error) {
            return {
                success: false,
                data: error.message,
            };
        }
    },

    async AssignmentSil(ids) {
        try {
            await lmsService.UesLmsTokenYenile();

            /*eslint-disable*/
            let removeSuccess = false;
            let globalRes = '';
            for (const id of ids) {
                const res = await uesLmsApi().post(`/assignment/${id}/deleteassignment`);
                if(res.data.data.success === true) {
                    removeSuccess = true;
                } else {
                    removeSuccess = false;
                    globalRes = res.data.message;
                    break;
                }
            }
            if (removeSuccess === false) {
                return {
                    success: false,
                    data: globalRes,
                };
            }
            return {
                success: true,
            };
        } catch (error) {
            return {
                success: false,
                data: error.message,
            };
        }
    },

    async AssignmentUpdate(id, data) {
        await lmsService.UesLmsTokenYenile();
        try{
          const res = await uesLmsApi().post(`/assignment/${id}/update`, data);
          return res;
        } catch (error) {
          return{
            success: false,
            data: error.message,
          };
        }
    },

    async OgretmenOgrencileriGetir(organization) {
        const dataArr = [];
        // await lmsService.UesLmsTokenYenile();
        const grades = organization.filter(x => x.type === 'grade');
        for (const gradeItem of grades) {  
            const classObj = {};
            classObj.text = gradeItem.name;
            classObj.value = gradeItem.id;
            classObj.icon = 'fa fa-sitemap';
            classObj.type = 'class';
            classObj.children = [];
            classObj.selected = false;
            classObj.opened = false;
            const students = await uesLmsApi().get(`organization/${gradeItem.id}/students`); //eslint-disable-line
            for (const studentItem of students.data) {  //eslint-disable-line
                const stuObj = {};
                stuObj.text = studentItem.student_name;
                stuObj.icon = 'fa fa-user';
                stuObj.value = studentItem.user_id;
                stuObj.type = 'student';
                stuObj.selected = false;
                stuObj.parentValue = gradeItem.id;
                classObj.children.push(stuObj);
            }
            dataArr.push(classObj);
        }
        store.commit('setOgretmenOgrencileri', dataArr)

        // return dataArr;
    },
//   async getLink() {
//     try {
//       await uesLmsApi.refreshToken();
//       const res = await uesLms().post(`/packages?page=${1}`, {
//         package_type: 'link',
//       });
//       return {
//         success: true,
//         data: res.data,
//       };
//     } catch (error) {
//       return {
//         success: false,
//         data: error.message,
//       };
//     }
//   },

  async editAssignment(id, data) {
    try{
      const res = await uesLmsApi().post(`/assignment/${id}/update`, {
        assignment_name: data.assignment_name,
        assignment_end_date: data.assignment_end_date
      });
      return res;
    } catch (error) {
      return{
        success: false,
        data: error.message,
      };
    }
  },

  async editAssignmentDate(id, data) {
    try{
      const res = await uesLmsApi().post(`/assignment/${id}/update`, {
        assignment_end_date: data.assignment_end_date
      });
      return res;
    } catch (error) {
      return{
        success: false,
        data: error.message,
      };
    }
  },

  async addScorms(id, scorms) {
    try{
      const res = await uesLmsApi().post(`/assignment/${id}/addScorm`, {
        scorms_ids: scorms
      });
      return res;
    } catch (error) {
      return{
        success: false,
        data: error.message,
      };
    }
  }
};
