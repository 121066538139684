<template >
	<div style="margin-top:120px">
		<!-- <section  class="home-two-slide d-flex align-items-center" style="margin-top: 131px; justify-content:center"> -->
			<section v-if=" InnerLoading" class="home-two-slide d-flex align-items-center" style="margin-top: 131px; justify-content:center">
			<div class="row ">
			<span  class="loader"></span>
			</div>
		</section>
		
		<div v-if=" !InnerLoading" class="container">
			<div class="row ">
				<div class="col-12">	

					<div class="settings-menu p-0">
						<div class="profile-heading">
							<!-- <div class="d-flex">
								<h3 style="color: #f66962;">{{Assignment.assignment_name??'-- -- --'}} :</h3>
								<h4>&nbsp;{{ Assignment.scorm.scorm_name??'-- -- --'}}</h4>
							</div>
							<p>{{ Assignment.assignment_desc??'There is no description.'}}</p> -->
							
							<div class="withdrawel-head" >
								<div class="title-withdrawel">
									<span class="file-text-icon" style="margin-right: 0px !important;">
										<i class="bx bxs-wallet"></i>
									</span>
									<div class="info-withdraw">
										<div class="d-flex">
											<h3 style="color: #f66962;">{{Assignment.assignment_name??'-- -- --'}} :</h3>
											<h4>&nbsp;{{ Assignment.scorm.scorm_name??'-- -- --'}}</h4>
										</div>
										<p>{{ Assignment.assignment_desc??'There is no description.'}}</p>
									</div>										
								</div>
								<div v-if="getRol == 'student' && DersAktifMi()" class="add-announcement-btn">
									<a @click="DerseGit()" class="btn btn-primary THMbutton THMbuttonShadow" :style="Button1StyleObject">Go to Lesson</a>
								</div>
							</div>
						</div>
						<div class="checkout-form instructor-earnings-card">
							<!-- Dashboard Grid -->
							<!-- <div class="row justify-content-center"> -->
							<div class="row border-bottom">
								<div class="col-lg-3 col-md-4 col-sm-6 d-flex">
									<div class="card dash-info flex-fill">
										<div class="card-body">
											<h6>Create Date</h6>
											<!-- <h2>$467</h2> -->
											<p>{{DateFormat(Assignment.create_date)}}</p>
										</div>
										
									</div>
								</div>
								<div class="col-lg-3 col-md-4 col-sm-6 d-flex">
									<div class="card dash-info flex-fill">
										<div class="card-body">
											<h6>Start Date</h6>
											<p>{{DateFormat(Assignment.sart_date)}}</p>
										</div>
									</div>
								</div>
								<div class="col-lg-3 col-md-4 col-sm-6 d-flex">
									<div class="card dash-info flex-fill">
										<div class="card-body">
											<h6>Due Date</h6>
											<p>{{DateFormat(Assignment.end_date)}}</p>
										</div>
									</div>
								</div>

								<div class="col-lg-3 col-md-4 col-sm-6 d-flex">
									<div class="card dash-info">
										<h6>Status</h6>
										<span v-if="(new Date(this.Assignment.end_date).getTime() < new Date(this.SistemTarihi).getTime())"
										class="resut-badge badge-light-danger" style="border-radius:10px">Expired</span>
										<span v-else-if="(this.Assignment.scorm.scorm_scores.total_score == 100)"
										class="resut-badge badge-light-success" style="border-radius:10px">Completed</span>
										<span v-else-if="(this.Assignment.scorm.scorm_scores.total_score == 0)"
										class="resut-badge badge-light-dark" style="border-radius:10px">Not Started</span>
										<span v-else
										class="resut-badge badge-light-warning" style="border-radius:10px">Ongoing</span>

									</div>
								</div>

								<div class="col-12">
									<div class="contact-info">
										<div style="display: flex;">
											<h6 style="margin-top: 5px;">Score</h6>
											<h4>&nbsp; {{ Assignment.scorm.scorm_scores.total_score}} %</h4>
										</div>
										<div class="range-md" :style="{ '--p': parseInt(Assignment.scorm.scorm_scores.total_score) }" ></div>
									</div>
								</div>
								
							</div>
							<!-- /Dashboard Grid -->
							<div class="row border-bottom" style="margin-top:20px">
								<div class="col-lg-3 col-md-4 col-sm-6 d-flex">
									<div class="card dash-info flex-fill">
										<div class="card-body">
												<a href="javascript:void(0);" style="font-size: 18px !important;">
													<img style="width: auto; height: 140px;" class="img-fluid" alt="Img" :src="Assignment.scorm.scorm_image">
												</a>									
										</div>
										
									</div>
								</div>

								<div class="col-lg-3 col-md-4 col-sm-6 d-flex">
									<div class="card dash-info flex-fill">
										<div class="card-body">
											<h6>Scorm Name</h6>
											<p>{{Assignment.scorm.scorm_name??'-- -- --'}}</p>
										</div>										
									</div>
								</div>

								<div class="col-lg-3 col-md-4 col-sm-6 d-flex">
									<div class="card dash-info flex-fill">
										<div class="card-body">
											<h6>Collection</h6>
											<p>{{Assignment.scorm.collection_path??'-- -- --'}}</p>
										</div>										
									</div>
								</div>	
								<div class="col-lg-3 col-md-4 col-sm-6 d-flex">
									<div class="card dash-info flex-fill">
										<div class="card-body">
											<h6 style="margin-top: 2px;">Description</h6>
											<p>{{  Assignment.scorm.scorm_desc??'There is no description.'}} </p>
										</div>										
									</div>
								</div>
							</div>

							<div class="row" style="margin-top:20px">
								<div class="col-lg-2 col-md-4 col-sm-6 d-flex">
									<div class="card dash-info flex-fill">
										<div class="card-body">
											<h6>Scorm</h6>
											<h6>Total Scores</h6>
											<!-- <p>{{DateFormat(Assignment.create_date)}}</p> -->
										</div>
										
									</div>
								</div>
								<div class="col-lg-2 col-md-4 col-sm-6 d-flex">
									<div class="card dash-info flex-fill">
										<div class="card-body">
											<h6 class="text-center">Score</h6>
											<p class="text-center">{{ Assignment.scorm.scorm_scores.total_checks_count}}</p>
											<!-- <p>{{DateFormat(Assignment.create_date)}}</p> -->
										</div>
										
									</div>
								</div>
								<div class="col-lg-2 col-md-4 col-sm-6 d-flex">
									<div class="card dash-info flex-fill">
										<div class="card-body">
											<h6 class="text-center">Checks</h6>
											<p class="text-center">{{ Assignment.scorm.scorm_scores.total_checks_count}}</p>
										</div>
									</div>
								</div>
								<div class="col-lg-2 col-md-4 col-sm-6 d-flex">
									<div class="card dash-info flex-fill">
										<div class="card-body">
											<h6 class="text-center">Errors</h6>
											<p class="text-center">{{Assignment.scorm.scorm_scores.total_errors_count}}</p>
										</div>
									</div>
								</div>
								<div class="col-lg-2 col-md-4 col-sm-6 d-flex">
									<div class="card dash-info flex-fill">
										<div class="card-body">
											<h6 class="text-center">Mistakes</h6>
											<p class="text-center">{{Assignment.scorm.scorm_scores.total_mistake_count}}</p>
										</div>
									</div>
								</div>

								<div class="col-lg-2 col-md-4 col-sm-6 d-flex">
									<div class="card dash-info flex-fill">
										<div class="card-body">
											<h6 class="text-center">Time</h6>
											<p class="text-center">{{unixTime_DakikaSaniye(Assignment.scorm.scorm_scores.total_time)}}</p>
										</div>
									</div>
								</div>

							</div>
						</div>
					</div>
				</div>
			</div>
		</div>

	</div>
</template>

<script>
import {mapGetters} from "vuex";
import LmsService from '@/services/lms.service';
import Content from '@/services/content';
import moment from 'moment';
import { millisToMinutesAndSeconds } from "@/global/time";

// import Global from "@/global";


export default {
	// eslint-disable-next-line vue/multi-word-component-names
	name: "AssignmentDetails",
	data() {
		return {
			Assignment: [],
			PastAssignments: [],
			SistemTarihi: null,
			InnerLoading: true
		};
	},

	// async beforeMount() {
	// this.enter = false
	// if (this.$route.query.length == 0 || this.$route.query.FonKod == null ){
	// 	this.$swal.fire({
	// 			title: 'Geçersiz İstek!1',
	// 			icon: 'warning',
	// 			confirmButtonColor: '#3085d6',
	// 			confirmButtonText: 'Kapat'
	// 		})
	// 	this.$router.push( { path : "/" } )
	// 	return
	// } else {
	// 	this.fonKodu = this.$route.query.FonKod
	
	// }
	// },
	
	async beforeMount() {
		if(!this.$route.meta.roles.includes(this.$store.state.KullaniciDetaylari.role_type)){
			this.$router.push( { path : "/home" } )
			return
		}

		await this.SistemTarihiGetir()
		if(this.$route.params.Assignment){
			this.Assignment = this.$route.params.Assignment; 
			this.$store.commit('setAssignmentDetay',this.$route.params.Assignment)
			this.InnerLoading=false
		} else {
			this.Assignment = await this.getAssignmentDetay
			this.InnerLoading=false
		}
		this.AssignmentSkorGetir()
	},
	async mounted() {
    	//  console.log(this.$route.history.state.extraData); // 'Hello'
		
		// if(this.$route.params.Assignment){
		// 	this.Assignment = this.$route.params.Assignment; 
		// 	this.$store.commit('setAssignmentDetay',this.$route.params.Assignment)
		// 	this.InnerLoading=false
		// } else {
		// 	this.Assignment = await this.getAssignmentDetay
		// 	this.InnerLoading=false
		// }

  	},


	computed:{
		...mapGetters(['getRol','getKullaniciDetaylari','getAssignmentDetay','Button1StyleObject']),

		ToplamKayitSayisi() {
			return this.KayitSayisi
		},
		

	},
	methods: {
		DateFormat(value){
        	return moment(value).format("DD.MM.YYYY"); 
    	},
		DateTimeFormat(value){
        	return moment(value).format("DD.MM.YYYY HH:mm:ss"); 
    	},

		IsExpired(value){
        	var a = new Date(this.SistemTarihi); 
        	var b =new Date(value) 
			// a.getTime() - b.getTime()
			return (a.getTime() > b.getTime()) ? false : true
    	},

		unixTime_DakikaSaniye(value){
			return millisToMinutesAndSeconds(value)
		},

		async SistemTarihiGetir(){
			try {
				const resData = await LmsService.SistemTarihiGetir()
				if(resData){
					this.SistemTarihi = resData
				}

			} catch (error) {
				return
			}
		},

		DersAktifMi() {
			return (moment(this.SistemTarihi).valueOf() < moment(this.Assignment.end_date).valueOf()) && (moment(this.SistemTarihi).valueOf() > moment(this.Assignment.start_date).valueOf());
		},

		DerseGit(){
			const ScormView = {
				scorm_id: this.Assignment.scorm.scorm_id,
				assignment_id: this.Assignment.assignment_id,
				scormTitle: this.Assignment.scorm.scorm_name,
				scormDesc: this.Assignment.scorm.scorm_desc,
				scormCode: this.Assignment.scorm.scorm_code,
				packageType: 'assignment',
				timestamp: null,
			};

        	this.$router.push({ name: 'scormView', params: { ScormView } });
		},

		async AssignmentSkorGetir(){
			this.KayitSayisi = 0
			try {
				this.$store.commit('setLoading', true)
				const resData = await Content.AssignmentSkorGetir(this.Assignment.assignment_id)

				if(resData.success == true){
					let Detay
					Detay = resData.data.assignment_type =='group' ? resData.data.users.find(x => x.user_id == this.getKullaniciDetaylari.user_id)?.scorm_scores
						    : resData.data.users.scorm_scores

					this.Assignment.scorm.scorm_scores.total_checks_count = Detay.total_checks_count
					this.Assignment.scorm.scorm_scores.total_errors_count = Detay.total_errors_count
					this.Assignment.scorm.scorm_scores.total_mistake_count = Detay.total_mistake_count
					this.Assignment.scorm.scorm_scores.total_score = parseInt(Detay.total_score)
					this.Assignment.scorm.scorm_scores.total_time = Detay.total_time
				}

                this.$store.commit('setLoading', false)
			} catch (error) {

                this.$store.commit('setLoading', false)
				this.$toast.open({
					message: "Assignment detayları alınamadı!",
					type: "error",
					duration: 3000,
					dismissible: true,
					position : 'top'
				})
				return
			}
		},

		activated() {
			

		},
		created () {
		}
	}
}
</script>

<style scoped>
.card {
	border: none;
}

.card-body {
    padding: 0 !important;
}
.card-body p{
	font-size: 16px;
}

.dash-info h2 {
    font-weight: 700;
    font-size: 20px;
    color: #002058;
    margin-bottom: 0;
}

.checkout-form {
    padding: 24px;
}


</style>
