<template >
	<div>
		<!-- <section  class="home-two-slide d-flex align-items-center" style="margin-top: 131px; justify-content:center"> -->
		<!-- <section v-if="InnerLoading" class="home-two-slide d-flex align-items-center" style="margin-top: 131px; justify-content:center">
			<div class="row ">
			<span  class="loader"></span>
			</div>
		</section> -->

		<section class="home-two-slide d-flex align-items-center" >

			<div class="container">
				<div class="row " >					
					<div class="col-lg-12 col-12" data-aos="fade-up">
						<div class="home-slide-face">						
							<div class="container">
								<div class="header-two-title text-center" data-aos="fade-up">									
									<p class="tagline" style="margin-bottom: -1rem;" :style="Text1StyleObject" >
										Assignments 
									</p>
								</div>

								<div class="featured-courses-two" style="margin-bottom: 25px!important;">
									<div class="row" style="justify-content: center;">

										<!-- <template v-if="Gosterim"> -->
										<template >
											<!-- <span  v-if="Gosterim && InnerLoading" class="loader"></span> -->
											<div  class="wishlist-tab" >
												<ul class="nav" role="tablist" style="justify-content: center">
													<li class="nav-item">
														<a href="javascript:void(0);" 
														@click="Create()" class="THMbutton THMbuttonShadow" :style="Button1StyleObject">
															<i class="feather-plus me-2"></i> Create
														</a>
													</li>

													<li class="nav-item">
														<div class="form" style="display:flex">
															<label  class="form-label" :style="Text1StyleObject" style="margin-top: 13px;">Multi Tasking &nbsp;</label>
															<select class="form-select select" v-model="IslemAdi" @change="IslemReset()"
															:style="InputStyleObject" style="width:80px; padding:4px">
																<option value="Edit">Edit</option>
																<option value="Delete">Delete</option>
															</select>
														</div>
													</li>
													<li v-if="IslemAdi =='Edit'" class="nav-item">
														<a href="javascript:void(0);" 
														@click="EditModalGosterKapat()"
														:class="(this.Secilenler.length > 0 || this.HepsiSecilimi == true) ? '':'li-disabled'" 
														class="THMbutton THMbuttonShadow" :style="Button1StyleObject" style="width:110px">
															<i class="feather-edit-2 me-2"></i> Edit
														</a>
													</li>
													<li v-else class="nav-item">
														<a href="javascript:void(0);" :class="(this.Secilenler.length > 0 || this.HepsiSecilimi == true) ? '':'li-disabled'" 
															class="THMbutton THMbuttonShadow" :style="Button1StyleObject" style="width:110px"
															@click="SilSor()">
															<i class="feather-trash me-2"></i> Delete
														</a>
													</li>
													

													<ul class="nav" role="tablist" style="justify-content: center">

														<li class="nav-item">
															<div class="form" style="display:flex">
																<label  class="form-label" :style="Text1StyleObject" style="margin-top: 13px;">Start Date &nbsp;</label>
																<select class="form-select select nodownimg" v-model="Op1" @change="Filtre()" 
																:style="InputStyleObject" style="width:35px; padding:4px">
																	<option v-for="item in Operator" :value="item" :key="item">{{item}}</option>
																</select>
																<date-picker  v-model="StartDate" @change="Filtre()" valueType="YYYY-MM-DD" :format="format" placeholder="Start Date" style="padding-top: 4px;"	
																:style="InputStyleObject">
																	<template v-slot:header="{ emit }">
																	<button class="mx-btn mx-btn-text" @click="emit(new Date())">Bugün</button>
																	</template>
																</date-picker>
															</div>
														</li>

														<li class="nav-item">
															<div class="form" style="display:flex">
																<label  class="form-label" :style="Text1StyleObject" style="margin-top: 13px;">Due Date &nbsp;</label>
																<select class="form-select select nodownimg" v-model="Op2" @change="Filtre()" 
																:style="InputStyleObject" style="width:35px;padding:4px">
																	<option v-for="item in Operator" :value="item" :key="item">{{item}}</option>
																</select>
																<date-picker  v-model="DueDate" @change="Filtre()" valueType="YYYY-MM-DD" :format="format" placeholder="Due Date" style="padding-top: 4px;"	
																	:style="InputStyleObject">
																	<template v-slot:header="{ emit }">
																	<button class="mx-btn mx-btn-text" @click="emit(new Date())">Bugün</button>
																	</template>
																</date-picker>
															</div>
														</li>

														<li class="nav-item">
															<div class="form" style="display:flex">
																<label  class="form-label" :style="Text1StyleObject" style="margin-top: 13px;">Assignee&nbsp;&nbsp;</label>
																<select class="form-select select " v-model="Assignee" :style="InputStyleObject" @change="Filtre()">
																	<option value='All'>All</option>
																	<option v-for="item in AssigneeListe" :value="item.name" :key="item.name">{{item.name}}</option>
																</select>
															</div>
														</li>
													</ul>
												</ul>
											</div>	


											<div class="settings-menu p-0">												
												<div class="checkout-form">
													<!-- Tab -->													
													<div  class="wishlist-tab" style="text-align: center;">
														<ul class="nav" role="tablist" style="justify-content: center">
															<li class="nav-item">
																<a href="javascript:void(0);" style="width:100%; min-width:210px" class="THMbutton THMbuttonShadow" 
																:style="AssignmentTip =='group' ? Button1StyleObject : Button2StyleObject" @click="AssignmentsGetir('group')"
																	role="tab">Group Assignments</a>
															</li>

															<li class="nav-item">
																<a href="javascript:void(0);" style="width:100%; min-width:210px" class="active THMbutton THMbuttonShadow" 
																:style="AssignmentTip =='individual' ? Button1StyleObject : Button2StyleObject" @click="AssignmentsGetir('individual')"
																role="tab">Individual Assignments</a>
															</li>
														</ul>	
													</div>
													<!--/Tab -->																	
													<div class="tab-pane active show">
														<section v-if="InnerLoading" class="home-two-slide d-flex align-items-center" style="margin-top: 131px; justify-content:center">
															<div class="row ">
															<span  class="loader"></span>
															</div>
														</section>
														<div v-else class="table-responsive custom-table">
															<h5 v-if="AssignmentsGosterim.length == 0 && Hazir==true" style="text-align: center;" :style="Text1StyleObject">There is no assignment.</h5>

															<table v-else class="table table-hover table-nowrap mb-0" :style="TableStyleObject">
																<thead>
																<tr>
																	<th class="text-center" style="width:40px">#</th>
																	<th class="text-center" style="width:50px">
																		<input class="form-check-input" type="checkbox"
																		v-model="HepsiSecilimi" @change="HepsiniSec()" :style="Button1StyleObject">
																	</th>
																	
																	<th>Assignment Name</th>
																	<th class="text-center">Assignee</th>
																	<th class="text-center">Start Date</th>
																	<th class="text-center">Due Date</th>
																	<th class="text-center">Status</th>
																	<th class="text-center">Details</th>
																	<th class="text-center" style>Edit</th>
																</tr>
																</thead>
																<tbody v-for="(item,i) in AssignmentsGosterim" :key="i">
																	<tr style="height:20px !important" >
																		<td class="text-center">{{i+1}}</td>
																		<td v-if="IslemAdi =='Edit'" class="text-center">
																			<input v-model="item.selected" class="form-check-input" type="checkbox"
																			:class="{'li-disabled':(Durum(item.start_date,item.end_date) =='Expired')}"
																			@change="Sec(i)" :style="Button1StyleObject">
																		</td>	
																		<td v-else class="text-center">
																			<input v-model="item.selected" class="form-check-input" type="checkbox"																			
																			@change="Sec(i)" :style="Button1StyleObject">
																		</td>
																
																		<td><span class="title-course">{{item.assignment_name}}</span></td>
																		<td class="text-center">{{item.name}}</td>
																		<td class="text-center">{{DateFormat(item.start_date)}}</td>
																		<td class="text-center">{{DateFormat(item.end_date)}}</td>
																		<td class="text-center">
																			<span :class="{'status-badge badge-light-warning':Durum(item.start_date,item.end_date) =='Not Started',
																			'status-badge badge-light-danger':Durum(item.start_date,item.end_date) =='Expired',
																			'status-badge badge-light-success':Durum(item.start_date,item.end_date) =='Continues'}">																			
																			{{Durum(item.start_date,item.end_date)}}
																			</span>																			
																		</td>																		
																		<td class="text-center">
																			<!-- https://feathericons.dev/?search=chevrons-down&iconset=feather -->
																		<svg v-if="DetayGoster.includes(i)" @click="DetayGosterGizle(i)" title="Hide Details" v-tippy style="cursor: pointer;"
																		viewBox="0 0 24 24" width="24" height="24" class="main-grid-item-icon" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2">
																			<polyline points="17 11 12 6 7 11" />
																			<polyline points="17 18 12 13 7 18" />
																		</svg>
																		<svg v-else @click="DetayGosterGizle(i)" title="Show Details" v-tippy style="cursor: pointer;"
																		viewBox="0 0 24 24" width="24" height="24" class="main-grid-item-icon" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2">
																			<polyline points="7 13 12 18 17 13" />
																			<polyline points="7 6 12 11 17 6" />
																		</svg>

																		</td>
																		<td class="text-center"><i  class="feather-edit-2 me-2 " 
																		:class="{'li-disabled':(Durum(item.start_date,item.end_date) =='Expired' || Secilenler.length > 1 || HepsiSecilimi==true)}"
																		@click="EditModalGosterKapat(i)"
																		style="cursor: pointer;" title="Edit Assignment" v-tippy></i></td>														

																	</tr>
																	<tr>
																		<td colspan="9" :id="i" v-if="DetayGoster.includes(i)">
																			<div :id="i" v-if="DetayGoster.includes(i)"> 
																			<template>
																			<AssignmentCardForList 
																				:Assignments="Assignments" 
																				:assignment_name="item.assignment_name"
																				:name="item.name"
																				:start_date="item.start_date"
																				:end_date="item.end_date"
																			>	
																			</AssignmentCardForList>
																					
																			</template>
																			</div>
																		</td> 
																	</tr>																	
																</tbody>
															</table>
														</div>
													</div>
												</div>
											</div>
										</template>
									</div>
								</div>
								
							</div>

							<!-- <b-pagination v-if="AssignmentsGosterim.length > 2" style="justify-content:center"
										v-model="GecerliSayfa"
										:total-rows="ToplamKayitSayisi"
										:per-page="4"
										aria-controls="my-table"
										first-text="First"
										prev-text="Previous"
										next-text="Next"
										last-text="Last"
										limit="10"
										@input ="sayfaDegistir(GecerliSayfa)">
							</b-pagination> -->


						</div>
					</div>
				</div>
			</div>
		</section>
		
		<EditAssignment v-if="Modal" :Mod="Secilenler.length ==0 || Secilenler.length ==1 ? 'single':'multi'" :SingleEditIndex="SingleEditIndex">	
		</EditAssignment>	

	</div>
</template>

<script>
import {mapGetters} from "vuex";
import LmsService from '@/services/lms.service';
import ContentService from '@/services/content';
import moment from 'moment';
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
import AssignmentCardForList from "./Component.CardForList.vue";
import EditAssignment from "./Component.EditAssignment.vue";


// import DatePicker from 'vuejs-datepicker';


export default {
	// eslint-disable-next-line vue/multi-word-component-names
	name: "AssignmentsTeacher",
	// props:{
	// 	Gosterim:null
	// },
	components: { DatePicker, AssignmentCardForList, EditAssignment},

	data() {
		return {
			AssignmentTip: '',
			Hazir : false,
			Assignments: [],
			AssignmentsGosterim: [],
			AssigneeListe: [],
			Assignee: 'All',
			SistemTarihi: null,
			AktifTab: 0,
			GecerliSayfa: 1,
			KayitSayisi: 0,
			InnerLoading: true,
			StartDate : null,
			DueDate: null,
			format: 'DD.MM.YYYY',
			Operator:['=','>','<','>=','<='],
			Op1:'>=',
			Op2:'<=',
			DetayGoster:[],
			HepsiSecilimi: false,
			Secilenler: [],
			Modal: false,
			SingleEditIndex:null,
			IslemAdi:'Edit'
		};
	},
	
	async beforeMount() {
		await this.SistemTarihiGetir()
		// ContentService.OgretmenOgrencileriGetir(this.$store.state.KullaniciDetaylari.organization)
		this.IlkYukleme();
	},

	computed:{
		...mapGetters(['getKullaniciDetaylari','Button1StyleObject','Button2StyleObject','Text1StyleObject','InputStyleObject','TableStyleObject',
		'InputStyleObject','SaveButtonStyleObject']),
		ToplamKayitSayisi() {
			return this.KayitSayisi
		},

	},
	methods: {
		DateFormat(value){
        	return moment(value).format("DD.MM.YYYY"); 
    	},
	
		IsExpired(value){
        	var a = new Date(this.SistemTarihi); 
        	var b =new Date(value) 
			// a.getTime() - b.getTime()
			return (a.getTime() > b.getTime()) ? false : true
    	},

		

		IslemReset() {
			this.Secilenler = []
			this.HepsiSecilimi = false
			this.AssignmentsGosterim.forEach((item) => {
				item.selected = false;
			});
		},

		HepsiniSec() {
			this.Secilenler = []
			this.AssignmentsGosterim.forEach((item,i) => {
				if (this.IslemAdi =='Edit' && this.Durum(item.start_date,item.end_date) !='Expired' ){
					item.selected = this.HepsiSecilimi;
					if (this.HepsiSecilimi == true) this.Sec(i)
				} else if(this.IslemAdi == 'Delete'){
					item.selected = this.HepsiSecilimi;
					if (this.HepsiSecilimi == true) this.Sec(i)
				}
			});
		},

		async Sec(i) {
			
			const index = this.Secilenler.indexOf(i);			
			if (index > -1) {
				this.Secilenler.splice(index, 1); // Diziden id'yi çıkar
			} else {
				this.Secilenler.push(i); // Diziye id'yi ekle
			}

		},

		DetayGosterGizle(id) {
			const index = this.DetayGoster.indexOf(id);
			if (index > -1) {
				this.DetayGoster.splice(index, 1); // Diziden id'yi çıkar
			} else {
				this.DetayGoster.push(id); // Diziye id'yi ekle
			}
		},

		AssignmentDetayGit(value){
			const Assignment = this.AssignmentsGosterim.find(x => x.assignment_id == value);
        	// this.$router.push({ name: 'assignmentDetails', params: { id: 123 }, state: { extraData:value } });
        	this.$router.push({ name: 'assignmentDetails', params: { Assignment } });
    	},

		Durum(start_date, end_date){
			// console.log(start_date, end_date, this.SistemTarihi)
        	var st = new Date(this.SistemTarihi); 
        	var sd =new Date(start_date) 
        	var ed =new Date(end_date) 
			// console.log(sd.getTime(), ed.getTime(), st.getTime())

			if (sd.getTime() > st.getTime()){
				return 'Not Started'
			} else if (ed.getTime() < st.getTime()){
				return 'Expired'
			} else { return 'Continues'}
    	},


		Create(){
			this.$router.push( { path : "/assignment/create"} )
		},

		async SistemTarihiGetir(){
			try {
				const resData = await LmsService.SistemTarihiGetir()
				if(resData){
					this.SistemTarihi = resData
				}

			} catch (error) {
				return
			}
		},

        async IlkYukleme(){

            if (this.getKullaniciDetaylari) {
				await this.AssignmentsGetir('group')
        	}
		},	

		async AssignmentsGetir(AssignmentTip){
			if (this.AssignmentTip != AssignmentTip){
				this.Hazir = false

				this.AssignmentTip = AssignmentTip
				this.KayitSayisi = 0
				this.AssigneeListe = []
				this.DetayGoster = []
				this.AssignmentsGosterim = []
				try {
					this.$store.commit('setLoading', true)
					const resData = this.AssignmentTip =='individual' ? await ContentService.AssignmentsGetir('individual') : await ContentService.AssignmentsGetir()
					this.$store.commit('setLoading', false)
					if(resData.success == true){
						this.Assignments = resData.data.data
						this.AssignmentsListele()
					}
					this.Hazir = true
					this.InnerLoading = false
				} catch (error) {
					this.InnerLoading = false
	
					this.$store.commit('setLoading', false)
					this.$toast.open({
						message: "Assignment listesi alınamadı!",
						type: "error",
						duration: 3000,
						dismissible: true,
						position : 'top'
					})
					return
				}
			}
		},

		async AssignmentsListele(){
			this.KayitSayisi = 0
			try {

				if(this.Assignments.length > 0){
					this.AssignmentsGosterim = this.Assignments

					const Assignment = this.Assignments.map(({ assignment_name, name, start_date, end_date }) => ({ assignment_name, name, start_date, end_date  })); 
			
					// Şimdi tekil hale getiriyoruz
					const UniqAssignment = [
					...new Map(Assignment.map(item => [JSON.stringify(item), item])).values()
					];

					this.AssignmentsGosterim = UniqAssignment.sort((a, b) => b.start_date - a.start_date);
					this.AssignmentsGosterim.sort((a, b) => b.start_date - a.start_date);

					this.AssignmentsGosterim = this.AssignmentsGosterim.map(item => ({ ...item, selected: false }));
					this.HepsiSecilimi = false

					const AssigneeListe = this.AssignmentsGosterim.map(({ name }) => ({ name })); 
					const UniqAssigneeListe = [
						...new Map(AssigneeListe.map(item => [JSON.stringify(item), item])).values()
					];
					this.AssigneeListe = UniqAssigneeListe
					// this.AssigneeListe.unshift('All')
				}
				this.KayitSayisi = this.AssignmentsGosterim.length

			} catch (error) {
				console.log('Assignment Göster',error)
			}
		},

		Filtre(){
			// if(this.Assignments.length > 0){

			// 	const Assignment = this.Assignments.map(({ assignment_name, name, create_date, start_date, end_date }) => ({ assignment_name, name, create_date, start_date, end_date  })); 
			// 	// tekil hale getiriyoruz
			// 	const UniqAssignment = [
			// 	...new Map(Assignment.map(item => [JSON.stringify(item), item])).values()
			// 	];

			// 	const Filtre = UniqAssignment.filter(x=> x.name== this.Assignee)
			// 	this.AssignmentsGosterim = Filtre.sort((a, b) => b.start_date - a.start_date);

			// } 

			const Assignment = this.Assignments.map(({ assignment_name, name, create_date, start_date, end_date }) => ({ assignment_name, name, create_date, start_date, end_date  })); 
				// tekil hale getiriyoruz
				const UniqAssignment = [
				...new Map(Assignment.map(item => [JSON.stringify(item), item])).values()
				];

			let KosulDizi = []
			let KosulItem = {}
			
			if(this.StartDate){
				KosulItem.key = 'start_date'
				KosulItem.operator = this.Op1 == '='?'==':this.Op1
				KosulItem.value = this.StartDate.split(' ')[0]
				KosulDizi.push(KosulItem)
			}
			KosulItem = {}
			if(this.DueDate){
				KosulItem.key = 'end_date'
				KosulItem.operator = this.Op2 == '='?'==':this.Op2
				KosulItem.value = this.DueDate.split(' ')[0]
				KosulDizi.push(KosulItem)

			}
			KosulItem = {}
			if(this.Assignee !='All'){
				KosulItem.key = 'name'
				KosulItem.operator = '=='
				KosulItem.value = this.Assignee
				KosulDizi.push(KosulItem)

			}
			const Filtre = this.Filtre_DinamikParametre(UniqAssignment, KosulDizi);
			this.AssignmentsGosterim =Filtre

		},

		Filtre_DinamikParametre(array, conditions) {
			return array.filter(item => {
				return conditions.every(condition => {
					const { key, operator, value } = condition;
					const itemValue = key == 'date' ? item[key]?.split(' ')[0] : item[key];
					switch (operator) {
					case '==': return itemValue == value;
					case '>=': return itemValue >= value;
					case '<=': return itemValue <= value;
					case '>': return itemValue > value;
					case '<': return itemValue < value;
					case '!=': return itemValue != value;
					default:
						this.$toast.open({
							message: "Unsupported operator!",
							type: "error",
							duration: 3000,
							dismissible: true,
							position : 'top'
						})
						return false;
					}
				});
			});
		},

		// async sayfaDegistir(value){
		// 	const Filtre = this.Assignments.filter((x)=> x.start_date <= this.SistemTarihi)
		// 	Filtre.sort((a, b) => a.create_date - b.create_date);
		// 	this.AssignmentsGosterim = Filtre.slice((value-1)*4, value*4);
		// },




		SilSor(){
			this.$swal.fire({
				title: 'Are you sure?',
				text: 'Once deleted, you will not be able to recover this assignments!',
				icon: 'question',
				showCancelButton: true,
				confirmButtonColor: '#d33',
				cancelButtonColor: '#3085d6',
				confirmButtonText: 'Delete',
				cancelButtonText : 'Cancel'
			}).then(async (result) => {
				if (result.isConfirmed) {	
					this.AssignmentSil()					
				}
			})
		},

		AssignmentSil() {
			let SilinecekScorm =[]
			this.AssignmentsGosterim.forEach(item => {
				if (item.selected ==true){
					const Assignment = this.Assignments.filter(x=> x.assignment_name == item.assignment_name && x.name == item.name && 
					x.start_date == item.start_date && x.end_date == item.end_date ); 
					// console.log(Assignment)
					const YeniDizi =Assignment.map(item => item.assignment_id);
					SilinecekScorm = SilinecekScorm.concat(YeniDizi)
				}
			});

			this.$store.commit('setLoading', true)				
			ContentService.AssignmentSil(SilinecekScorm).then((res) => {
              if(!res.success) {
				this.$store.commit('setLoading', false)			

                this.ready = true;
                console.log('Assignment Delete Error', res.data);
				this.$toast.open({
					message: "An error occured while you removing assignments",
					type: "error",
					duration: 3000,
					dismissible: true,
					position : 'top'
				})
              } else {
				this.$store.commit('setLoading', false)				
				this.$toast.open({
					message: "Delete successful",
					type: "success",
					duration: 3000,
					dismissible: true,
					position : 'top'
				})
				this.DeleteHepsiSecilimi= false
				this.DeleteSecilenler = []
				location.reload();
              }
            }); 			
		},

		EditModalGosterKapat(index){
			this.SingleEditIndex = index
				
			if(this.Modal == true){
				this.SingleEditIndex = null
			}

			this.Modal = !this.Modal
		},

		async AssignmentUpdate(Index, Name, Duedate){      
			let data
			let UpdateAssignment =[]
			
			if(typeof Index !='undefined'){
				const SingleAssignment = this.AssignmentsGosterim[Index];
				const Assignment = this.Assignments.filter(x=> x.assignment_name == SingleAssignment.assignment_name && x.name == SingleAssignment.name && 
				x.start_date == SingleAssignment.start_date && x.end_date == SingleAssignment.end_date );

				UpdateAssignment =Assignment.map(item => item.assignment_id);
				data = (Name == null || (Name.trim()=='')) ? { 'assignment_end_date': Duedate}:
				{'assignment_name': Name,'assignment_end_date': Duedate}

			} else{
				data = {
					'assignment_end_date': Duedate,
				}
				this.AssignmentsGosterim.forEach(item => {
					if (item.selected ==true){
						const Assignment = this.Assignments.filter(x=> x.assignment_name == item.assignment_name && x.name == item.name && 
						x.start_date == item.start_date && x.end_date == item.end_date ); 
						// console.log(Assignment)
						const YeniDizi =Assignment.map(item => item.assignment_id);
						UpdateAssignment = UpdateAssignment.concat(YeniDizi)
					}
				});
			}		
				

			try{
				this.$store.commit('setLoading', true)	
				this.EditModalGosterKapat()

				// for (const item of UpdateAssignment) {
				// 	await ContentService.AssignmentUpdate(item, data);
				// }

				await Promise.all(
					UpdateAssignment.map(item => ContentService.AssignmentUpdate(item, data))
				);
				this.$store.commit('setLoading', false)	
				this.$toast.open({
					message: "Update successful",
					type: "success",
					duration: 3000,
					dismissible: true,
					position : 'top'
				})
				location.reload();
			} catch (error) {
				this.$store.commit('setLoading', false)	
				this.$toast.open({
					message: "An error occured while you update assignments",
					type: "error",
					duration: 3000,
					dismissible: true,
					position : 'top'
				})
       		}
			

		},
		

		activated() {
			

		},
		created () {
		}
	}
}
</script>

<style scoped>

.text-center{
	text-align:center
}

.custom-table table th {
  background: var(--color) !important;
  font-weight: 500;
  color: #1b1b1b;
  padding: 15px 10px;
  /* border-color: #f4f6f9; */
}
/* .custom-table table td:hover {
  background-color: var(--color) !important;
} */
.table-hover>tbody>tr:hover>* {
    --bs-table-color-state: var(--color) !important;
    --bs-table-bg-state: var(--color-hover) !important;
}
label.form-label {
    font-size: 15px;
}





</style>
