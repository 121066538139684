<template>
    <!-- Main Wrapper -->
    <div class="main-wrapper log-wrap" >

    <div class="row">

        <!-- Login Banner -->
        <!-- <div class="col-md-6 login-bg" :style="[THMBackgroundColor != null ? {'background-color':THMBackgroundColor} : '']"> -->
        <div class="col-md-7 login-bg" :style="{'background-color':this.$store.state.RenkTonlari[0]?.color??'#fff'}">
            <!-- <div class="owl-carousel login-slide owl-theme"> -->
                <div class="welcome-login" style="margin-left: auto; margin-right: auto;">
                    <div class="login-banner">
                        <img src="/assets/img/login-img.png" class="img-fluid" alt="Logo">
                    </div>
                    <div class="mentor-course text-center" >
                        <h2 :style="{'color':ZeminRengiIcinYazi(this.$store.state.RenkTonlari[4]?.color??'#fff')}">Welcome to <br>UESLMS</h2>
                        <p :style="{'color':ZeminRengiIcinYazi(this.$store.state.RenkTonlari[4]?.color??'#fff')}">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt
                            ut labore et dolore magna aliqua. Ut enim ad minim veniam.</p>
                    </div>
                </div>
                <!-- <div class="welcome-login">
                    <div class="login-banner">
                        <img src="/assets/img/login-img.png" class="img-fluid" alt="Logo">
                    </div>
                    <div class="mentor-course text-center">
                        <h2>Welcome to <br>DreamsLMS Courses.</h2>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt
                            ut labore et dolore magna aliqua. Ut enim ad minim veniam.</p>
                    </div>
                </div>
                <div class="welcome-login">
                    <div class="login-banner">
                        <img src="/assets/img/login-img.png" class="img-fluid" alt="Logo">
                    </div>
                    <div class="mentor-course text-center">
                        <h2>Welcome to <br>DreamsLMS Courses.</h2>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt
                            ut labore et dolore magna aliqua. Ut enim ad minim veniam.</p>
                    </div>
                </div> -->
            <!-- </div> -->
        </div>
        <!-- /Login Banner -->

        <div class="col-md-5 login-wrap-bg">

            <!-- Login -->
            <div class="login-wrapper"  style="background-color: #fff;" :style="{'justify-content': getOkulAyarlar.GoogleLogIn == 1 ? 'space-around':'' }">
                <div class="loginbox">
                    <div class="w-100" >
                        <div class="img-logo" v-if="getOkulAyarlar.GoogleLogIn == 0">
                        <!-- <div class="login-banner"> -->
                            <img :src="`/assets/img/schoollogo/${this.$store.state.DomainAdi}.png`" class="img-fluid" alt="Logo"  >
                            <h1 style="margin-left: 15px; padding-top: 24px">Log into Your Account</h1>                            
                        </div>
                        <!-- <h1>Log into Your Account</h1> -->
                        <div v-if="getOkulAyarlar.GoogleLogIn == 1">
                            <div class="img-logo" style="justify-content: center;">
                            <!-- <div class="login-banner"> -->
                                <img :src="`/assets/img/schoollogo/${this.$store.state.DomainAdi}.png`" class="img-fluid" alt="Logo"  >
                                <h1 style="margin-left: 15px; padding-top: 24px; text-align: center;">Log into Your Account</h1>
                                <img src="assets/img/google.png" class="img-fluid" alt="Logo">                               
                            </div>
 
                            <div class="d-grid">
                                <button class="btn btn-primary btn-start THMbutton" 
                                :style="Button1StyleObject" type="submit">Sign In with Google</button>
                            </div>
                        </div>
                        <div v-else >
                            <div class="input-block">
                                <label class="form-control-label">User Name</label>
                                <input v-model="KullaniciAdi" type="text" class="form-control THMinput" placeholder="Enter your user name" :style="InputStyleObject">
                            </div>
                            <div class="input-block">
                                <label class="form-control-label">Password</label>
                                <div class="pass-group">
                                    <input v-model="Sifre" :type="SifreTipi" class="form-control pass-input THMinput" :style="InputStyleObject"
                                        placeholder="Enter your password">
                                    <span v-on:click="SifreGoster()" class="toggle-password " :class="SifreIcon"></span>
                                </div>
                            </div>
 
                            <div class="d-grid">
                                <button class="btn btn-primary btn-start THMbutton" 
                                v-on:click="Login()" :style="Button1StyleObject" type="submit">Log In</button>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- <div v-if="this.$store.state.OkulAyarlar?.GoogleLogIn??0 != 1" class="google-bg text-center" :style="{'background-color':this.$store.state.RenkTonlari[2].color}">
                    <span><a href="#" :style="{'color':ZeminRengiIcinYazi(this.$store.state.RenkTonlari[1].color)}">Or log in with</a></span>
                    <div class="sign-google">
                        <a href="#" :style="{'color':ZeminRengiIcinYazi(this.$store.state.RenkTonlari[1].color)}">
                            <img src="assets/img/net-icon-01.png" class="img-fluid" alt="Logo"> 
                            Log In using Google
                        </a>
                        
                    </div>
                    
                </div> -->
            </div>
            <!-- /Login -->

            <!-- <template>
                <div>
                    <input type="color" v-model="color" />
                    <input type="number" v-model.number="Tonlama" min="1" />
                    <button @click="RenkTonlariOlustur">Tonları Oluştur</button>
                    <div v-if="RenkTonlari.length">
                    <div
                        v-for="(RenkTonlama, index) in RenkTonlari"
                        :key="index"
                        :style="{ backgroundColor: RenkTonlama, color: '#fff', padding: '10px', margin: '5px' }"
                    >
                        {{ RenkTonlama }}
                    </div>
                    </div>
                </div>
            </template> -->

        </div>

    </div>

    </div>
    <!-- /Main Wrapper -->
</template>

<script>
import {mapGetters} from "vuex";
// import {mapState} from "vuex";
import LmsService from '@/services/lms.service';
import ContentService from '@/services/content';
import { colorShades, getTextColorBasedOnBackground } from "@/global/color";
export default {
    // eslint-disable-next-line vue/multi-word-component-names
    name:"login",
    data() {
        return {   
            color: "#012866",
    //   RenkTonlama: 5,
    //   RenkTonlari: [],
            THMBackgroundColor: null,
            KullaniciAdi: '',
            Sifre: '',
            Giris : false, 
            SifreTipi: 'password',
            SifreIcon: 'feather-eye',
            // eslint-disable-next-line no-useless-escape
            reg: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/
        
        }
    },

    async beforeMount() {
		 await this.OkulAyarlar();
	},

    // async mounted() {
	// 	 await this.OkulAyarlar();
	// },

    computed:{
        ...mapGetters(['getOkulAyarlar','Button1StyleObject','InputStyleObject']),
        // ...mapState(['OkulAyarlar']),
    },
    methods: {
        RenkTonlariOlustur(Renk) {
            // this.RenkTonlari = colorShades(Renk, this.RenkTonlama);
            this.$store.state.RenkTonlari = colorShades(Renk, this.$store.state.RenkTonlama).map((shadeHex) => {
            const textColor = getTextColorBasedOnBackground(shadeHex); // Metin rengi belirleme
            return { color: shadeHex, textColor };
            });
        },
        ZeminRengiIcinYazi(Renk) {
            return getTextColorBasedOnBackground(Renk); // Metin rengi belirleme        
        },

        async OkulAyarlar() {
        //    console.log(window.location.hostname, this.$store.state.DomainAdi);
            this.$store.state.DomainAdi = window.location.hostname//'fnlenglish' gundogdulearningcentre
            const obj = {
                DomainAdi : window.location.hostname,//'gundogdulearningcentre',
            }
            // this.$store.state.DomainAdi = 'sinavglobal.com' //gundogdulearningcentre
            // const obj = {
            //     DomainAdi : 'sinavglobal.com',
            // }
            try {
                this.$store.commit('setLoading', true)
                
                const TemaData = await LmsService.OkulAyarlar(obj)
				// if(!TemaData ){
                    // this.$store.state.OkulAyarlar = TemaData
                    this.$store.commit('setOkulAyarlar', TemaData)
                    this.$store.commit('setStorageOkulAyarlar', TemaData)
                    this.RenkTonlariOlustur(this.$store.state.OkulAyarlar.BackgroundColor)
				// }
                this.$store.commit('setLoading', false)

			} catch (error) {
                this.$store.commit('setLoading', false)
				this.$toast.open({
					message: "Okul Tema bilgileri bulunamadı.",
					type: "error",
					duration: 3000,
					dismissible: true,
					position : 'top'
				})
				return
			}
        },

        SifreGoster() {
            if(this.SifreTipi === 'text') {
                this.SifreTipi = 'password'
                this.SifreIcon = 'feather-eye'
            } else {
                this.SifreTipi = 'text'
                this.SifreIcon = 'feather-eye-off'
            }
        },

        // async Login() {
        //     this.Giris = true
        //     const obj = {
        //         email: this.KullaniciAdi,
        //         password: this.Sifre,
        //         api_key: this.$store.state.OkulAyarlar.ApiKey,
        //     }

        //     try {
        //         this.$store.commit('setLoading', true)
        //         const res = await LmsService.UesLmsTokenAl(obj)

        //         if(!res || res.message === 'Request failed with status code 401'){
        //         this.$store.commit('setLoading', false)                    
        //             this.$toast.open({
        //                 message: 'res.message',
        //                 type: "error",
        //                 duration: 7000,
        //                 dismissible: true,
        //                 position : 'top'
		// 		    })
        //         }else{
        //             // this.$store.state.token = res.data.token
        //             // this.$store.commit('setToken', res.data.token)

        //             this.$store.commit("setToken", res.data)
        //             this.$store.commit("setStorageToken", res.data)
        //             await this.$store.dispatch('actionToken', {
        //             token     : res.data.token,
        //             expiresIn : 60*60*60
        //             });

        //             this.KullaniciBilgiAl()
        //             this.$store.commit('setLoading', false)
                    
        //         } 
		// 	} catch (error) {

        //         this.$store.commit('setLoading', false)
		// 		this.$toast.open({
		// 			message: error.message,
		// 			type: "error",
		// 			duration: 3000,
		// 			dismissible: true,
		// 			position : 'top'
		// 		})
		// 		return
		// 	}
        // },

        async Login() {
            this.Giris = true

            const obj = {
                email: this.KullaniciAdi,
                password: this.Sifre,
                api_key: this.$store.state.OkulAyarlar.ApiKey,
            }

            try {
                this.$store.commit('setLoading', true)
                const res = await LmsService.UesLmsTokenAl(obj)
                if(res.success == false){
                this.$store.commit('setLoading', false)                    
                    this.$toast.open({
                        message: res.data,
                        type: "error",
                        duration: 3000,
                        dismissible: true,
                        position : 'top'
				    })
                }else{

                    this.KullaniciBilgiAl(this.KullaniciAdi, this.Sifre)
                    this.$store.commit('setLoading', false)
                    
                } 
			} catch (error) {

                this.$store.commit('setLoading', false)
				this.$toast.open({
					message: error.message,
					type: "error",
					duration: 3000,
					dismissible: true,
					position : 'top'
				})
				return
			}
        },

        // async KullaniciBilgiAl() {
        //     try {
        //         const res = await LmsService.KullaniciBilgiAl()

        //         const KullaniciData = res.data.data
        //         KullaniciData.KullaniciAdi = this.KullaniciAdi
        //         KullaniciData.Sifre = this.Sifre

        //         this.$store.commit('setKullaniciDetaylari', KullaniciData)
        //         this.$store.commit('setStorageKullaniciDetaylari', KullaniciData)                

        //         this.$store.commit('setLoading', false)
        //         this.$router.push( { path : "/home"} )

		// 	} catch (error) {
        //         this.$store.commit('setLoading', false)

		// 		this.$toast.open({
		// 			message: error.message,
		// 			type: "error",
		// 			duration: 3000,
		// 			dismissible: true,
		// 			position : 'top'
		// 		})
		// 		return
		// 	}
        // },

        async KullaniciBilgiAl() {
            try {
                this.$store.commit('setLoading', true)                    
                const res = await LmsService.KullaniciBilgiAl(this.KullaniciAdi, this.Sifre)
                this.$store.commit('setLoading', false)                    

                if(res.success == false){
                    this.$toast.open({
                        message: res.data,
                        type: "error",
                        duration: 3000,
                        dismissible: true,
                        position : 'top'
				    })
                }else{
                    this.$store.commit('setLoading', false)
                    this.$router.push( { path : "/home"} )

                    if(this.$store.state.KullaniciDetaylari.role_type =='teacher'){
                        ContentService.OgretmenOgrencileriGetir(this.$store.state.KullaniciDetaylari.organization)
                    }                    
                } 
                this.$store.commit('setLoading', false)            


			} catch (error) {
                this.$store.commit('setLoading', false)

				this.$toast.open({
					message: error.message,
					type: "error",
					duration: 3000,
					dismissible: true,
					position : 'top'
				})
				return
			}
        },
    },

}
</script>

<style scoped>



/* .THMbutton {
    background: var(--color);
    border-color: var(--color);
}

.THMbutton:hover {
    background: var(--color-hover);
    border-color: var(--color-hover);
}

.THMinput {
    border-color: var(--border-color);
}

.THMinput:focus {
    border-color: var(--focus-color);
} */




</style>
