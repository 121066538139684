<template >
	<div class="row" style="justify-content: center;">
		<div v-for="(item) in this.AssignmentsGosterim" :key="item.assignment_id" class="col-xl-2 col-lg-3 col-md-4 col-sm-4 d-flex" 
		style="cursor: pointer;">
			<div class="featured-details-two" :style="CardStyleObject" >
				<div class="product-item-two">
					<div class="product-img-two" >
						<a href="javascript:void(0);" style="font-size: 18px !important;">
							<img class="img-fluid" alt="Img" :src="item.scorm.scorm_image">
						</a>									
					</div>
					<div class="course-details-content">
						<div class="name-text featured-info-two" >
							<p class="me-3" >{{ item.assignment_name }}</p>
							<h3 style="margin-top:-10px" ><span>{{ item.scorm.collection_path }}</span></h3>
							<h3 class="title instructor-text" ><a >{{ item.scorm.scorm_name }}</a></h3>
						</div>
						
						<!-- <div class="featured-info-time d-flex align-items-center">
							<div class="hours-time-two d-flex align-items-center">
								<span><i class="fa-regular fa-clock me-2"></i></span>
								<h3><span>Due Date: {{ DateFormat(item.end_date) }}</span></h3>
							</div>
						</div>
						<br>
						<div class="featured-info-time d-flex align-items-center">
							<div class="hours-time-two d-flex align-items-center">
								<div class="price combo" >
									<h3 v-if="IsExpired(item.end_date)">continues</h3>
									<h3 v-if="!IsExpired(item.end_date)" style="color: #ff0100 !important;">expired</h3>
								</div>
							</div>
						</div> -->
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import {mapGetters} from "vuex";
import LmsService from '@/services/lms.service';
// import Content from '@/services/content';
import moment from 'moment';
import 'vue2-datepicker/index.css';

// import DatePicker from 'vuejs-datepicker';


export default {
	// eslint-disable-next-line vue/multi-word-component-names
	name: "AssignmentCardForList",
	props:['Assignments','assignment_name','name','start_date','end_date'],

	data() {
		return {
			Hazir : false,
			// Assignments: this.AssignmentsData,
			AssignmentsGosterim: [],
			SistemTarihi: null,
			KayitSayisi: 0,
			StartDate : null,
			DueDate: null,
			format: 'DD.MM.YYYY',

		};
	},
	
	async mounted() {
		// console.log(this.Assignments)
		await this.SistemTarihiGetir()
		this.AssignmentsListele();

	},

	computed:{
		...mapGetters(['CardStyleObject']),

		ToplamKayitSayisi() {
			return this.KayitSayisi
		},

	},
	methods: {
		DateFormat(value){
        	return moment(value).format("DD.MM.YYYY"); 
    	},
		
		IsExpired(value){
        	var a = new Date(this.SistemTarihi); 
        	var b =new Date(value) 
			// a.getTime() - b.getTime()
			return (a.getTime() > b.getTime()) ? false : true
    	},
		// AssignmentDetayGit(value){
		// 	const Assignment = this.AssignmentsGosterim.find(x => x.assignment_id == value);
        // 	// this.$router.push({ name: 'assignmentDetails', params: { id: 123 }, state: { extraData:value } });
        // 	this.$router.push({ name: 'assignmentDetails', params: { Assignment } });
    	// },


		Durum(start_date, end_date){
			// console.log(start_date, end_date, this.SistemTarihi)
        	var st = new Date(this.SistemTarihi); 
        	var sd =new Date(start_date) 
        	var ed =new Date(end_date) 
			// console.log(sd.getTime(), ed.getTime(), st.getTime())

			if (sd.getTime() > st.getTime()){
				return 'Not Started'
			} else if (ed.getTime() < st.getTime()){
				return 'Expired'
			} else { return 'Ongoing'}
    	},



		AssignmentsGit(){
			this.$router.push( { path : "/assignments"} )
		},

		async SistemTarihiGetir(){
			try {
				const resData = await LmsService.SistemTarihiGetir()
				if(resData){
					this.SistemTarihi = resData
				}

			} catch (error) {
				return
			}
		},

		async AssignmentsListele(){
			this.KayitSayisi = 0
			try {

				if(this.Assignments.length > 0){
					this.AssignmentsGosterim = this.Assignments

					const Assignment = this.Assignments.filter(x=> x.assignment_name == this.assignment_name && x.name == this.name && 
					x.start_date == this.start_date && x.end_date == this.end_date ); 
			
					// Şimdi tekil hale getiriyoruz
					const UniqAssignment = [
					...new Map(Assignment.map(item => [JSON.stringify(item), item])).values()
					];
					this.AssignmentsGosterim = UniqAssignment.sort((a, b) => b.start_date - a.start_date);
					this.AssignmentsGosterim.sort((a, b) => b.start_date - a.start_date);

					const AssigneeListe = this.AssignmentsGosterim.map(({ name }) => ({ name })); 
					const UniqAssigneeListe = [
						...new Map(AssigneeListe.map(item => [JSON.stringify(item), item])).values()
					];
					this.AssigneeListe = UniqAssigneeListe
					// this.AssigneeListe.unshift('All')
				}
				this.KayitSayisi = this.AssignmentsGosterim.length

			} catch (error) {
				console.log('Assignment Göster',error)
			}
		},





		activated() {
			

		},
		created () {
		}
	}
}
</script>

<style scoped>

.text-center{
	text-align:center
}

.custom-table table th {
  background: var(--color) !important;
  font-weight: 500;
  color: #1b1b1b;
  padding: 15px 10px;
  /* border-color: #f4f6f9; */
}
/* .custom-table table td:hover {
  background-color: var(--color) !important;
} */
.table-hover>tbody>tr:hover>* {
    --bs-table-color-state: var(--color) !important;
    --bs-table-bg-state: var(--color-hover) !important;
}
.select-noimg{
	--bs-form-select-bg-img: none !important;
}

.featured-details-two .name-text h3 a {
    font-weight: 500;
    font-size: 14px;
    color: var(--color) !important;
}

.featured-info-two{
	text-wrap: wrap; 
	margin-bottom: 0px;
	padding-bottom: 0px !important;
}

.course-details-content p {
    font-size: 13px;
}
.course-details-content span {
    font-size: 13px;
}

.course-details-content {
    padding: 20px 10px 1px;
}
.price.combo h3 {
    font-size: 13px !important;
}
</style>
