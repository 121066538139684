import Vue from 'vue';
import Vuex from 'vuex';
import users from "../store/moduller/users.js";
import theme from "../store/moduller/theme.js";
import Global from "@/global";


// import { router } from "../../router";

// import { createVuexPersistedState } from "vue-persistedstate";

// import VuexPersistence from 'vuex-persist';
// const vuexLocal = new VuexPersistence({
//   storage: window.localStorage,
// });

import CryptoJS from 'crypto-js';
import LmsService from '@/services/lms.service';
import ContentService from '@/services/content';
import { colorShades, getTextColorBasedOnBackground } from "@/global/color";
import {router} from '@/router';
import Content from '@/services/content';

Vue.use(Vuex);

export const store = new Vuex.Store (
{
    // plugins: [
    //     createVuexPersistedState({
    //       key:'vuex',
    //       storage:window.localStorage,
    //       whiteList:["token","Passphrase","userProfile","products","province","personTitle","schoolList","regionLit","generalUsers","visitEditBackTo"],
    //       blackList: [],
    //     }),
    //   ],
    // plugins: [vuexLocal.plugin],
    state: {
        token      : "",
        expiresIn  : null,
        Passphrase : 'HJx7t7bTm9WWFQpB2KxAQhVRafMiR@j7TCHYvtzB4w7Gpgw8',
        isLoading  : false,
        isLoged    : false,
        // sepetListesiSelect:[],

        //sayfa liselemelerinde kullanılacak parametreler
        paramOffset : 0,
        paramRows   : 10,
        rowCount : 0, //Gelen sonuçtan alınacak olan sayfalama yapılmamış toplam satır sayısı
        currentPage : 1,
        //sayfa liselemelerinde kullanılacak parametreler
        AssignmentDetay: {},
        ScormView: {},
        Resources:[],
        OkulAyarlar: {},
        RenkTonlama: 5,
        RenkTonlari: [],
        DomainAdi: null,
        previousRoute : null,
        OgretmenOgrencileri: []
        // KullaniciDetaylari: null


    },
    modules :{
        users, theme
    },

    getters : {
        isLoged(state){
            return state.token !== ""
            //token var mı login miyiz, route lar arası dolaşırken token varmı bakıyoruz token yoksa direk logine yönlendiriyoruz.
        },
        getToken(state){
            return state.token
        },
        isLoading(state){
            return state.isLoading
        },
        paramOffset(state){
            return state.paramOffset
        },
        paramRows(state){
            return state.paramRows
        },
        rowCount(state){
            return state.rowCount
        },
        isLogIn(state){
            return state.isLoged
        },
        getOkulAyarlar(state){
            return state.OkulAyarlar
        },
        async getAssignmentDetay(state){
            await Global.cookie.getAssignmentDetay()
            return state.AssignmentDetay
        },
        getScormView(state){
            Global.cookie.getScormView()
            return state.ScormView
        },


    },

    mutations : {

        SET_PREVIOUS_ROUTE(state, previousRoute) {
            state.previousRoute = previousRoute;
        },

        setToken(state, payload){
            state.token = payload.token
        },

        setStorageToken(state, payload){
            location.host == 'localhost:8080' ? localStorage.setItem("LMSTokenData", JSON.stringify(payload).toString())
            : localStorage.setItem("LMSData0", CryptoJS.AES.encrypt(JSON.stringify(payload).toString(), this.state.Passphrase).toString())
        },

        clearToken(state){
            state.token = ""
        },
        getToken(state){
            return state.token
        },
        setLoading(state, visible){
            state.isLoading = visible
        },
        set_rowCount(state, value){
            state.rowCount = value
        },
        setIsLogIn(state){
            return state.isLoged = state
        },
        setOkulAyarlar(state, value){
            state.OkulAyarlar = value
        },
        setKullaniciDetaylari(state, value){
            state.KullaniciDetaylari = value
        },
        setOgretmenOgrencileri(state, value){
            state.OgretmenOgrencileri = value
        },

        setStorageOkulAyarlar(value){
            location.host == 'localhost:8080' ? localStorage.setItem("LMSOkulAyarlar", JSON.stringify(value.OkulAyarlar).toString())
            : localStorage.setItem("LMSData2", CryptoJS.AES.encrypt(JSON.stringify(value.OkulAyarlar).toString(), this.state.Passphrase).toString())
        },
    
        clearOkulAyarlar(state){
            state.OkulAyarlar = null
        },

        setAssignmentDetay(state, value){
            state.AssignmentDetay = value;
            location.host == 'localhost:8080' ? localStorage.setItem("LMSAssignmentDetay", JSON.stringify(value).toString())
            : localStorage.setItem("LMSData3", CryptoJS.AES.encrypt(JSON.stringify(value).toString(), this.state.Passphrase).toString())
        },

        setScormView(state, value){
            state.ScormView = value;
            location.host == 'localhost:8080' ? localStorage.setItem("LMSScormView", JSON.stringify(value).toString())
            : localStorage.setItem("LMSData4", CryptoJS.AES.encrypt(JSON.stringify(value).toString(), this.state.Passphrase).toString())
        },

        setResources(state, value){
            state.Resources = value;
            location.host == 'localhost:8080' ? localStorage.setItem("LMSResources", JSON.stringify(value).toString())
            : localStorage.setItem("LMSData5", CryptoJS.AES.encrypt(JSON.stringify(value).toString(), this.state.Passphrase).toString())
        },
        clearResources(state){
            state.Resources = null;
        }


    },
    actions : {

        async appInit({ commit, dispatch}){
        //    console.log(window.location.hostname); // Alan adı (domain)

            // await dispatch('OkulAyarlar')

            //store.state.DomainAdi = 'fnlenglish'//'fnlenglish' gundogdulearningcentre
            store.state.DomainAdi = window.location.hostname
            const obj = {
                DomainAdi : window.location.hostname,//'fnlenglish',
            }
            try {
                const TemaData = await LmsService.OkulAyarlar(obj)  
                commit('setOkulAyarlar', TemaData)
                commit('setStorageOkulAyarlar', TemaData)

                await dispatch('RenkTonlariOlustur',TemaData.BackgroundColor)
                document.body.style.backgroundColor = store.state.RenkTonlari[4]?.color??'#fff' // app.vue içinden buraya aldık refresh sırasında gelmesi geçikmesin diye
			} catch (error) {
				return
			}

            const enter = await Global.cookie.getKullaniciDetaylari()
            if(!enter){
                dispatch("logout")
                return false
            } else{

                let time = new Date().getTime()
                if(time >= this.state.expiresIn){
                    dispatch("logout")
                    return
                } 

                const obj = {
                    email: store.state.users.KullaniciDetaylari.KullaniciAdi,
                    password: store.state.users.KullaniciDetaylari.Sifre,
                    api_key: store.state.OkulAyarlar.ApiKey,
                }
                
                try {
                    const res = await LmsService.UesLmsTokenAl(obj)
                    if(!res || res.message === 'Request failed with status code 401'){
                        dispatch("logout")
                    }else{

                        commit("setToken", res.data.token)
                        commit("setStorageToken", res.data)

                        await dispatch('actionToken', {
                            token     : res.data.token,
                            expiresIn : 60*60*60
            
                        });	
                        
                        

                        await LmsService.KullaniciBilgiAl(store.state.users.KullaniciDetaylari.KullaniciAdi, store.state.users.KullaniciDetaylari.Sifre)
                        await Global.cookie.getKullaniciDetaylari()
                        if(store.state.KullaniciDetaylari.role_type =='teacher'){
                            ContentService.OgretmenOgrencileriGetir(store.state.KullaniciDetaylari.organization)
                        }  
                        // const KullaniciData = res1.data.data
                        // KullaniciData.KullaniciAdi = store.state.users.KullaniciDetaylari.KullaniciAdi
                        // KullaniciData.Sifre = store.state.users.KullaniciDetaylari.Sifre
                        // commit('setKullaniciDetaylari', KullaniciData)
                        // commit('setStorageKullaniciDetaylari', KullaniciData)
                        
                        try {
                            await Content.getCollections()
                        } catch (error) {
                            console.log('Colletion yüklenemedi')
                        }
                        
                        // router.push("/home");                        
                    } 
                } catch (error) {

                    router.push("/login");
                    return
                }

            }
        },

        // async OkulAyarlar({commit,dispatch}) {
        //     // console.log('tema')
        //     //console.log(window.location.hostname); // Alan adı (domain)
        //     store.state.DomainAdi = 'gundogdulearningcentre'//'fnlenglish' gundogdulearningcentre
        //     const obj = {
        //         DomainAdi : 'gundogdulearningcentre',
        //     }

        //     try {
        //         const TemaData = await LmsService.OkulAyarlar(obj)  
        //             commit('setOkulAyarlar', TemaData)
        //             commit('setStorageOkulAyarlar', TemaData)
        //             await dispatch('RenkTonlariOlustur',TemaData.BackgroundColor)
		// 	} catch (error) {
		// 		return
		// 	}
        // },

        RenkTonlariOlustur(state, Renk) {
            // console.log(Renk)
            // this.RenkTonlari = colorShades(Renk, this.RenkTonlama);
            store.state.RenkTonlari = colorShades(Renk, store.state.RenkTonlama).map((shadeHex) => {
            const textColor = getTextColorBasedOnBackground(shadeHex); // Metin rengi belirleme
            return { color: shadeHex, textColor };
            });
            // console.log(store.state.RenkTonlari)

        },


        clearStorage({ commit}){
            if (location.host !=='localhost:8080') {
                localStorage.removeItem("LMSData0")
                localStorage.removeItem("LMSData1")
                localStorage.removeItem("LMSData3")
                localStorage.removeItem("LMSData4")
                localStorage.removeItem("LMSData5")
                //localStorage.removeItem("data2")

            }else{
                localStorage.removeItem("LMSTokenData")
                localStorage.removeItem("LMSKullaniciDetaylari")
                localStorage.removeItem("LMSAssignmentDetay")
                localStorage.removeItem("LMSScormView")
                localStorage.removeItem("LMSResources")
                //localStorage.removeItem("OkulAyarlar")
            }
            commit("clearToken")
            commit("clearKullaniciDetaylari")
            commit("clearResources")
            
            // commit("clearOkulAyarlar")
            

        },

        async actionToken({ commit, dispatch}, data){
            const tokenData = {
                token : data.token,
                expiresIn : new Date().getTime() + +data.expiresIn * 100
            }
            
            commit("setToken", tokenData)
            commit("setStorageToken", tokenData)

            await dispatch("setTimeoutTimer", +data.expiresIn * 1000)

        },

        logout({ dispatch}){
            dispatch("clearStorage")
            window.location = '#/login';
        },

        setTimeoutTimer({dispatch}, tokenExpireTime){
            setTimeout(() => {
            dispatch("logout")
            }, tokenExpireTime)
        },

    }
}
)
