import { colorShades } from "@/global/color";
import {store} from "../store";

const getters = {
    Button1StyleObject() {
        const Color = store.state.OkulAyarlar?.Button1Color ?? '#CC0000';
        const TextColor = store.state.OkulAyarlar?.Button1TextColor ?? '#FFFFFF';

        return {
            '--color': Color,
            '--color-hover': colorShades(Color, 5)[1],
            'color': TextColor,
        };
    },

    Button2StyleObject() {
        const Color = store.state.OkulAyarlar?.Button2Color ?? '#010a17';
        const TextColor = store.state.OkulAyarlar?.Button2TextColor ?? '#FFFFFF';

        return {
            '--color': Color,
            '--color-hover': colorShades(Color, 5)[1],
            'color': TextColor,
        };
    },

    TableStyleObject() {
        const Color = store.state.OkulAyarlar?.BackgroundColor ?? '#CC0000';
        return {
            '--color': colorShades(Color, 5)[3]+'52',
            '--color-hover': colorShades(Color, 5)[3]+'22',
        };
    },

    Text1StyleObject() {
        const Color = store.state.OkulAyarlar?.BackgroundColor ?? '#000000';
        return {
            'color': colorShades(Color, 5)[0],
            // 'color': this.$store.state.OkulAyarlar.Button1TextColor == null ? 'default': this.$store.state.OkulAyarlar.Button1TextColor,
        };
    },

    InputStyleObject() {
        return {
            '--border-color': store.state.RenkTonlari[4]?.color ?? '#dc3545',       
            '--focus-color': store.state.RenkTonlari[2]?.color ?? '#dc3545',
        };
    },

    NextButtonStyleObject() {
        const Color = '#1d9cfd';
        const TextColor = '#FFFFFF';
        return {
            '--color': Color,
            '--color-hover': colorShades(Color, 5)[1],
            'color': TextColor,
        };
    },

    BackButtonStyleObject() {
        const Color = '#ff4667';
        const TextColor = '#FFFFFF';
        return {
            '--color': Color,
            '--color-hover': colorShades(Color, 5)[1],
            'color': TextColor,
        };
    },

    SaveButtonStyleObject() {
        const Color = '#159f46';
        const TextColor = '#FFFFFF';
        return {
            '--color': Color,
            '--color-hover': colorShades(Color, 5)[1],
            'color': TextColor,
        };
    },

    CardStyleObject() {
        const Color = store.state.OkulAyarlar?.BackgroundColor ?? '#000000';
        const BoxShadowColor = store.state.RenkTonlari[4]?.color ?? '#413655';
        return {
            '--box-shadow-color': BoxShadowColor,
            '--background': colorShades(Color, 5)[1],
            '--color': colorShades(Color, 5)[0],
            '--color-h3': colorShades(Color, 5)[5],
            '--in-class-bgcolor': colorShades(Color, 5)[3],
            '--icbox-shadow-color': colorShades(Color, 5)[3],
        };

    },

    ProfileStyleObject() {
        const Color = store.state.OkulAyarlar?.BackgroundColor ?? '#000000';
        return {
            '--background': colorShades(Color, 5)[1],
        };
    },

    VSelectStyleObject(){
        return {
            '--vs-colors--lightest': store.state.RenkTonlari[4]?.color ?? '#dc3545',       
            '--focus-color': store.state.RenkTonlari[2]?.color ?? '#dc3545',
        };
    },

    CourseHoverStyleObject() {
        const Color = store.state.OkulAyarlar?.BackgroundColor ?? '#CC0000';
        const TextColor = store.state.OkulAyarlar?.Button1TextColor ?? '#FFFFFF';

        return {
            '--ovelay-bg': colorShades(Color, 5)[0]+'62',
            '--ovelay-btn': '#00000030',/*colorShades(Color, 5)[1]+'20',*/
            '--txt-color': TextColor,
        };
    },

    ScrollBarColor() {
        const Color = store.state.OkulAyarlar?.BackgroundColor ?? '#CC0000'
        return {
            '--background-color': colorShades(Color, 5)[3]
        };
    },

}


export default {
    getters 
}

