<template >
	<div>
		<!-- <section  class="home-two-slide d-flex align-items-center" style="margin-top: 131px; justify-content:center"> -->
		<section v-if="!Hazir" class="home-two-slide d-flex align-items-center" style="margin-top: 131px; justify-content:center">
			<div class="row ">
			<span  class="loader"></span>
			</div>
		</section>

		<section class="home-two-slide d-flex align-items-center" v-if="Hazir">

			<div class="container">
				<div class="row ">					
					<div class="col-lg-12 col-12" data-aos="fade-up">
						<div class="home-slide-face">						
							
							<div class="container">
								<div class="header-two-title text-center" data-aos="fade-up">
									<p class="tagline" style="margin-bottom: -1rem;" :style="Text1StyleObject" >
										Library (myEDULIBRARY) 
									</p>
								</div>

								<div class="featured-courses-two" style="margin-bottom: 25px!important;">
									<div class="row" style="justify-content: center;">
										<div class="dash-pagination">
											<div class="row align-items-center">
												<div class="col-4">
																										
												</div>
												<div class="col-4">
													<div class="fade-up" style="justify-items:center">
														<div class="banner-content">
															<form class="form">
																<div class="input-group">																	
																	<input v-model="Aranan" @keyup="AramaYap"
																	type="email" class="form-control" placeholder="Search">
																</div>
															</form>
														</div>
													</div>
												</div>
												<div class="col-4"> 	
																									
												</div>
											</div>
										</div>										
										
										<template>
											<h5 v-if="ScormData.length == 0" style="text-align: center;" :style="Text1StyleObject">There is no contents.</h5>
											<div v-for="(item) in ScormData" :key="item.id" class="col-xl-2 col-lg-3 col-md-4 col-sm-4 d-flex" 
											 	v-on:click="DerseGit(item.id)" style="cursor: pointer;">
												<div class="featured-details-two" :style="CardStyleObject" >
													<div class="product-item-two">
														<div class="product-img-two" >
															<a href="javascript:void(0);" style="font-size: 18px !important;">
																<img class="img-fluid" alt="Img" :src="item.image">
															</a>									
														</div>
														<div class="course-details-content">
															<div><p class="me-3" >{{ item.name }}</p></div>	
														</div>
													</div>
												</div>
											</div>
										</template>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>

	</div>
</template>

<script>
import {mapGetters} from "vuex";
// import LmsService from '@/services/lms.service';
import moment from 'moment';
import Content from '@/services/content';


export default {
	// eslint-disable-next-line vue/multi-word-component-names
	name: "library",
	// props:{
	// 	Gosterim:null
	// },
	data() {
		return {
			Aranan: "",
			AramaSonuclari: [],
			Hazir : false,
			Scorms: [],
			ScormData: [],
			GecerliSayfa: 1,
			KayitSayisi: 0,
			InnerLoading: true,
		};
	},
	
	async beforeMount() {
		const Izin = this.getRol =='teacher' ? this.getOkulAyarlar.OgretmenMenu1 : this.getOkulAyarlar.OgrenciMenu1
		if(Izin == 0){
			this.$router.push( { path : "/home" } )
			return
		}
		await this.getScorms()
	},

	computed:{
		...mapGetters(['getKullaniciDetaylari','getScormView','getRol','getOkulAyarlar','Button1StyleObject','CardStyleObject','Text1StyleObject']),

		ToplamKayitSayisi() {
			return this.KayitSayisi
		},

	},
	methods: {
		DateFormat(value){
        	return moment(value).format("DD.MM.YYYY"); 
    	},
		async getScorms(){
			this.ScormData = []
			try {
				this.$store.commit('setLoading', true)
				const res = await Content.getScorms(0, 'reader')
				this.ScormData = res.data
				this.Scorms = res.data
				this.$store.commit('setLoading', false)
				this.Hazir = true
			} catch (error) {
				this.Hazir = true
				this.$store.commit('setLoading', false)
				console.log('Scorm Data yüklenemedi')
			}
		},


		DerseGit(id){
			const SData = this.ScormData.find(x=> x.id == id)
			const ScormView = {
				scorm_id: SData.id,
				assignment_id: SData.collection,
				scormTitle: SData.name,
				scormDesc: SData.desc,
				scormCode: SData.scorm_code,
				packageType: 'scorm',
				timestamp: null,
			};

        	this.$router.push({ name: 'scormView', params: { ScormView } });
		},

		AramaYap() {
			if(this.Aranan !=''){
				const Filtre = this.Scorms
				// this.Assignments = Filtre;
				const query = this.Aranan.toLowerCase();
				const AramaSonuc = Filtre.filter((item) =>
					// this.deepSearch(item, query)
					this.searchInSpecificKeys(item, query)					
				);
				this.ScormData = AramaSonuc

			} else {
				this.ScormData = this.Scorms
			}			
			this.KayitSayisi = this.ScormData.length
		},
		
		deepSearch(obj, query) {
			// Tüm anahtarları ve değerleri kontrol etmek için recursive arama
			for (const key in obj) {
				if (Object.prototype.hasOwnProperty.call(obj, key)) {
					const value = obj[key];

					if (
						key.toLowerCase().includes(query) || // Anahtar sorguyu içeriyor mu?
						(typeof value === "string" &&
						value.toLowerCase().includes(query)) || // Değer string olarak sorguyu içeriyor mu?
						(typeof value === "number" && value.toString().includes(query)) // Değer sayı ise sorguyu içeriyor mu?
					) {
						return true;
					}

					// Alt nesneler veya diziler varsa onları da kontrol et
					if (typeof value === "object" && value !== null) {
					if (this.deepSearch(value, query)) {
						return true;
						}
					}
				}
			}
			return false;
		},

		searchInSpecificKeys(obj, query) {
			// Sadece belirli anahtarlar üzerinde arama yapılacak
			const keysToSearch = ["name"];
			for (const key of keysToSearch) {
				if (Object.prototype.hasOwnProperty.call(obj, key)) {
				const value = obj[key];
				if (
					typeof value === "string" &&
					value.toLowerCase().includes(query)
				) {
					return true;
				}
				}
			}

			// Alt nesne kontrolü (örneğin scorm)
			if (obj.scorm) {
				for (const key of keysToSearch) {
				if (Object.prototype.hasOwnProperty.call(obj.scorm, key)) {
					const value = obj.scorm[key];
					if (
					typeof value === "string" &&
					value.toLowerCase().includes(query)
					) {
					return true;
					}
				}
				}
			}

			return false;
		},
		

		activated() {
			

		},
		created () {
		}
	}
}
</script>

<style scoped>



</style>
