<template >
	<div>

		<section class="home-two-slide d-flex align-items-center" >

			<div class="container">
				<div class="row " >					
					<div class="col-lg-12 col-12" data-aos="fade-up">
						<div class="home-slide-face">						
							<div class="container">
								<div class="header-two-title text-center" data-aos="fade-up">									
									<p class="tagline" style="margin-bottom: -1rem;" :style="Text1StyleObject" >
										Create Assignment 
									</p>
								</div>

								<div class="featured-courses-two" style="margin-bottom: 25px!important;">
									<div class="row" style="justify-content: center;">

										<!-- <template v-if="Gosterim"> -->
										<template >
											<div class="settings-menu p-0">											
												<div class="checkout-form">
													<!-- Tab -->
													<div class="col-lg-12" style="display:flex" >
														<div class="col-md-8">													
															<div  class="wishlist-tab" style="text-align: center;" >
																<ul class="nav" role="tablist">
																	<li class="nav-item" style="margin-left: 0px;">
																		<a href="javascript:void(0);" style="width:100%; min-width:200px" class="THMbutton THMbuttonShadow" 
																		:style="AktifTab ==0 ? Button1StyleObject : Button2StyleObject"
																		@click="AktifTabDegis(0)" role="tab">Details</a>
																	</li>

																	<li class="nav-item">
																		<a href="javascript:void(0);" style="width:100%; min-width:200px" class="active THMbutton THMbuttonShadow" 
																		:style="AktifTab ==1 ? Button1StyleObject : Button2StyleObject" 
																		@click="AktifTabDegis(1)" role="tab">Assignees</a>
																	</li>

																	<li class="nav-item">
																		<a href="javascript:void(0);" style="width:100%; min-width:200px" class="active THMbutton THMbuttonShadow" 
																		:style="AktifTab ==2 ? Button1StyleObject : Button2StyleObject" 
																		@click="AktifTabDegis(2)" role="tab">Resources</a>
																	</li>
																</ul>	
															</div>
														</div>
														<div class="col-md-4" style="justify-items: flex-end;">
															<div  class="wishlist-tab" style="text-align: center;">
																<ul class="nav" role="tablist" >
																	<li class="nav-item" style="margin-right: 0px; margin-left: 15px">
																		<a href="javascript:void(0);" style="width:100%; min-width:90px" class="active THMbutton THMbuttonShadow" 
																		:style="BackButtonStyleObject" @click="Vazgec()"
																		role="tab">Cancel</a>
																	</li>
																	<li v-if="KayitAktif() == true" class="nav-item" style="margin-right: 0px; margin-left: 15px">
																		<a href="javascript:void(0);" style="width:100%; min-width:90px" class="active THMbutton THMbuttonShadow" 
																		:style="SaveButtonStyleObject" @click="AssignmentOlustur()"
																		
																		role="tab">Save</a>
																	</li>
																</ul>	
															</div>
														</div>
													</div>
													<!--/Tab -->																	
													<div class="tab-pane active show">
														<hr style="margin-top: 2px;">
														<template v-if="AktifTab == 0">
															<div class="input-block">
																<label class="form-control-label">Assignment Name</label>
																<input v-model="Title" type="text" class="form-control THMinput" placeholder="Assignment Name (Required)" :style="InputStyleObject">
															</div>
															<div class="input-block">
																<label class="form-control-label">Description</label>
																<div class="pass-group">
																	<textarea v-model="Description" rows="4" class="form-control pass-input THMinput" :style="InputStyleObject" 
																	placeholder="Assignment Description"></textarea>
																</div>
															</div>

															<div class="row">
																<div class="col-md-6 ">
																	<div class="input-block">
																		<label class="form-control-label">Start Date</label>
																		<date-picker  v-model="StartDate" valueType="YYYY-MM-DD" :format="format" placeholder="Start Date (Required)" style="padding-top: 4px; display: inline;"
																			:style="InputStyleObject" :disabled-date="StartDateTarihleriEngelle">
																			<template v-slot:header="{ emit }">
																			<button class="mx-btn mx-btn-text" @click="emit(new Date())">Bugün</button>
																			</template>
																		</date-picker>
																	</div>
																</div>
																<div class="col-md-6">
																	<div class="input-block">
																		<label class="form-control-label">Due Date  </label>
																		<date-picker  v-model="DueDate" valueType="YYYY-MM-DD" :format="format" placeholder="Due Date (Required)" style="padding-top: 4px; display: inline;"
																			:style="InputStyleObject" :disabled-date="DueDateTarihleriEngelle">
																			<template v-slot:header="{ emit }">
																			<button class="mx-btn mx-btn-text" @click="emit(new Date())">Bugün</button>
																			</template>
																		</date-picker>
																	</div>																
																</div>
															</div>
														</template>

														<template>
															<Assignees v-if="this.AktifTab == 1"></Assignees>
														</template>

														<template v-if="AktifTab == 2">
															<div class="announcement-head">
																<div class="announcement-titlte">
																	<h6>Please choose a course and lessons. You should select lesson card from left column to the right. 
																		If you add many resources then will be created many assignments with one resource. 
																		Please remember that students have to have access to this course.
																	</h6>
																</div>
															</div>
															<div class="row">
																<div class="col-md-6 ">
																	<div class="input-block">
																		<label class="form-control-label">Collections</label>
																		<v-select placeholder="Select a Collection"
																		@input="childResources()"
																		v-model="ParentId"
																		:options="ParentData"
																		:reduce="(option) => option.id"
																		label="name">
																		</v-select>
																	</div>
																</div>
																<div class="col-md-6 ">

																	<div class="input-block">
																		<label class="form-control-label">Courses</label>
																		<v-select placeholder="Select a Course"
																		@input="getScorms()"
																		
																		v-model="ChildId"
																		:options="ChildData"
																		:reduce="(option) => option.id"
																		label="name">
																		</v-select>
																	</div>
																</div>
															</div>
															
															<div v-if="this.ScormData.length != 0" class="col-lg-12" style="display:flex; margin-bottom:15px" >
																<div class="col-md-2" v-if="AktifTab == 2">
																	<button class="btn btn-primary btn-start THMbutton THMbuttonShadow"
																	@click="HepsiniSec()" :style="NextButtonStyleObject" style="margin-right: 10px;" type="submit"> 
																		Select All
																	</button>
																</div>
																<div v-if="this.SecilenScormlar.length > 0" class="col-md-2 offset-md-8" style="text-align: end;">
																	<button class="btn btn-primary btn-start THMbutton THMbuttonShadow" 
																	@click="HepsiniSil()"
																	:style="BackButtonStyleObject" type="submit">Remove All
																	</button>
																</div>
															</div>
															<div class="row">
																<Courses :Gosterim="`assignment`" ></Courses>
																
															</div>
														</template>

														<hr style="margin-top: 2px;">

														<div class="col-lg-12" style="display:flex" >
															<div class="col-md-2">
																<button v-if="AktifTab != 0" class="btn btn-primary btn-start THMbutton THMbuttonShadow" @click="OncekiAdim()"
																:style="BackButtonStyleObject" style="margin-right: 10px;" type="submit"> 
																<i class="fas fa-arrow-left" style="margin-left:0px; margin-right:6px"></i>
																    Previous
																</button>
															</div>
															<div class="col-md-2 offset-md-8" style="text-align: end;">
																<button v-if="AktifTab != 2" class="btn btn-primary btn-start THMbutton THMbuttonShadow" @click="SonrakiAdim()"
																:style="NextButtonStyleObject" type="submit">Next
																<i class="fas fa-arrow-right ms-2"></i>																
																</button>
															</div>
														</div>

														
													</div>
												</div>
											</div>
										</template>
									</div>
								</div>
								
							</div>

							<!-- <b-pagination v-if="AssignmentsGosterim.length > 2" style="justify-content:center"
										v-model="GecerliSayfa"
										:total-rows="ToplamKayitSayisi"
										:per-page="4"
										aria-controls="my-table"
										first-text="First"
										prev-text="Previous"
										next-text="Next"
										last-text="Last"
										limit="10"
										@input ="sayfaDegistir(GecerliSayfa)">
							</b-pagination> -->


						</div>
					</div>
				</div>
			</div>
		</section>

	</div>
</template>

<script>
import {mapGetters} from "vuex";
import LmsService from '@/services/lms.service';
import moment from 'moment';
import DatePicker from 'vue2-datepicker';
import Assignees from './Component.Assignees';
import ContentService from '@/services/content';
import Courses from "../resources/Component.Course.vue";


import 'vue2-datepicker/index.css';


// import DatePicker from 'vuejs-datepicker';


export default {
	// eslint-disable-next-line vue/multi-word-component-names
	name: "createAssignment",
	props:{
		Gosterim:null
	},
	components: { DatePicker, Assignees, Courses},

	data() {
		return {
			Hazir : false,
			SistemTarihi: null,
			AktifTab: 0,
			KayitSayisi: 0,
			InnerLoading: true,
			format: 'DD.MM.YYYY',

			Title: null,
			Description: null,
			StartDate : null,
			DueDate: null,

			Collections:[],
			ParentData: [],
			ParentId: null,
			ChildData: [],
			ChildId: null,
			Scorms: [],
			ScormData: [],
			SecilenScormlar:[],
			AssigneeData:{},
		};
	},
	
	async mounted() {
		const Izin = this.getRol =='teacher' ? this.getOkulAyarlar.OgretmenMenu4 : 0
		if(Izin == 0){
			this.$router.push( { path : "/home" } )
			return
		}
		this.SistemTarihiGetir()
		await this.getCollections()
	},

	computed:{
		...mapGetters(['getKullaniciDetaylari','getOkulAyarlar','getRol','Button1StyleObject','Button2StyleObject','Text1StyleObject','InputStyleObject',
			'NextButtonStyleObject','BackButtonStyleObject','SaveButtonStyleObject','VSelectStyleObject'
		]),

		ToplamKayitSayisi() {
			return this.KayitSayisi
		},

	},
	methods: {
		DateFormat(value){
        	return moment(value).format("DD.MM.YYYY"); 
    	},

		StartDateTarihleriEngelle(date) {
			const today = new Date();
			today.setHours(0, 0, 0, 0);
			// return date < today || date > new Date(today.getTime() + 7 * 24 * 3600 * 1000);
			return  date < today 
		},

		DueDateTarihleriEngelle(date) {
			let today= null
			if(this.StartDate){
				today = new Date(this.StartDate);
			} else{
				today = new Date();
			}
			today.setHours(0, 0, 0, 0);
			// return date < today || date > new Date(today.getTime() + 7 * 24 * 3600 * 1000);
			return  date < today 
		},
		
		AktifTabDegis(value){
			this.AktifTab = value

		},

		HepsiniSil() {
			this.SecilenScormlar = []
		},

		async HepsiniSec() {
			this.ScormData.forEach(item => {
				const index = this.SecilenScormlar.indexOf(item.id);
				if (index == -1) {
					this.SecilenScormlar.push(item.id); // Diziye id'yi ekle
				} else {
					// this.SecilenScormlar.splice(index, 1); // Diziden id'yi çıkar
				}
			});
		},

		OncekiAdim() {
			this.AktifTab -= 1;
		},
		SonrakiAdim() {
			this.AktifTab += 1;
		},

		async SistemTarihiGetir(){
			try {
				const resData = await LmsService.SistemTarihiGetir()
				if(resData){
					this.SistemTarihi = resData
				}

			} catch (error) {
				return
			}
		},

		async getCollections(){
			try {
				this.$store.commit('setLoading', true)
				const res = await ContentService.getCollections()
				this.Collections = res.data.data
				this.parentCollection();
				this.$store.commit('setLoading', false)
				this.Hazir = true
				 
			} catch (error) {
				this.Hazir = true
				this.$store.commit('setLoading', false)
				console.log('Colletion yüklenemedi')
			}
		},

		async parentCollection(){
			try {
				const ParentCollections = this.Collections
				this.ParentData = ParentCollections.filter((x)=> x.parent == 0)
			} catch (error) {
				console.log('Resouces hata:',error)
				return
			}
		},

		childResources(){
			try {
				const ParentResources = this.Collections
				this.ChildData = ParentResources.filter((x)=> x.parent == this.ParentId )
				this.KayitSayisi = this.ChildData.length
			} catch (error) {
				console.log('Resouces hata:',error)
				return
			}
    	},

		async getScorms(){
			this.ScormData = []
			try {
				this.$store.commit('setLoading', true)
				const res = await ContentService.getScorms(this.ChildId)
				this.ScormData = res.data
				this.Scorms = res.data
				this.$store.commit('setLoading', false)
			} catch (error) {
				this.$store.commit('setLoading', false)
				console.log('Scorm Data yüklenemedi')
			}
		},

		Vazgec(){
			this.$router.push({ name: 'assignments' });
		},

		KayitAktif(){
			if(!this.Title || this.Title.length == 0 || !this.StartDate || this.DueDate == 0 ||!this.AssigneeData || this.AssigneeData.length == 0
			||!this.SecilenScormlar || this.SecilenScormlar.length == 0) {
				return false
			} else return true
		},

		async AssignmentOlustur(){
			const CreateData = {}
			CreateData.assignment_name = this.Title
			CreateData.assignment_desc = this.Description
			CreateData.assignment_start_date = `${this.StartDate} 00:00:00`;
			CreateData.assignment_end_date = `${this.DueDate} 23:59:00`;
			CreateData.block_after_due_date = false
			CreateData.single_access = false
			CreateData.status = true
			CreateData.assignment_scorms = this.SecilenScormlar

			let res = null
			if (this.AssigneeData.assignment_type === 'mixed') {
				const { studentIds } = this.AssigneeData;
				const { groupIds } = this.AssigneeData;
				// delete this.AssigneeData.groupIds;
				// delete this.AssigneeData.studentIds;
				CreateData.ids = studentIds;
				CreateData.assignment_type = 'individual';
				this.$store.commit('setLoading', true)
				res = await ContentService.AssignmentOlustur(CreateData);
				CreateData.ids = groupIds;
				CreateData.assignment_type = 'group';
				res = await ContentService.AssignmentOlustur(CreateData);
			} else {
				CreateData.assignment_type = this.AssigneeData.assignment_type
				CreateData.ids = this.AssigneeData.ids;
				this.$store.commit('setLoading', true)
				res = await ContentService.AssignmentOlustur(CreateData);
			}
			if (res.success === true) {
				this.$store.commit('setLoading', false)
				this.$toast.open({
					message: "Created Successfully!",
					type: "success",
					duration: 3000,
					dismissible: true,
					position : 'top'
				})
				this.$router.push({
					name: 'assignments',
				});
			} else {
				this.$store.commit('setLoading', false)				
				this.$toast.open({
					message: "An error occured while you creating assignments",
					type: "error",
					duration: 3000,
					dismissible: true,
					position : 'top'
				})
				console.log('ASSIGNMENT CREATION ERROR', res.data);
			}
			

		},

		beforeDestroy() {
			this.SecilenScormlar = [],
			this.AssigneeData = {}
		},
	}
}
</script>

<style scoped>

.text-center{
	text-align:center
}

.select-noimg{
	--bs-form-select-bg-img: none !important;
}



</style>
