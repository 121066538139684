import Vue from 'vue'
import App from './App.vue'
import VueResource from 'vue-resource'
import { router } from './router'
import { store } from './components/store/store'
import trUpper from "@/global/upperTr"
// import Global from "@/global";
// import axios from 'axios'

import '@/global/progress.css'; 
import '@/global/global.css'; 
import '@/global/vselect.css'; 

import Vuelidate from "vuelidate"
import { BootstrapVue } from 'bootstrap-vue'

import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css';
Vue.use(VueSweetalert2)

import VueTheMask from 'vue-the-mask'
Vue.use(VueTheMask)

import VueHtmlToPaper from 'vue-html-to-paper';
const printOptions = {
  name: '_blank',
  specs: [
    'fullscreen=yes',
    'titlebar=yes',
    'scrollbars=yes'
  ],
  styles: [
    '/assets/vendor/bootstrap/css/bootstrap.min.css',
    // 'https://unpkg.com/kidlat-css/css/kidlat.css'
    '/assets/css/main.css'
  ],
  timeout: 100, // default timeout before the print window appears
  autoClose: true, // if false, the window will not close after printing
  windowTitle: window.document.title, // override the window title
}
Vue.use(VueHtmlToPaper, printOptions);

// Make BootstrapVue available throughout your project
Vue.use(BootstrapVue)

import VJstree from 'vue-jstree';
Vue.component('v-jstree', VJstree);


import vSelect from 'vue-select'
// import 'vue-select/dist/vue-select.css';
Vue.component('v-select', vSelect)

import vSelect2 from 'v-select2-component';
Vue.component('v-select2', vSelect2);

//axios.defaults.baseURL='http://89.19.16.234:9194/api' //server api
//axios.defaults.baseURL='https://uescrm-api.ey.r.appspot.com/api' //google api
// axios.defaults.baseURL='http://localhost:4000/api'

Vue.use(VueResource)
Vue.use(Vuelidate)

import VueApexCharts from 'vue-apexcharts'
Vue.use(VueApexCharts)
// eslint-disable-next-line vue/multi-word-component-names
Vue.component('apexChart', VueApexCharts)

import SmartTable from 'vuejs-smart-table'
Vue.use(SmartTable)

// eslint-disable-next-line no-unused-vars
import VueTippy, { TippyComponent } from "vue-tippy";
Vue.use(VueTippy);
import "tippy.js/themes/light.css";
import "tippy.js/themes/light-border.css";
import "tippy.js/themes/google.css";
import "tippy.js/themes/translucent.css";

// Vue.directive('uppercase', {
//   update (el) {
//     el.value = el.value.toUpperCase()
//   },
// })

// import Multiselect from 'vue-multiselect';
// Vue.use( Multiselect);

Vue.directive('upperTR', {
  update(el) {
    let strValue = el.value
    let harfler = { "i": "İ", "ş": "Ş", "ğ": "Ğ", "ü": "Ü", "ö": "Ö", "ç": "Ç", "ı": "I" }
    strValue = strValue.replace(/(([iışğüçö]))/g, function(harf){ return harfler[harf]; })
    el.value = strValue.toUpperCase()
  },
})

String.prototype.turkishToUpper = trUpper

Vue.filter("upperTR",(value)=>{
  if(value !== null){
    let string = value
    let harfler = { "i": "İ", "ş": "Ş", "ğ": "Ğ", "ü": "Ü", "ö": "Ö", "ç": "Ç", "ı": "I" }
    string = string.replace(/(([iışğüçö]))/g, function(harf){ return harfler[harf]; })
    return string.toUpperCase()
  }

})

Vue.filter("para",(value)=>{
  return parseFloat(value).toLocaleString(undefined,{minimumFractionDigits:2}) + " TL";
  //Değer tanımsız bile olsa minimum 2 basamaklı göster
})

Vue.filter("para_6digit",(value)=>{
  return parseFloat(value).toLocaleString(undefined,{maximumFractionDigits:6});
  //Değer tanımsız bile olsa minimum 2 basamaklı göster
})

Vue.filter("para_2digit",(value)=>{
  return parseFloat(value).toLocaleString(undefined,{maximumFractionDigits:2});
  //Değer tanımsız bile olsa minimum 2 basamaklı göster
})

Vue.filter("sayi",(value)=>{
  return parseFloat(value).toLocaleString(undefined,{minimumFractionDigits:0});
  //Değer tanımsız bile olsa minimum 2 basamaklı göster
})


// Vue.filter("sayi_format",(value, value2, format)=>{
//   if(format==true){
//     let str = parseFloat(value)
//               .toFixed(value2)
//               .replace(/(\d)(?=(\d{3})+\.)/g, '$1.')
//               .replace(/\.(\d+)$/,',$1');
//               return str
//   } else if(format==false){
//     return parseFloat(value).toFixed(value2)
//   } else return value
// })

Vue.filter("sayi_format",(value, value2, format)=>{
  //  console.log(value,isNaN(value))
  if(value == null) return 0
  if(format==true){
    const arrPara = value.toString().split('.')
    const para1 = parseInt(arrPara[0]).toLocaleString(undefined,{minimumFractionDigits:0}).replace(",", ".")
    let tamPara = 0
    tamPara = para1
    if (arrPara[1]){
      const kurus = parseFloat('0.'+arrPara[1]).toFixed(value2)
      const arrKurus = kurus.toString().split('.')
      if (typeof arrKurus[1] !== 'undefined'){
        tamPara = tamPara+','+arrKurus[1]
      } else {
        tamPara
      }
    } 
    return tamPara

  } else if(format==false){
    return parseFloat(value).toFixed(value2)
  } else return value
})


import VueToast from 'vue-toast-notification';
import 'vue-toast-notification/dist/theme-sugar.css';
Vue.use(VueToast);






export const eventBus = new Vue();

// import Global from "@/global";
// import {store} from "@/components/store/store";

// import VueRouter from "vue-router"
// import { router } from './router'
// Vue.use(VueRouter)
// const router = new VueRouter(
//     {
//       routes : routes,
//       mode : ""
//     }
// )

// router.beforeEach(async (to, from, next ) => {
//     console.log(1,from.name, from.path, to.name, to.path)
//     console.log(2,from.meta)


//     const enter = await Global.cookie.getUserDetails()
//     // const routeNames = to.name;
//     const userRole = store.state.users.userDetails.role
//     console.log('eeee',userRole)
//      console.log(3, to.meta.allowedRoles)

//     if (to.meta.auth && !enter) {
//         next({ name: 'login' });
//     } else if (to.meta.allowedRoles && !to.meta.allowedRoles.includes(userRole)) {
//         console.log(4,to.meta.allowedRoles)
//         // if(to.name =='login'){// eğer giriş yapmışsa ve login sayfasına gitmek isterse ana sayfaya yönlendir
//             userRole === 'admin' 
//             ? next({ name: 'mainPageAdmin' }) //await router.replace('/admin')  
//             : next({ name: 'mainPage' });//await router.replace('/') 
//         // } 
//         // else {
//         //     next()
//         // }

//     } else {
//         console.log(5,to.path)
//         if (to.path === '/user/login' || to.path === '/admin/login'){
//             // console.log(2,store.state.users.userDetails.role)
//             store.state.users.userDetails.role == "user" ? await router.replace('/user') : await router.replace('/admin')
//             // return
//         }  
//         next();
//     }

// });

// router.beforeEach(async (to, from, next ) => {
//     // console.log(from.name, from.meta, to.meta, to.name, to.path)
//     const enter = await Global.cookie.getUserDetails()
//     // const routeNames = to.matched.map(x => x.name);
//     const routeRoles = to.meta.allowedRoles
//     const userRole = store.state.users.userDetails.role
//      console.log(to.name,to.path, to.meta, enter)
//      console.log(to.name,to.path, from.name, from.path)
//     if ('auth' in to.meta && to.meta.auth && !enter) {
//         //auth true olan herhangi bir sayfaya gitmeye çalışırsa ve giriş yoksa logine gönder
//         next('/user/login')
//     } 
//     else if('auth' in to.meta && !to.meta.auth){
//         //login sayfasına geldik
//         //Eğer giriş yapılmışsa logineden yönlendir
//         if(enter){
//             userRole === 'admin' 
//             ? next({ name: 'mainPageAdmin' }) //await router.replace('/admin')  
//             : next({ name: 'mainPage' });//await router.replace('/')            
//         }else{
//             next()
//         }
        
//     } 
//     else if ('auth' in to.meta && to.meta.auth && enter){
//         //giriş yapılmışsa sayfada gezinme
//         if (to.path === '/user/login' || to.path === '/admin/login'){
//             // console.log(2,store.state.users.userDetails.role)
//             store.state.users.userDetails.role == "user" ? await router.replace('/user') : await router.replace('/admin')
//             return
//         }  

//         if (routeRoles && routeRoles.length > 0 && !routeRoles.includes(userRole)) {
//             next({ path: from.path });
//           } else if (routeRoles) {
//             if((to.path =='/user' || to.path =='/user/home') && userRole == 'admin'){
//             // if(to.path.search('/user') >= 0 && userRole == 'admin'){
//                 next({ name: 'mainPageAdmin' });
//             } else if ((to.path =='/admin' || to.path =='/admin/home') && userRole == 'user'){
//             // } else if (to.path.search('/admin')  && userRole == 'user'){
//                 next({ name: 'mainPage' });
//             } else{
//                 next();
//             }

//           } else {
//             next({ name: 'login' });
//           }

//     } else{
//         if(from.name =='join'){
//             next({path: from.path})
//         } else{
//             next()
//         }
        
//     }

// });

Vue.config.productionTip = false
new Vue({
  router,
  store,
  VueToast,
  render: h => h(App),
}).$mount('#app')
