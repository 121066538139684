<template>
    <div>
        <div v-if="isLoged && this.$route.path!='/scormView'" class="topbarnav">
            <app-top-nav-bar></app-top-nav-bar>
        </div>
        
        <div  :class ="(this.$route.path!='/scormView' && this.$route.path!='/login')? 'mainDiv' : 'mainDiv2'" :style="ScrollBarColor" >
        <!-- <div  :class ="(this.$route.path!='/login')? 'mainDiv' : 'mainDiv2'" :style="ScrollBarColor" > -->
            <div id="main-wrapper log-wrap" >
                <app-Loading v-if="isLoading"></app-Loading>
                <!-- <app-Login ></app-Login> -->
                <!-- <app-top-nav-bar v-if="isLoged && this.$route.path!='/scormView'"></app-top-nav-bar> -->
                <!-- <router-view></router-view> -->
                <!-- <app-left-side-bar v-if="isLoged"></app-left-side-bar> -->

                <transition name="fade" mode="out-in">	
                <router-view></router-view>
                </transition>
            </div>

            <div  v-if="isLoged">
                <div class="patter-one-main" >
                    <img class="patter-one" src="assets/img/bg/patter-one.png" alt="Img">
                    <img class="patter-two" src="assets/img/bg/home-middle.png" alt="Img" >
                </div>

                <div class="shapes">
                    <img class="shapes-one" src="assets/img/bg/home-right.png" alt="Img" style="right: 0rem;">
                    <!-- <img class="shapes-two" src="assets/img/bg/home-right-bottom.png" alt="Img">
                    <img class="shapes-middle" src="assets/img/bg/home-middle.png" alt="Img"> -->
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import topNavBar from "./components/static/TopNavBar";
// import footer from "./components/static/footer";
import Loading from "./components/static/Loader";

// import Login from "./components/auth/loginPage"
import Global from "@/global";
// import { mapActions } from 'vuex';
import { colorShades } from "@/global/color";
import {mapGetters} from "vuex";



export default {
name: 'app',
data(){
return{
}
},
components : {
appTopNavBar : topNavBar,
// appFooter : footer,
appLoading : Loading
// appLogin: Login
},

async beforeMount() {
// await this.$store.dispatch("appInit")
},
async mounted() {
  // Pencere kapanmadan önce tüm projede geçerli olacak şekilde olayı dinle
  //window.addEventListener('beforeunload', this.clearLocalStorage);
},

// beforeDestroy() {
//     // Bellek sızıntılarını önlemek için olayı kaldır
//     window.removeEventListener('beforeunload', this.clearLocalStorage);
// },

async beforeCreate() {
  // const [navigationEntry] = performance.getEntriesByType('navigation');
  // if (navigationEntry && navigationEntry.type === 'reload') {
  //   console.log('Sayfa yenilendi.');
  // } else {
  //   console.log('Sayfa ilk kez yüklendi.');
  // }
  // this.appInit();
  await this.$store.dispatch("appInit")
  // await this.setBackgroundColor()


},
async created(){

  //İlk başlangıçta token kontrolü yapılıyor, ama refresler direk ana sayfa yapmasın diye her init olayında route yolunu gönderiyoruz ki refreshler aynı sayfada kalsın
  // await this.$store.dispatch("appInit")
  Global.cookie.getKullaniciDetaylari()
  // this.appInit();

  //Uygulama ilk açıldığında
  this.$store.state.paramOffset = 0
  this.$store.state.paramRowsOffset = 20

},
computed: {
  ...mapGetters(['ScrollBarColor']),
  isLoged(){
      return this.$store.state.token
  },
  isLoading(){
      return this.$store.getters.isLoading;
  },
  
  // ScrollBarColor() {
	// 		const Color = this.$store.state.OkulAyarlar?.BackgroundColor ?? '#CC0000';

	// 		return {
	// 			'--background-color': this.RenkTonuVer(Color, 5, 3),
	// 		};
	// 	},
},
methods:{
  // ...mapActions(['appInit']),
// clearLocalStorage() {
//     localStorage.clear(); // Yerel depolamayı temizle
//   }

  RenkTonuVer(Renk,Ton,Donus) {
      return colorShades(Renk, Ton)[Donus];
  },

 async setBackgroundColor() {
      // document.body.style.backgroundColor = this.$store.state.RenkTonlari[4]?.color??'#fff'
  }
},

watch: {
}
}

</script>

<style>



#app {
font-family: 'Avenir', Helvetica, Arial, sans-serif;
-webkit-font-smoothing: antialiased;
-moz-osx-font-smoothing: grayscale;
text-align: center;
color: #2c3e50;
margin-top: 60px;

}

/* burası ana sayfada çıkan scrool barı yok etmek için */
.topbarnav {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 60px; 
  z-index: 1000;
}

/* body, html {
 overflow: hidden; Sayfa genelinde scroll'u kapat
} */

.mainDiv {
  margin-top: 69px; 
  overflow-y: auto; 
  height: calc(100vh - 69px); 
  padding: 0px; /* İçerik boşluğu */
  box-sizing: border-box;
  /* background-color: #c9c7c4; */
  border-radius: 4px;
}
.mainDiv2 {
  /* margin-top: 69px;  */
  /* overflow-y: auto;  */
  /* overflow-y: hidden;  */
  /* height: calc(100vh - 69px);  */
  padding: 0px; /* İçerik boşluğu */
  box-sizing: border-box;
  /* background-color: #c9c7c4; */
  border-radius: 4px;
}

.mainDiv::-webkit-scrollbar { 
  width: 7px; /*Daraltılmış scrollbar*/
  /* margin-top: 60px; */
}
.mainDiv::-webkit-scrollbar-thumb { 
  background-color: var(--background-color) !important;
  /* background: var(--color) !important; */
  border-radius: 4px;
}


/* burası ana sayfada çıkan scrool barı yok etmek için */

.patter-one {
  position: absolute !important;
  left: -5rem;
  bottom: 0rem !important;
  z-index: -1;

  
}
.patter-two {
    position: fixed !important;
    left: 10rem;
    /* left: 30%; */
    top: 4rem;
    z-index: -1;
}


.loader {
  width: 48px;
  height: 48px;
  border-radius: 50%;
  position: relative;
  animation: rotate 1s linear infinite
}
.loader::before , .loader::after {
  content: "";
  box-sizing: border-box;
  position: absolute;
  inset: 0px;
  border-radius: 50%;
  border: 5px solid #aab1f1;
  animation: prixClipFix 2s linear infinite ;
}
.loader::after{
  border-color: #f38a6a;
  animation: prixClipFix 2s linear infinite , rotate 0.5s linear infinite reverse;
  inset: 6px;
}

@keyframes rotate {
  0%   {transform: rotate(0deg)}
  100%   {transform: rotate(360deg)}
}

@keyframes prixClipFix {
  0%   {clip-path:polygon(50% 50%,0 0,0 0,0 0,0 0,0 0)}
  25%  {clip-path:polygon(50% 50%,0 0,100% 0,100% 0,100% 0,100% 0)}
  50%  {clip-path:polygon(50% 50%,0 0,100% 0,100% 100%,100% 100%,100% 100%)}
  75%  {clip-path:polygon(50% 50%,0 0,100% 0,100% 100%,0 100%,0 100%)}
  100% {clip-path:polygon(50% 50%,0 0,100% 0,100% 100%,0 100%,0 0)}
}



/* .dikey-orta-button {
margin-top: 5px;
position: absolute;
top: 50%;
transform: translateY(-50%);
} */

.div-dikey-orta-button  {
display: flex;
align-items: center;
/* align-items: center; */
/* justify-content: center; */
/* border: 2px solid blue; */
/* height: 200px; */
}

/*.form-control:focus {*/

/*  color: #000;*/
/*  background-color: #fff;*/
/*  border:1px solid #49c5b6;*/
/*  outline: 0;*/
/*  box-shadow: none;*/

/*}*/

/*Tarih componenti hizalama*/
/* .mx-datepicker {
    width: 100% !important;
}

.mx-datepicker {
    position: relative;
    display: inline-block;
    width: 210px;
} */
/* .mx-input:hover {
	border-color: #ced4da !important;
}

.mx-input:focus {
border-color: #28a745 !important;
box-shadow: 0 0 0 0.1rem rgba(40, 167, 69, 0.25) !important;
} */

.mx-input {
	border-color: var(--border-color) !important;
}
.mx-input:hover {
	border-color: var(--border-color) !important;
}

.mx-input:focus {
border-color: var(--focus-color) !important;
/* box-shadow: 0 0 0 0.1rem rgba(40, 167, 69, 0.25) !important; */
}
.mx-input-wrapper input{
  height: 40px;
  font-size: 14px;
}

/* .mx-datepicker-main .mx-datepicker-popup{
  top: 178px !important

} */
.mx-datepicker-popup{
  top: 178px !important;
}


.fade-enter{
opacity: 0;
/*    animasyonun başlangıcında ilk karede o veriyoruz*/
}
.fade-enter-active{
transition: opacity .05s ease-out;
/*    0.3 saniye içinde gerçekleştir. Opacitiy yukarıda 0 verdik belirtilen zaman içinde 1 kadar gidecek.*/
}
/* .fade-leave{
 ekrandan ayrılma animasyonu olduğundan bir şey vermiyoruz. Çünkü ekrana giriş animasyonunun sonunda opacity zaten 1 olacak. olmasaydı burada tanımlardık
} */
.fade-leave-active{
opacity : 0;
transition: opacity .05s ease-out;

/*    ekrandan ayrılma gerçekleştiğinde yine opacity 1 olacağından 0.3 saniye içinde 0 getir*/
}

.form-control:focus {
border-color: #28a745;
box-shadow: 0 0 0 0.1rem rgba(40, 167, 69, 0.25);
} 

.inputTitle {
font-weight: 400 !important;
font-size: 14px !important;
margin-bottom: 0.05rem !important;
}

.multiselect__content-wrapper {
border: 1px solid #099945 !important;
box-shadow: 0 0 0 0.1rem rgba(40, 167, 69, 0.25) !important;
min-width: 280px !important; 

}


.multiselect {
box-sizing: content-box;
display: block;
position: relative;
width: 100%;
min-height: 35px !important;
text-align: left;
color: #35495e;
font-size: 14px !important;
}

.multiselect__tags {
min-height: 36px !important;
border-color: #ced4da !important;
  /* border: 1px solid #ced4da; */
border-radius: 0.25rem !important;
display: block;
padding: 6px 40px 0 4px !important;
border-radius: 5px;
border: 1px solid #e8e8e8;
background: #fff;
font-size: 14px;
}

.multiselect__select {
position: absolute;
/* width: 40px; */
height: 36px !important;
right: 1px;
top: -1px !important;
padding: 4px 8px;
text-align: center;
transition: transform .2s ease;
}
.multiselect__single {
padding-left: 5px;
margin-bottom: 6px !important;
}

.multiselect, .multiselect__input, .multiselect__single {
font-family: inherit;
font-size: 14px !important;
touch-action: manipulation;
}






</style>
