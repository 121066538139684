<template>
	<div class="main-wrapper">
		<AssignmentList v-if="getRol =='student' && (this.Izin = this.getRol =='teacher' ? this.getOkulAyarlar.OgretmenMenu4 : this.getOkulAyarlar.OgrenciMenu4) == 1"></AssignmentList>
		<AssignmentsTeacher v-if="getRol =='teacher' && (this.Izin = this.getRol =='teacher' ? this.getOkulAyarlar.OgretmenMenu4 : this.getOkulAyarlar.OgrenciMenu4) ==1"></AssignmentsTeacher>
		<!-- <Footer></Footer> -->
		 
	</div>
</template>

<script>
import AssignmentList from "@/template/AssignmentList.vue";
import AssignmentsTeacher from "./AssignmentsTeacher.vue";
import {mapGetters} from "vuex";

// import Footer from "@/components/static/Footer";


export default {
	// eslint-disable-next-line vue/multi-word-component-names
	name: "Assignments",
	components: { AssignmentList, AssignmentsTeacher },
	data() {
		return {
			file: null,
			content:null,
			Izin:0
			
		};
	},

	// async beforeMount() {
	// 	this.Izin = this.getRol =='teacher' ? this.getOkulAyarlar.OgretmenMenu4 : this.getOkulAyarlar.OgrenciMenu4
	// },
	computed:{
		...mapGetters(['getKullaniciDetaylari','getOkulAyarlar','getRol']),

	},
	methods: {


	},

	activated() {
		

	},
	created () {
	},
	
	
}
</script>

<style scoped>




</style>
