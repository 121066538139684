<template >
	<div class="featured-courses-two" style="margin-bottom: 25px!important;">
		<div class="row" style="justify-content: center;">
			<template>
				<h5 v-if="this.$parent.ScormData.length == 0" style="text-align: center;" :style="Text1StyleObject">There is no contents.</h5>
				<div v-for="(item) in this.$parent.ScormData" :key="item.id" class="col-xl-2 col-lg-3 col-md-3 col-sm-4 d-flex" 
					v-on:click="Gosterim =='resource'?DerseGit(item.id):Sec(item.id)" style="cursor: pointer;">
					<div class="featured-details-two" :style="CardStyleObject" :class="Secilimi(item.id)==false ? '':'ovelayed'" >
						<div class="product-item-two">
							<div class="product-img-two" >
								<a href="javascript:void(0);" style="font-size: 18px !important;">
									<img class="img-fluid" alt="Img" :src="item.image">
								</a>									
							</div>
							<div class="course-details-content">
								<div><p class="me-3" >{{ item.name }}</p></div>	
							</div>
						</div>
						<div v-if="Gosterim=='assignment' && Secilimi(item.id)==false" class="course-ovelay" :style="CourseHoverStyleObject">
							<a class="course-hoverbtn"  >Select</a>
						</div>
						<div v-if="Gosterim=='assignment' && Secilimi(item.id)==true" class="course-ovelay" :style="CourseHoverStyleObject">
							<a class="course-hoverbtn" >Remove</a>
						</div>
					</div>
				</div>
			</template>
		</div>
	</div>
		
</template>

<script>
import {mapGetters} from "vuex";

export default {
	// eslint-disable-next-line vue/multi-word-component-names
	name: "course",
	props:{
		Gosterim:null
	},
	data() {
		return {

		};
	},

	computed:{
		...mapGetters(['Text1StyleObject','CardStyleObject','CourseHoverStyleObject']),	
	},
	
	methods: {
		Secilimi(id){
			if(this.Gosterim=='assignment'){
				return this.$parent.SecilenScormlar.includes(id)
			}
		},

		DerseGit(id){
				const SData = this.$parent.ScormData.find(x=> x.id == id)
				const ScormView = {
					scorm_id: SData.id,
					assignment_id: SData.collection,
					scormTitle: SData.name,
					scormDesc: SData.desc,
					scormCode: SData.scorm_code,
					packageType: 'scorm',
					timestamp: null,
				};
				this.$router.push({ name: 'scormView', params: { ScormView } });

		},

		async Sec(i) {
			console.log(i)
			const index = this.$parent.SecilenScormlar.indexOf(i);
			if (index > -1) {
				this.$parent.SecilenScormlar.splice(index, 1); // Diziden id'yi çıkar
			} else {
				this.$parent.SecilenScormlar.push(i); // Diziye id'yi ekle
			}
		},

		activated() {
			

		},
		created () {
		}
	}
}
</script>

<style scoped>

</style>
