<template >
	<div >
		<div v-for="(item) in this.DetayData" :key="item.assignment_id" class="row border-bottom"> 
			<div class="col-lg-3  d-flex">
				<div class="card dash-info flex-fill" style="background: none; border: none;">
					<p :style="Text1StyleObject" style="margin-left: 23px; margin-bottom: -15px; white-space: normal; overflow-wrap: break-word;">
						Lesson: {{item.scorm_name??'-- -- --'}}
					</p>
					<div class="card-body">
							<a href="javascript:void(0);" style="font-size: 18px !important;">
								<img style="width: auto; height: 140px;" class="img-fluid" alt="Img" :src="item.scorm_image">
							</a>									
					</div>
					
				</div>
			</div>
			<div class="col-lg-9 d-flex">			
					
					<table class="table table-hover table-nowrap mb-0" :style="TableStyleObject">
						<thead>
						<tr>
							<th>Lesson</th>
							<th class="text-center">Score</th>
							<th class="text-center">Checks</th>
							<th class="text-center">Errors</th>
							<th class="text-center">Mistakes</th>
							<th class="text-center">Time</th>
						</tr>
						</thead>
						<template v-if="item.assignment_type == 'group'">
							<tbody>
								<tr style="height:20px !important" >
									<td>Total</td>
									<td class="text-center">{{item.users[0].scorm_scores.total_score}}</td>
									<td class="text-center">{{item.users[0].scorm_scores.total_checks_count}}</td>
									<td class="text-center">{{item.users[0].scorm_scores.total_errors_count}}</td>
									<td class="text-center">{{item.users[0].scorm_scores.total_mistake_count}}</td>
									<td class="text-center">{{millisToMinutesAndSeconds(item.users[0].scorm_scores.total_time)}}</td>
								</tr>
								<tr v-if="item.users[0].scorm_scores.paginated_results.length == 0" style="height:20px !important" >
									<td ><span class="title-course">Activities</span></td>
									<td colspan="5" class="text-center"><span class="title-course">There is no activities</span></td>								
								</tr>
								<tr v-else style="height:20px !important" >
									<td colspan="6"><span class="title-course">Avtivities</span></td>
									
								</tr>
							</tbody>
							<tbody v-for="(Sayfa,i) in item.users[0].scorm_scores.paginated_results" :key="i">
								<tr style="height:20px !important" >
									<td><span class="title-course">{{Sayfa.page_name}}</span></td>
									
									<td class="text-center">{{Math.floor(Sayfa.score * 100)}}%</td>
									<!-- <td class="text-center">{{item.users[0].scorm_scores.total_score}}</td> -->
									<td class="text-center">{{Sayfa.checks_count}}</td>
									<td class="text-center">{{Sayfa.errors_count}}</td>
									<td class="text-center">{{Sayfa.mistake_count}}</td>
									<td class="text-center">{{millisToMinutesAndSeconds(Sayfa.time)}}</td>
								</tr>
							</tbody>
						</template>
						<template v-else>
							<tbody>
								<tr style="height:20px !important" >
									<td>Total</td>
									<td class="text-center">{{item.users.scorm_scores.total_score}}</td>
									<td class="text-center">{{item.users.scorm_scores.total_checks_count}}</td>
									<td class="text-center">{{item.users.scorm_scores.total_errors_count}}</td>
									<td class="text-center">{{item.users.scorm_scores.total_mistake_count}}</td>
									<td class="text-center">{{millisToMinutesAndSeconds(item.users.scorm_scores.total_time)}}</td>
								</tr>
								<tr v-if="item.users.scorm_scores.paginated_results.length == 0" style="height:20px !important" >
									<td ><span class="title-course">Activities</span></td>
									<td colspan="5" class="text-center"><span class="title-course">There is no activities</span></td>								
								</tr>
								<tr v-else style="height:20px !important" >
									<td colspan="6"><span class="title-course">Avtivities</span></td>									
								</tr>
							</tbody>
							<tbody v-for="(Sayfa,i) in item.users.scorm_scores.paginated_results" :key="i">
								<tr style="height:20px !important" >
									<td><span class="title-course">{{Sayfa.page_name}}</span></td>
									
									<td class="text-center">{{Math.floor(Sayfa.score * 100)}}%</td>
									<!-- <td class="text-center">{{item.users[0].scorm_scores.total_score}}</td> -->
									<td class="text-center">{{Sayfa.checks_count}}</td>
									<td class="text-center">{{Sayfa.errors_count}}</td>
									<td class="text-center">{{Sayfa.mistake_count}}</td>
									<td class="text-center">{{millisToMinutesAndSeconds(Sayfa.time)}}</td>
								</tr>
							</tbody>
						</template>

					</table>

		
			</div>

		</div>

	</div>
</template>

<script>
import {mapGetters} from "vuex";
// import Content from '@/services/content';
import moment from 'moment';
import 'vue2-datepicker/index.css';

// import DatePicker from 'vuejs-datepicker';


export default {
	// eslint-disable-next-line vue/multi-word-component-names
	name: "ReportUserDetails",
	props:['DetailData','UserId','AssignmentId'],

	data() {
		return {
			Hazir : false,
			DetayData: [],
			SistemTarihi: null,
			KayitSayisi: 0,
			StartDate : null,
			DueDate: null,
			format: 'DD.MM.YYYY',

		};
	},
	
	async mounted() {

		await this.KullaniciSkorlariGetir()
	},

	computed:{
		...mapGetters(['CardStyleObject','Text1StyleObject','TableStyleObject']),

		filteredAssignments() {
		return this.assignments
			.map((assignment) => {
			// Her görevde users dizisini filtrele
			const filteredUsers = assignment.users.filter(
				(user) => user.user_id === this.targetUserId
			);

			// Eğer uygun users yoksa bu görevi tamamen çıkar
			if (filteredUsers.length === 0) return null;

			// Yeni bir görev nesnesi oluştur ve users dizisini güncelle
			return {
				...assignment,
				users: filteredUsers,
			};
			})
			.filter((assignment) => assignment !== null); // Geçersiz görevleri çıkar
		},
  

	},
	methods: {
		DateFormat(value){
        	return moment(value).format("DD.MM.YYYY"); 
    	},

		millisToMinutesAndSeconds(millis) {
			let minutes = Math.floor(millis / 60000);
			let seconds = ((millis % 60000) / 1000).toFixed(0);
			if (minutes < 10) {
				minutes = `0${minutes}`;
			}
			if (seconds < 10) {
				seconds = `${minutes}`;
			}
			return `${minutes}:${seconds}`;
		},


		async KullaniciSkorlariGetir(){
			try {
				if(this.DetailData[0].assignment_type == 'group'){				

					this.DetayData = this.DetailData
					.map((Detay) => {
						// Users listesini filtrele
						const filteredUsers = Detay.users.filter(
						(user) => user.user_id === this.UserId
						);
						// Eğer filteredUsers boş değilse, assignment'i güncelle
						return filteredUsers.length > 0
						? { ...Detay, users: filteredUsers }
						: null;
					})
					.filter((Detay) => Detay !== null); // Null olanları temizle

				} else {
					this.DetayData = this.DetailData.filter(x=> x.assignment_id == this.AssignmentId)

				}

			} catch (error) {
				console.log('Assignment Göster',error)
			}
		},


		activated() {
			

		},
		created () {
		}
	}
}
</script>

<style scoped>

.text-center{
	text-align:center
}

.custom-table table th {
  background: var(--color) !important;
  font-weight: 500;
  color: #1b1b1b;
  padding: 15px 10px;
  /* border-color: #f4f6f9; */
}
/* .custom-table table td:hover {
  background-color: var(--color) !important;
} */
.table-hover>tbody>tr:hover>* {
    --bs-table-color-state: var(--color) !important;
    --bs-table-bg-state: var(--color-hover) !important;
}
.select-noimg{
	--bs-form-select-bg-img: none !important;
}

.featured-details-two .name-text h3 a {
    font-weight: 500;
    font-size: 14px;
    color: var(--color) !important;
}

.featured-info-two{
	text-wrap: wrap; 
	margin-bottom: 0px;
	padding-bottom: 0px !important;
}

.course-details-content p {
    font-size: 13px;
}
.course-details-content span {
    font-size: 13px;
}

.course-details-content {
    padding: 20px 10px 1px;
}
.price.combo h3 {
    font-size: 13px !important;
}
</style>
